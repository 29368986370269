import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { RecordSelect } from '@smartplatform/ui';
import { renderUser, renderPlaceholder } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

export const ToolbarUserSelect = observer(({ showValue, value, filter, label, ...restProps }) => {
	const _filter = { order: 'lastName asc, firstName asc, username asc', ...filter };

	if (!showValue) {
		if (value === 'ALL') {
			showValue = renderPlaceholder(t('any'));
		} else if (value === 'ME') {
			showValue = renderUser(store.model.user, ...[,], t('task.me'));
		} else if (value === 'NONE') {
			showValue = t('task.notAssigned');
		}
	}
	return (
		<div className='toolbar-user-select'>
			{label && <label>{label}</label>}
			<RecordSelect
				className='toolbar-user'
				model={store.model.User}
				showValue={showValue}
				value={value}
				computed={renderUser}
				searchPlaceholder={t('search')}
				filter={_filter}
				itemsPerPage={10000}
				comparator={(a, b) => a && b && a === b}
				isRequired
				noScrollToItem
				{...restProps}
				//* sortSelected не работает поиск с этим пропом
			/>
		</div>
	);
});

ToolbarUserSelect.propTypes = {
	filter: PropTypes.object,
	searchFields: PropTypes.array,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onChange: PropTypes.func,
	prependItems: PropTypes.array,
	showValue: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ToolbarUserSelect.defaultProps = {
	searchFields: ['username', 'lastName', 'firstName', 'middleName'],
};

