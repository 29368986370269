import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import store from './store';
import App from './App';

const render = (Component) => {
	ReactDOM.render(
		<AppContainer warnings={false}>
			<Theme preset={presetGpnDefault}>
				<Component />
			</Theme>
		</AppContainer>,
		document.getElementById('root')
	);
};

const init = async () => {
	await store.init();
	render(App);
};

init();

if (module.hot) {
	module.hot.accept();

	let lastHash;

	const upToDate = function upToDate() {
		return lastHash.indexOf(__webpack_hash__) >= 0;
	};

	const clientEmitter = require('webpack/hot/emitter');

	clientEmitter.on('webpackHotUpdate', function (currentHash) {
		lastHash = currentHash;
		if (upToDate()) return;
		store.fireHMR();
	});
}

