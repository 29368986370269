import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AddIcon from 'client/img/add-btn.svg';

import './style.scss';

export class AddCircleButton extends React.Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired,
		disabled: PropTypes.bool,
	};

	static defaultProps = {
		disabled: false,
	};

	render() {
		const { onClick, disabled } = this.props;
		const className = classNames('add-row', { disabled });

		return (
			<div className={className} onClick={onClick}>
				<AddIcon className='add-btn' />
			</div>
		);
	}
}

