import i18next from 'i18next';

import en from './en';
import ru from './ru';

// let currentLanguage = localStorage.getItem('smartplatform:appLanguage') || '"en"';
let currentLanguage = '"en"';
currentLanguage = currentLanguage.substring(1, currentLanguage.length - 1);

i18next.init({
	lng: currentLanguage,
	resources: {
		en,
		ru,
	},
});

export default function (key, options = {}) {
	return i18next.t(key, options);
}

