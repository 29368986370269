import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { toLowerCamelCase, getLabelName, getPerPage } from 'client/tools';
import { Toolbar } from 'components';
import { Pager } from '@smartplatform/ui';
import debounce from 'lodash/debounce';
import { WrappedTable } from 'components';
import store from 'client/store';
import t from 'i18n';

import './basicList.scss';

const propTypes = {
	model: PropTypes.any.isRequired,
	filter: PropTypes.object,
	path: PropTypes.string,
	noPerPageSelector: PropTypes.bool,
	/** Функция для передачи компонента тулбара */
	renderCustomToolbar: PropTypes.func,
	afterToolbar: PropTypes.any, // доп. контент
	toolbarButtonText: PropTypes.string,
	toolbarCountText: PropTypes.string,
	/**Функция в аргументе возвращает функцию init */
	getInit: PropTypes.func,
	perPage: PropTypes.number,
};

const defaultProps = {
	noPerPageSelector: false,
	toolbarCountText: t('total'),
};

@observer
export class BasicList extends React.Component {
	static propTypes = propTypes;

	static defaultProps = defaultProps;

	@observable records = [];
	@observable querySearch = '';
	@observable search = '';
	@observable page = 1;
	@observable perPage = 1;
	@observable order = this.props.order || 'id desc';

	model = null;
	path = this.props.path || store.route.path;

	constructor(props) {
		super(props);
		this.model = props.model;
		this.rowHeight = props.rowHeight;
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		props.getInit?.(this.init);
		this.setTitle();
	}

	componentDidMount() {
		if (!this.props.perPage) {
			this.setPerPage(getPerPage(this.rowHeight));
		} else {
			this.perPage = this.props.perPage;
		}
		this.init();
	}

	init = async () => {
		try {
			this.records = await this.model.find({
				...this.props.filter,
				limit: this.perPage,
				order: this.order,
				search: this.querySearch,
				skip: this.perPage * (this.page - 1),
			});
		} catch {}
	};

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)) {
			this.init();
		}
	}

	setTitle = () => {
		const { title } = this.props;

		if (title) {
			store.ui.title = title;
		} else {
			const modelNamePlural = getLabelName('plural', this.model.name);
			store.ui.title = t(modelNamePlural);
		}
	};

	doSearch = () => {
		this.querySearch = this.search;
		this.init();
	};

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
	};

	onChange = (prop) => (value) => {
		this.page = 1;
		this[prop] = value;
		this.init();
	};

	onQueryUpdate = (query) => {
		if (query.order === undefined) {
			this.order = this.props.order || 'id desc';
		} else {
			this.order = `${query.order} nulls last`;
		}

		this.init();
	};

	setPerPage = (value) => {
		this.page = 1;
		this.perPage = value;
	};

	onRowClick = (record) => {
		if (this.props.onRowClick) this.props.onRowClick(record);
		else store.route.push({ path: `${this.path}/${record.id}` });
	};

	create = () => store.route.push({ path: `${this.path}/create` });

	renderToolbar = () => {
		const { toolbarButtonText, toolbarCountText, renderCustomToolbar, afterToolbar, disabledButton } = this.props;
		const { create, onSearch, search, records } = this;

		const createButtonText = toolbarButtonText || getLabelName('create', this.model.name);
		const customToolbarProps = {
			onClick: create,
			inputValue: search,
			placeholder: t('searchName'),
			totalCount: records.totalCount,
			text: t(createButtonText),
			onSearch,
			toolbarCountText,
			disabledButton,
		};

		return renderCustomToolbar ? (
			renderCustomToolbar(customToolbarProps)
		) : (
			<Toolbar className='basic-list-toolbar'>
				{!disabledButton && <Toolbar.CreateBtn onClick={customToolbarProps.onClick} text={customToolbarProps.text} />}
				<Toolbar.Search value={customToolbarProps.inputValue} onSearch={customToolbarProps.onSearch} placeholder={t('search')} />
				<Toolbar.Count text={customToolbarProps.toolbarCountText} count={customToolbarProps.totalCount} />
			</Toolbar>
		);
	};

	render() {
		const { children, noPerPageSelector, afterToolbar } = this.props;

		const toolbar = this.renderToolbar();

		return (
			<div className='basic-list'>
				{toolbar}
				<div className='basic-list'>
					<>
						{afterToolbar}
						<WrappedTable rows={this.records} query={{}} onQueryUpdate={this.onQueryUpdate} onRowClick={this.onRowClick}>
							{children}
						</WrappedTable>
						{!noPerPageSelector && (
							<Pager
								current={this.page}
								totalCount={this.records.totalCount || 0}
								onChange={this.onChange('page')}
								itemsPerPage={this.perPage}
								noCount
							/>
						)}
					</>
				</div>
			</div>
		);
	}
}

