import React from 'react';
import { RecordSelect } from '@smartplatform/ui';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { renderStatus, renderPlaceholder } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

export const ToolbarListSelect = observer(({ filter, computed, label, ...restProps }) => {
	return (
		<div className='toolbar-list-select'>
			{label && <label>{label}</label>}
			<RecordSelect
				computed={computed}
				filter={filter}
				searchPlaceholder={t('search')}
				showSelectControls
				isRequired
				className='list-select'
				noScrollToItem
				{...restProps}
				itemsPerPage={10000}
			/>
		</div>
	);
});

ToolbarListSelect.propTypes = {};

ToolbarListSelect.defaultProps = {
	filter: { order: 'name asc' },
};

