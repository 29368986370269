import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ModelRoute } from './ModelRoute';
import { toLowerCamelCase } from 'client/tools';
import { cloneDeep } from 'lodash';
import { RouteMenu } from 'components';
import PropTypes from 'prop-types';
import t from 'i18n';
import './style.scss';
import classNames from 'classnames';

export class Dictionaries extends Component {
	static propTypes = {
		title: PropTypes.string,
		models: PropTypes.array,
		excludedProperties: PropTypes.array,
		match: PropTypes.object,
		className: PropTypes.string,
		searchPlaceholder: PropTypes.string,
		search: PropTypes.bool,
	};

	static defaultProps = {
		title: t('dictionaries.plural'),
		searchPlaceholder: t('dictionaries.search'),
		excludedProperties: [],
		models: [],
		search: false,
	};

	constructor(props) {
		super(props);
		this.menuItems = cloneDeep(this.props.models);
		this.routes = [];
		this.path = props.match.path;
		this.menuItems = this.menuItems.map((item) => this.transformModelObject(item));
		this.menuItems.forEach((item) => {
			const route = Array.isArray(item?.items) ? item.items : [item];
			this.routes.push(...route);
		});
	}

	// объект вида { modelName: "User" }
	transformModelObject = (model) => {
		model = typeof model === 'string' ? { modelName: model } : model;
		if (model.modelName) {
			const lowerModelName = toLowerCamelCase(model.modelName.replace(/^View/, ''));
			model.title ||= t(`${lowerModelName}.plural`);
			model.path ||= `${this.props.match.path}/${lowerModelName}`;
		}

		if (Array.isArray(model?.items)) {
			model.items = model.items.map((model) => this.transformModelObject(model));
		}

		return model;
	};

	render() {
		const { models, title, excludedProperties, className, match, location, search, searchPlaceholder } = this.props;
		const { menuItems, routes } = this;

		if (!models?.length) return <div className='error'>no models provided</div>;
		const _className = classNames('dictionaries-page', className);

		const isRootPath = match.path === location.pathname;
		if (isRootPath && this.routes[0]?.path) return <Redirect to={this.routes[0].path} />;

		return (
			<div className={_className}>
				<div className='content'>
					<RouteMenu items={menuItems} search={search} searchPlaceholder={searchPlaceholder} />
					<div className='model'>
						<Switch>
							{routes.map((model) => {
								const { path, modelName } = model;
								return (
									<Route
										key={modelName}
										path={path}
										component={() => <ModelRoute {...model} excludedProperties={excludedProperties} />}
									/>
								);
							})}
						</Switch>
					</div>
				</div>
			</div>
		);
	}
}

