import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'components';
import { Popconfirm } from '@smartplatform/ui';
import classNames from 'classnames';
import t from 'i18n';

export const DeleteButton = (props) => {
	const { confirmMessage, text, onConfirm, portalClassName, noBorder, popPosition, disabled, className, ...restButtonProps } = props;

	return (
		<Popconfirm
			content={confirmMessage}
			onConfirm={onConfirm}
			portalClassName={portalClassName}
			position={popPosition}
			disabled={disabled}
			contentClassName="Theme_color_gpnDefault"
		>
			<Button
				variant='danger'
				startIcon={<FontAwesomeIcon icon={faTrashCan} />}
				className={classNames('delete-btn', className, { 'no-border': noBorder })}
				disabled={disabled}
				text={text}
				{...restButtonProps}
			/>
		</Popconfirm>
	);
};

DeleteButton.defaultProps = {
	confirmMessage: t('delete'),
	text: t('delete'),
};

DeleteButton.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	confirmMessage: PropTypes.any,
	portalClassName: PropTypes.string,
	noBorder: PropTypes.bool,
	disabled: PropTypes.bool,
	popPosition: PropTypes.oneOf([
		'topLeft',
		'top',
		'topRight',
		'leftTop',
		'left',
		'leftBottom',
		'rightTop',
		'right',
		'rightBottom',
		'bottomLeft',
		'bottom',
		'bottomRight',
	]),
};

