import React from 'react';
import t from 'i18n';
import { faUserGear, faUser, faFlag } from '@fortawesome/free-solid-svg-icons';
import LogBookIcon from 'client/img/sidebar/items/file.svg';
import RoleMappingIcon from 'client/img/sidebar/acl.svg';
import DictionaryIcon from 'client/img/sidebar/items/paste.svg';
import * as Pages from 'client/pages';
import { renderFaIcons } from 'client/tools';

const checkAccess = ({ READ = [], WRITE = [] }) => {
	if (typeof READ === 'string') READ = [READ];
	if (typeof WRITE === 'string') WRITE = [WRITE];

	return (store) => {
		const isWriteAllowed = !!WRITE.find((modelName) => store.model[modelName]?.INFO.WRITE);
		const isReadAllowed = !!READ.find((modelName) => store.model[modelName]?.INFO.READ);
		return isWriteAllowed && isReadAllowed;
	};
};

export default [
	{
		type: 'submenu',
		id: 'plane',
		label: t('plane.plural'),
		enabled: () => true,
		isOpenByDefault: true,
		//checkAccess({ WRITE: 'Contragent', READ: 'Contragent' }),
		items: [
			{
				type: 'item',
				path: '/planes',
				label: t('plane.plural'),
				icon: faFlag,
				Component: Pages.Planes,
				enabled: (store) => true,
			},
			{
				type: 'item',
				path: '/logbook',
				label: t('logbook.title'),
				reactIcon: <LogBookIcon />,
				Component: Pages.Logbook,
				enabled: (store) => true,
			},
		],
	},
	{
		type: 'submenu',
		id: 'admin',
		label: t('administration'),
		items: [
			{
				type: 'item',
				label: t('user.plural'),
				path: '/users',
				Component: Pages.Users,
				enabled: checkAccess({ WRITE: 'ACL', READ: 'ACL' }),
				icon: faUser,
			},
			{
				type: 'item',
				label: t('roles'),
				path: '/roles',
				Component: Pages.Roles,
				enabled: checkAccess({ WRITE: 'ACL', READ: 'ACL' }),
				icon: faUserGear,
			},
			{
				type: 'item',
				path: '/rolemappings',
				label: t('roleMappings'),
				Component: Pages.RoleMappings,
				enabled: checkAccess({ WRITE: 'ACL', READ: 'ACL' }),
				reactIcon: <RoleMappingIcon />,
			},
			{
				type: 'item',
				path: '/dictionaries',
				label: t('dictionary.plural'),
				Component: Pages.Dictionaries,
				enabled: checkAccess({ WRITE: 'Engine', READ: 'Engine' }),
				reactIcon: <DictionaryIcon />,
			},
			{
				type: 'item',
				path: '/settings',
				label: t('_settings.title'),
				Component: Pages.Settings,
				enabled: checkAccess({ WRITE: 'ACL', READ: 'ACL' }),
				reactIcon: renderFaIcons.settings,
			},
			/*{
				type: 'item',
				path: '/transport-module',
				label: t('transportData.title'),
				Component: TransportModule,
				reactIcon: renderFaIcons.settings,
			},
			{
				type: 'item',
				path: '/journal',
				label: t('journal.title'),
				Component: Audit,
				reactIcon: renderFaIcons.settings,
			},*/
		],
	},
];

