import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TruncateText } from 'components';

export class MenuItem extends Component {
	static propTypes = {
		path: PropTypes.string,
		title: PropTypes.string,
	};

	render() {
		const { path, title } = this.props;
		if (!path) return null;

		return (
			<NavLink to={path} activeClassName='active'>
				<TruncateText>{title}</TruncateText>
			</NavLink>
		);
	}
}

