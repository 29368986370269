import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { stringToBoolean } from 'client/tools';
import { Button, Input, Loader, NumberInput, SlideInput } from '@smartplatform/ui';
import { BlockedFieldsMessage, LabelField } from 'components';
import './style.scss';
import { triggerErrorNotification, triggerSaveNotification } from 'components/notifications';
import { Switch } from '@consta/uikit/Switch';

@observer
export default class PasswordSettings extends React.Component {
	@observable authenticationSettings = {
		minLength: 6,
		requireNumber: false,
		requireUppercase: false,
		requireLowercase: false,
		requireSpecialChars: false,
		passwordExpirationTime: 0,
		countSavedPasswords: 0,
		countUniqueCharts: 0,
	};

	disabledFields = new Set();

	@observable originalSettings = {};
	@observable updatedSettingsKeys = [];
	@observable isLoading = true;

	propsField = {
		minLength: {
			type: 'range',
			props: {
				min: 3,
				max: 64,
			},
		},
		requireNumber: {
			type: 'boolean',
			props: {},
		},
		requireUppercase: {
			type: 'boolean',
			props: {},
		},
		requireLowercase: {
			type: 'boolean',
			props: {},
		},
		requireSpecialChars: {
			type: 'boolean',
			props: {},
		},
		passwordExpirationTime: {
			type: 'number',
			props: {},
		},
		countSavedPasswords: {
			type: 'number',
			props: {},
		},
		countUniqueCharts: {
			type: 'number',
			props: {},
		},
	};

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		store.ui.title = t('passwordSettings.title');
		const { password: passwordSettings } = store.serverConfig?.authentication || {};

		if (passwordSettings) {
			Object.keys(passwordSettings).forEach((key) => {
				if (this.authenticationSettings[key] !== undefined) {
					this.authenticationSettings[key] = passwordSettings[key];
					this.disabledFields.add(key);
				}
			});
		}

		const settings = await store.model.Config.find({
			where: { code: { inq: Object.keys(this.authenticationSettings).map((key) => this.generateCode(key)) } },
		});
		if (settings) {
			for (const setting of settings) {
				const key = setting.code.split('.').at(-1);
				if (!this.disabledFields.has(key)) this.setConfigByModel(key, setting);
			}
		}
		this.dropOriginalSettings();
		this.isLoading = false;
	};

	setConfigByModel = (key, setting) => {
		const type = this.propsField?.[key].type;
		if (type === 'boolean') {
			this.authenticationSettings[key] = setting.value ? stringToBoolean(setting.value) : false;
		} else if (type === 'number' || type === 'range') {
			this.authenticationSettings[key] = isNaN(parseInt(setting.value)) ? 0 : parseInt(setting.value);
		} else {
			this.authenticationSettings[key] = setting.value || '';
		}
	};

	onSave = async () => {
		try {
			for (const key of this.updatedSettingsKeys) {
				const settingItem =
					(
						await store.model.Config.find({
							where: { code: this.generateCode(key) },
						})
					)?.[0] || new store.model.Config({ code: this.generateCode(key) });
				settingItem.value = this.authenticationSettings[key] === '' ? null : this.authenticationSettings[key];
				await settingItem.save();
			}
			this.dropOriginalSettings();
			await store.fetchDbServerConfig();
			triggerSaveNotification(t('config.saved'));
		} catch (e) {
			triggerErrorNotification(e.message);
		}
	};

	generateCode = (property) => `authentication.password.${property}`;

	dropOriginalSettings = () => {
		this.originalSettings = { ...this.authenticationSettings };
		for (const field in this.authenticationSettings) {
			this.originalSettings[field] = this.authenticationSettings[field];
		}
		this.updatedSettingsKeys = [];
	};

	onChange = (field) => (value) => {
		this.authenticationSettings[field] = value;
		this.setUpdatedSettingsKeys();
	};

	setUpdatedSettingsKeys = () =>
		(this.updatedSettingsKeys = Object.keys(this.originalSettings).filter((key) => this.authenticationSettings[key] !== this.originalSettings[key]));

	renderFields = () => {
		const { authenticationSettings, onChange } = this;
		let fields = [];
		for (const field in authenticationSettings) {
			const type = this.propsField?.[field]?.type;

			if (type === 'boolean') {
				fields.push(
					<div className='item-setting-switch' key={field}>
						<span>{t(`passwordSettings.${field}`)}</span>
						<Switch
							checked={authenticationSettings[field]}
							onChange={(e) => onChange(field)(e.checked)}
							disabled={this.disabledFields.has(field)}
							size='l'
							{...this.propsField?.[field].props}
						/>
					</div>
				);
			} else if (type === 'number') {
				fields.push(
					<LabelField key={field} id={field} label={t(`passwordSettings.${field}`)}>
						<NumberInput
							value={authenticationSettings[field]}
							onChange={onChange(field)}
							{...this.propsField?.[field].props}
							disabled={this.disabledFields.has(field)}
						/>
					</LabelField>
				);
			} else if (type === 'range') {
				fields.push(
					<LabelField key={field} id={field} label={t(`passwordSettings.${field}`)}>
						<div className='wrapper-slide-input'>
							<NumberInput
								value={authenticationSettings[field]}
								onChange={onChange(field)}
								{...this.propsField?.[field].props}
								disabled={this.disabledFields.has(field)}
							/>
							<SlideInput
								min={3}
								max={64}
								step={1}
								value={authenticationSettings[field]}
								onChange={onChange(field)}
								disabled={this.disabledFields.has(field)}
							/>
						</div>
						{(authenticationSettings[field] < this.propsField?.[field].props.min || authenticationSettings[field] > this.propsField?.[field].props.max) && (
							<p className='invalid-message'>
								{t('passwordSettings.invalidRange', {
									min: this.propsField?.[field].props.min,
									max: this.propsField?.[field].props.max,
								})}
							</p>
						)}
					</LabelField>
				);
			} else {
				fields.push(
					<LabelField key={field} id={field} label={t(`passwordSettings.${field}`)}>
						<Input value={authenticationSettings[field]} onChange={onChange(field)} {...this.propsField?.[field].props} />
					</LabelField>
				);
			}
		}
		return fields;
	};

	render() {
		const { onSave, updatedSettingsKeys, isLoading, authenticationSettings, propsField } = this;
		if (isLoading) return <Loader size={20} />;
		const contentPassword = this.renderFields();
		const validLengthPassword =
			authenticationSettings.minLength >= propsField.minLength.props.min && authenticationSettings.minLength <= propsField.minLength.props.max;

		const inValidRequireOptions =
			!authenticationSettings.requireLowercase &&
			!authenticationSettings.requireNumber &&
			!authenticationSettings.requireUppercase &&
			!authenticationSettings.requireSpecialChars;
		return (
			<div className='authentication-setting'>
				{this.disabledFields.size > 0 && <BlockedFieldsMessage />}
				<p className='head-group'>{t('passwordSettings.password')}</p>
				{contentPassword}
				{inValidRequireOptions && <p className='invalid-message'>{t('passwordSettings.invalidPasswordOptions')}</p>}
				<Button
					variant='primary'
					onClick={onSave}
					className='mt-2'
					disabled={updatedSettingsKeys.length === 0 || !validLengthPassword || inValidRequireOptions}
				>
					{t('save')}
				</Button>
			</div>
		);
	}
}
