import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import store from 'client/store';
import t from 'i18n';

import { ButtonGroup, Checkbox, ColorPicker, NumberInput, RecordSelect, Select, SlideInput } from '@smartplatform/ui';
import { Button, LabelField } from 'components';
import { DatePicker } from '@consta/uikit/DatePicker';
import ColorsChart from 'client/pages/planes/graphicReport/left-side/colors-chart/ColorsChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import Dates from 'client/pages/planes/components/dates/Dates';
import { Switch } from '@consta/uikit/Switch';

@inject('graphicReportStore')
@observer
export class ActionsPanel extends Component {
	store = null;

	constructor(props) {
		super(props);
		this.store = props.graphicReportStore;
	}

	renderConf(record) {
		return (
			<div className='item-conf'>
				{record.label}
				{!!record.current && <span>{t('installed')}</span>}
			</div>
		);
	}

	render() {
		const {
			flightPhase,
			onFlightPhaseChange,
			aircraft,
			onAircraftChange,
			onDateChange,
			startDate,
			endDate,
			downloadReportAsPDF,
			isLoading,
			BUTTONS,
			enginesConfigurationItems,
			configuration,
			onChangeConfiguration,
			onChangeDelta,
			fakeCofDelta,
			isSmoothed,
			onChangeSmoothed,
		} = this.store;

		return (
			<>
				<div className='aircraft-phase'>
					<div className='firstRow'>
						<div className='left-first-row'>
							<RecordSelect
								className='aircraftSelect'
								model={store.model.Aircraft}
								filter={{
									include: ['engines'],
								}}
								property='serialNumber'
								value={aircraft}
								onChange={onAircraftChange}
								isRequired
								disabled={true}
							/>
							<ButtonGroup className='phaseSelect'>
								{BUTTONS.map((phaseValue) => (
									<Button
										key={phaseValue.phaseName}
										onClick={() => onFlightPhaseChange(phaseValue)}
										variant={flightPhase.phaseName === phaseValue.phaseName ? 'primary' : 'default'}
									>
										{phaseValue.phaseName}
									</Button>
								))}
							</ButtonGroup>
						</div>
						<ColorsChart />
					</div>

					<div className='second-row'>
						{!!enginesConfigurationItems.length && (
							<LabelField label={t('aircraft.configuration')} className='engines-conf'>
								<Select
									items={enginesConfigurationItems}
									value={configuration}
									onChange={onChangeConfiguration}
									isRequired
									size='s'
									computed={this.renderConf}
								/>
							</LabelField>
						)}
						<LabelField label={t('aircraft.graphicReport.delta')} className='delta'>
							<div className='wrapper-slide-input'>
								{/*<NumberInput value={fakeCofDelta} onChange={onChangeDelta} disabled={true} />*/}
								<SlideInput min={0} max={5} step={1} value={fakeCofDelta} onChange={onChangeDelta} />
							</div>
						</LabelField>
						<LabelField label={t('aircraft.isSmoothed')} className='smoothed'>
							<Switch size='l' checked={isSmoothed} onChange={onChangeSmoothed} />
						</LabelField>
					</div>
					<Dates startDate={startDate} endDate={endDate} onChange={onDateChange} />
				</div>
				<div>
					<Button
						size='sm'
						disabled={isLoading}
						onClick={() => {
							downloadReportAsPDF();
						}}
						style={{ marginTop: '5px' }}
					>
						{t('downloadPDF')}
					</Button>
				</div>
			</>
		);
	}
}
