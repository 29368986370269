import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { Switch, Route } from 'react-router-dom';
import { PlaneList } from './PlaneList';
import { SinglePage } from './SinglePage';

export const Planes = (props) => {
	const { path } = props;

	return (
		<Switch>
			<Route exact path={path} render={(routeProps) => <PlaneList {...routeProps} />} />
			<Route path={`${path}/create`} render={(routeProps) => <SinglePage {...routeProps} />} />
			<Route path={`${path}/:id`} render={(routeProps) => <SinglePage {...routeProps} />} />
		</Switch>
	);
};
