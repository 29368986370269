import React from 'react';

import { Column } from '@smartplatform/ui';
import { BasicList } from 'components';
import store from 'client/store';
import t from 'i18n';
import { getRoleNames, renderFaIcons } from 'client/tools';

export default ({ modelName, path, match }) => {
	const renderIconLock = (record) => record.isBlocked && renderFaIcons.lock;

	const renderIconIsAdmin = (record) => (record.userRoleNames?.includes('admin') ? renderFaIcons.crown : ' ');

	return (
		<BasicList model={store.model[modelName]} path={path} match={match} title={t('user.plural')}>
			<Column width={20} computed={renderIconIsAdmin} />
			<Column width={70} property='id' label={t('id')} />
			<Column property='lastName' label={t('user.lastName')} />
			<Column property='firstName' label={t('user.firstName')} />
			<Column property='middleName' label={t('user.middleName')} />
			<Column property='phone' label={t('user.phone')} />
			<Column property='email' label={t('user.email')} />
			<Column width={20} computed={renderIconLock} label={renderFaIcons.userLock} />
		</BasicList>
	);
};
