import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { toLowerCamelCase } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

@observer
export class DeleteErrorEditWrapper extends React.Component {
	@observable isDeletable = true;
	@observable isSave = true;

	record = null;

	constructor(props) {
		super(props);
	}

	getValidatedRemove = async (record) => {
		this.record = record;
		if (record) {
			this.isDeletable = await this.record.isDeletable?.();
		}

		return this.isDeletable;
	};

	getValidatedSave = async (record) => {
		this.record = record;
		if (record) {
			this.isSave = await this.record.isSave?.();
		}

		return this.isSave;
	};

	render() {
		const { modelName } = this.props;
		const modelNameCamelCase = toLowerCamelCase(modelName);
		const invalidDeletingMsg = t(modelNameCamelCase + '.deleteInvalidMsg');
		const invalidSavingMsg = t(modelNameCamelCase + '.saveInvalidMsg');
		const path = this.props.queryPath;
		const queryParameter = modelNameCamelCase + 'Ids';

		return (
			<>
				{this.props.children({ 
					getValidatedRemove: this.getValidatedRemove,
					getValidatedSave: this.getValidatedSave 
				})}
				{!this.isDeletable && (
					<>
						<div className='text-danger'>{invalidDeletingMsg}</div>
						{path && (
							<Link
								to={(location) => {
									//если не присвоить, то тогда routeStore обнулит квери параметры (см исходники routeStore _onChange)
									store.route.path = path;
									return path + '?user=ALL&' + [queryParameter] + '[]=' + [this.record.id];
								}}
							>
								{t('show')}
							</Link>
						)}
					</>
				)}
				{!this.isSave && (
					<>
						<div className='text-danger'>{invalidSavingMsg}</div>
						{path && (
							<Link
								to={(location) => {
									//если не присвоить, то тогда routeStore обнулит квери параметры (см исходники routeStore _onChange)
									store.route.path = path;
									return path + '?user=ALL&' + [queryParameter] + '[]=' + [this.record.id];
								}}
							>
								{t('show')}
							</Link>
						)}
					</>
				)}
			</>
		);
	}
}

