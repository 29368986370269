import React from 'react';
import { action, observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import i18next from 'i18next';
import { withRouter } from 'react-router';

import {
	Form,
	Field,
	Select,
} from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import data from './data';

const mapToSelect = row => ({ value: row.id, label: row.name });

@withRouter @observer
class ACLEdit extends React.Component {

	@observable record = null;
	@observable principalType = null;
	@observable roles = null;
	@observable users = null;

	constructor (props) {
		super(props);
		store.ui.title = t('acl.title');

		this.data = {
			accessTypes: data.ACCESS_TYPE.map(mapToSelect),
			permissions: data.PERMISSION.map(mapToSelect),
			principalTypes: data.PRINCIPAL_TYPE.map(mapToSelect),
		};

		this.models = Object.keys(store.model._models).map(modelName => ({
			value: modelName,
			label: i18next.exists(`${modelName}.title`) ? t(`${modelName}.title`) : modelName,
		}));

		this.loadData();
	}

	@action loadData = async() => {
		this.id = this.props.match.params.id;

		if (this.id) {
			this.record = await store.model.ACL.findById(this.id);
		} else {
			this.record = new store.model.ACL();
		}

		this.roles = await store.model.Role.find();
		this.users = await store.model.User.find();
	};

	back = () => {
		let rootPath = this.props.rootPath || '';
		if (rootPath.length > 0) {
			rootPath = rootPath.replace(/\/$/, '');
		}
		this.props.history.push(`${rootPath}/acls`);
	};

	render() {
		if (!this.roles) return '...';
		if (!this.record) return '...';

		return <Form
			record={this.record}
			stay
			onSave={this.back}
			onDelete={this.back}
		>
			<Field property="model" label={t('acl.model')}>
				<Select items={this.models} position="bottomLeft" itemsPerPage={10000}/>
			</Field>

			<Field property="property" label={t('acl.property')}/>

			<Field property="accessType" label={t('acl.accessType')}>
				<Select items={this.data.accessTypes} position="bottomLeft" itemsPerPage={10000}/>
			</Field>

			<Field property="permission" label={t('acl.permission')}>
				<Select items={this.data.permissions} position="bottomLeft" itemsPerPage={10000}/>
			</Field>

			<Field property="principalType" label={t('acl.principalType')}>
				<Select items={this.data.principalTypes} position="bottomLeft" itemsPerPage={10000}/>
			</Field>

			{this.record.principalType === 'USER' && this.users && (
				<Field property="principalId" label={t('acl.principalId')}>
					<Select items={this.users.map(u => ({value: u.id, label: u.username}))} position="bottomLeft" itemsPerPage={10000}/>
				</Field>
			)}

			{this.record.principalType === 'ROLE' && this.roles && (
				<Field property="principalId" label={t('acl.principalId')}>
					<Select value={this.record.principalId} items={this.roles.map(r => ({value: r.name, label: r.name}))} position="bottomLeft" itemsPerPage={10000}/>
				</Field>
			)}
		</Form>;
	}
}

export default ACLEdit;
