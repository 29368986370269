import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { camelCase, divide } from 'lodash';
import { Collapse } from '@consta/uikit/Collapse';
import { Checkbox } from '@consta/uikit/Checkbox';
import store from 'client/store';
import t from 'i18n';
import { AutoAnimate } from 'client/components/auto-animate';

import './leftSide.scss';

@inject('graphicReportStore')
@observer
export class CollapsedMenu extends Component {
	@observable isOpen = this.props.isOpen ?? true;

	constructor(props) {
		super(props);
		this.store = props.graphicReportStore;
	}

	onClick = () => {
		this.isOpen = !this.isOpen;
	};

	render() {
		const { items, label, onChange } = this.props;
		const { selectedParams, getParameterName, selectAll, removeAll } = this.store;

		return (
			<Collapse
				className='wrapper-collapse-menu'
				view='ghost'
				label={
					<div className='collapse-label'>
						<div>{label}</div>
					</div>
				}
				horizontalSpace='xl'
				rightSide={[
					<div className='collapse_select-options'>
						<div
							onClick={(e) => {
								e.stopPropagation();
								selectAll(items);
							}}
						>
							{t('selectAll')}
						</div>
						<div
							onClick={(e) => {
								e.stopPropagation();
								removeAll(items);
							}}
						>
							{t('removeAll')}
						</div>
					</div>,
				]}
				onClick={this.onClick}
				isOpen={this.isOpen}
			>
				<AutoAnimate className={'collapsed-menu'}>
					{items.map((param) => (
						<Checkbox checked={selectedParams.has(param.code)} key={param.code} onChange={onChange(param)} label={getParameterName(param)} />
					))}
				</AutoAnimate>
			</Collapse>
		);
	}
}
