import React from 'react';
import { Table } from '@smartplatform/ui';

import './style.scss';

const Wrapper = ({ children }) => (
	<div id='table' className='table-wrapper'>
		{children}
	</div>
);
export const WrappedTable = (props) => <Table wrapper={Wrapper} {...props} />;

WrappedTable.propTypes = { ...Table.propTypes };

