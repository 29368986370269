import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { BasicList } from 'components';
import { Column } from '@smartplatform/ui';
import { camelCase } from 'lodash';
import { getLabelName } from 'client/tools';

@observer
export class List extends Component {
	constructor(props) {
		super(props);
		this.modelNameCamelCase = camelCase(props.modelName);
		this.model = store.model[props.modelName];
		this.access = store.model.ACL && store.model.ACL.INFO.WRITE;

		this.relations = props.listRelations.map((relation) => {
			relation.model = this.model.RELATIONS[relation.relation]?.model;
			return relation;
		});
	}

	render() {
		const { listProperties, listRelations } = this.props;

		const include = listRelations.map(({ relation, property, properties = [] }) => ({
			relation,
			scope: { fields: property || properties.filter(Boolean) },
		}));

		return (
			<BasicList
				model={this.model}
				filter={{
					include,
				}}
				disabledButton={!this.access}
				title={t('viewEngine.plural')}
			>
				{listProperties.map(({ property, ...rest }, i) => (
					<Column key={i} property={property} label={t(`${this.modelNameCamelCase}.${property}`)} {...rest} />
				))}
				{this.relations.map(({ relation, property, model, ...rest }, i) => {
					const label = getLabelName('title', model);
					return <Column key={i} relation={relation} property={property} label={label} {...rest} />;
				})}
			</BasicList>
		);
	}
}
