import { REQUIRED_FLIGHT_PHASE_NAME } from 'client/constants';

export const CRUISE_AIRCRAFT_PARAMETER_NAMES = ['ZALT', 'ZT1A', 'SAT', 'AGW', 'ZXM'];

const GENERAL_ENGINE_PARAMS = ['ZPCN12', 'ZPCN25', 'ZT49', 'ZVB1F'];

export const CRUISE_ENGINE_PARAMETER_NAMES = [...GENERAL_ENGINE_PARAMS, 'ZWF36', 'ZPOIL', 'ZTOIL', 'C_DOP', 'C_FFD'];

export const TAKEOFF_AIRCRAFT_PARAMETER_NAMES = [...CRUISE_AIRCRAFT_PARAMETER_NAMES, 'CAS'];

export const TAKEOFF_ENGINE_PARAMETER_NAMES = [...GENERAL_ENGINE_PARAMS, 'ZVB2R', 'ZVB1R', 'ZVB2R', 'C_N1'];


export const DAT_ENGINE_PARAMETER_NAMES = [];
export const DAT_AIRCRAFT_PARAMETER_NAMES = [];

export const TAKEOFF = 'TAKEOFF';
export const CRUISE = 'CRUISE';

export const BUTTONS = REQUIRED_FLIGHT_PHASE_NAME.map((flightPhaseName) => {
	let fields = {};
	if (flightPhaseName === TAKEOFF) {
		fields = {
			phaseName: TAKEOFF,
			aircraftParams: [...TAKEOFF_AIRCRAFT_PARAMETER_NAMES],
			engineParams: [...TAKEOFF_ENGINE_PARAMETER_NAMES],
		};
	} else if (flightPhaseName === CRUISE) {
		fields = {
			phaseName: CRUISE,
			aircraftParams: [...CRUISE_AIRCRAFT_PARAMETER_NAMES, ...DAT_ENGINE_PARAMETER_NAMES],
			engineParams: [...CRUISE_ENGINE_PARAMETER_NAMES, ...DAT_AIRCRAFT_PARAMETER_NAMES],
		};
	}

	return fields;
});

const _COLUMNS = [
	{ property: 'flightTime', width: 150, label: 'flightTime' },
	{ property: 'altitude', label: 'ZALT' },
	{ property: 'mach', label: 'ZXM' },
	{ property: 'tat', label: 'ZT1A' },
	{ property: 'agw', label: 'AGW' },
	{ property: 'egt_1', label: 'ZT49' },
	{ property: 'n1_1', label: 'ZPCN12' },
	{ property: 'n2_1', label: 'ZPCN25' },
	{ property: 'egt_2', label: 'ZT49' },
	{ property: 'n1_2', label: 'ZPCN12' },
	{ property: 'n2_2', label: 'ZPCN25' },
];

const SHARED_COLUMNS = [
	{ property: 'sat', label: 'SAT' },
	{ property: 'ZVB1F_1', label: 'ZVB1F' },
	{ property: 'ZVB1F_2', label: 'ZVB1F' },
];

export const TAKEOFF_COLUMNS = [
	{ property: 'cas', label: 'CAS' },
	{ property: 'ZVB1R_1', label: 'ZVB1R' },
	{ property: 'ZVB1R_2', label: 'ZVB1R' },
	{ property: 'ZVB2F_1', label: 'ZVB2F' },
	{ property: 'ZVB2F_2', label: 'ZVB2F' },
	{ property: 'ZVB2R_1', label: 'ZVB2R' },
	{ property: 'ZVB2R_2', label: 'ZVB2R' },
	{ property: 'C_N1_1', label: 'C_N1' },
	{ property: 'C_N1_2', label: 'C_N1' },
];
export const CRUISE_COLUMNS = [
	{ property: 'ZTOIL_1', label: 'ZTOIL' },
	{ property: 'ZTOIL_2', label: 'ZTOIL' },
	{ property: 'ZPOIL_1', label: 'ZPOIL' },
	{ property: 'ZPOIL_2', label: 'ZPOIL' },
	{ property: 'ZWF36_1', label: 'ZWF36' },
	{ property: 'ZWF36_2', label: 'ZWF36' },
	{ property: 'C_DOP_1', label: 'C_DOP' },
	{ property: 'C_DOP_2', label: 'C_DOP' },
	{ property: 'C_FFD_1', label: 'C_FFD' },
	{ property: 'C_FFD_2', label: 'C_FFD' },

	// { property: 'PW100_ID_1', label: 'PW100-ID' },
	// { property: 'PW100_ID_2', label: 'PW100-ID' },
	// { property: 'PW100_SN_1', label: 'PW100-SN' },
	// { property: 'PW100_SN_2', label: 'PW100-SN' },
	// { property: 'PW100_FDATE_1', label: 'PW100-FDATE' },
	// { property: 'PW100_FDATE_2', label: 'PW100-FDATE' },
	// { property: 'PW100_REF_1', label: 'PW100-REF'  },
	// { property: 'PW100_REF_2', label: 'PW100-REF'  },
	// { property: 'PW100_CYC_1', label: 'PW100-CYC' },
	// { property: 'PW100_CYC_2', label: 'PW100-CYC' },
	// { property: 'PW100_PRESS_1', label: 'PW100-PRESS' },
	// { property: 'PW100_PRESS_2', label: 'PW100-PRESS' },
	// { property: 'PW100_OAT_1',label: 'PW100-OAT' },
	// { property: 'PW100_OAT_2',label: 'PW100-OAT' },
	// { property: 'PW100_IAS_1',  label: 'PW100-IAS' },
	// { property: 'PW100_IAS_2',  label: 'PW100-IAS' },
	// { property: 'PW100_TORQUE_1',  label: 'PW100-TORQUE' },
	// { property: 'PW100_TORQUE_2',  label: 'PW100-TORQUE' },
	// { property: 'PW100_NP_1',  label: 'PW100-NP' },
	// { property: 'PW100_NP_2',  label: 'PW100-NP' },
	// { property: 'PW100_NL_1',  label: 'PW100-NL' },
	// { property: 'PW100_NL_2',  label: 'PW100-NL' },
	// { property: 'PW100_NH_1',  label: 'PW100-NH' },
	// { property: 'PW100_NH_2',  label: 'PW100-NH' },
	// { property: 'PW100_ITT_1',  label: 'PW100-ITT' },
	// { property: 'PW100_ITT_2',  label: 'PW100-ITT' },
	// { property: 'PW100_WF_1',  label: 'PW100-WF' },
	// { property: 'PW100_WF_2',  label: 'PW100-WF' },
	// { property: 'PW100_UWF_1', label: 'PW100-UWF' },
	// { property: 'PW100_UWF_2', label: 'PW100-UWF' },
	// { property: 'PW100_MNT_1', label: 'PW100-MNT' },
	// { property: 'PW100_MNT_2', label: 'PW100-MNT' },
];

Object.keys(TAKEOFF_COLUMNS).forEach((key) => {
	TAKEOFF_COLUMNS[key].isSelected = false;
	TAKEOFF_COLUMNS[key].phaseName = 'TAKEOFF';

	if (TAKEOFF_COLUMNS[key].label === 'C_N1') {
		TAKEOFF_COLUMNS[key].isSelected = true;
	}
});
Object.keys(CRUISE_COLUMNS).forEach((key) => {
	CRUISE_COLUMNS[key].isSelected = false;
	CRUISE_COLUMNS[key].phaseName = 'CRUISE';
});
Object.keys(SHARED_COLUMNS).forEach((key) => {
	SHARED_COLUMNS[key].isSelected = false;
	SHARED_COLUMNS[key].isShared = true;
});
Object.keys(_COLUMNS).forEach((key) => {
	_COLUMNS[key].isSelected = true;
	_COLUMNS[key].isShared = true;
});
export const COLUMNS = _COLUMNS.concat(SHARED_COLUMNS, TAKEOFF_COLUMNS, CRUISE_COLUMNS);

export const DANGEROUS_LEVEL = {
	low: {
		color: 'rgb(229, 203, 33, .7)',
		coefficient: 0.6,
		itemColor: '#E56921',
	},
	medium: {
		color: 'rgb(229, 105, 33, .5)',
		coefficient: 0.8,
		itemColor: '#E5CB21',
	},
	high: {
		color: 'rgb(199, 14, 17,.5)',
		coefficient: 1,
		itemColor: '#d72631',
	},
};

export const KG_TO_LBS = 2.2046226;

