import React from 'react';
import classNames from 'classnames';
import { SUCCESS } from './constants';
import { Button } from 'components';
import store from 'client/store';
import PropTypes from 'prop-types';
import t from 'i18n';
import { observer } from 'mobx-react';

@observer
export class Notification extends React.Component {
	static propTypes = {
		message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
		id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		onClick: PropTypes.func,
		onConfirm: PropTypes.func,
		onReject: PropTypes.func,
		isAlarm: PropTypes.bool,
	};

	static defaultProps = {
		type: SUCCESS,
		isAlarm: false,
	};

	removeNotification = (callback) => {
		if (this.props.onClick) {
			this.props.onClick();
		} else {
			store.ui.removeNotification(this.props.id);
		}
		if (typeof callback === 'function') {
			callback();
		}
	};

	render() {
		const { message, type, onConfirm, soundCode } = this.props;
		const { removeNotification } = this;
		const hasControls = !!onConfirm;

		const notificationProps = {
			className: classNames('notification', `notification-${type?.toLowerCase()}`, `notification-animation-${!hasControls ? 'basic' : 'no-fade-out'}`, {
				'default-cursor': hasControls,
			}),
			onClick: removeNotification,
		};

		if (!hasControls) {
			//на случай свернутого окна
			setTimeout(() => removeNotification(this.props.id), 17000);
			// notificationProps.onAnimationEnd = () => removeNotification(this.props.id);
		}

		return (
			<div {...notificationProps}>
				<div className='notification-message-text'>{message}</div>
				{hasControls && (
					<div className='controls'>
						<Button className='open-button' onClick={() => removeNotification(onConfirm)}>
							{t('open')}
						</Button>
					</div>
				)}
			</div>
		);
	}
}
