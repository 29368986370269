import React from 'react';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';
import { startOfDay, endOfDay } from 'date-fns';
import { Pager } from '@smartplatform/ui';
import store from 'client/store';
import { formatDate, fioShort } from 'client/tools';
import aStore from './store';
import t from 'i18n';
import SortIcon from 'client/img/icons/sort.svg';
import Filters from './Filters';

@observer
export default class List extends React.Component {
	constructor(props) {
		super(props);
		this.updateHeight = debounce(this.updateHeight, 250, { leading: false, trailing: true });
	}

	componentDidMount() {
		this.init();
		window.addEventListener('resize', this.updateHeight);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateHeight);
	}

	init = async () => {
		const { startDate, endDate, model, action, owner, order, perPage, page } = aStore;
		let where = { and: [] };
		if (startDate) where.and.push({ createdAt: { gte: startOfDay(new Date(startDate)) } });
		if (endDate) where.and.push({ createdAt: { lte: endOfDay(new Date(endDate)) } });
		if (model) where.and.push({ model });
		if (action) where.and.push({ action });
		if (owner) where.and.push({ ownerId: owner.id });

		if (where.and.length === 0) where = undefined;

		aStore.records = await store.model.Audit.find({
			where,
			include: ['owner'],
			order,
			limit: perPage,
			skip: (page - 1) * perPage,
		});

		store.ui.title = t('audit.title');
	};

	onPageChange = (page) => {
		aStore.page = page;
		this.init();
	};

	select = (record, el) => {
		// получаем все элементы и убираем active
		const auditItemsAll = document.querySelectorAll('.audit-table-item');
		[...auditItemsAll].map((item) => item.classList.remove('active'));

		// устанавливает active
		if (![...el.currentTarget.classList].includes('active')) el.currentTarget.classList.add('active');

		store.route.push({ path: `${this.props.path}${record.id}` });
	};

	onListMount = (el) => {
		this.listEl = el;
		this.setPerPage();
	};

	updateHeight = () => {
		this.setPerPage();
		aStore.page = 1;
		this.init();
	};

	setPerPage = () => {
		if (this.listEl) {
			const rect = this.listEl.getBoundingClientRect();
			aStore.perPage = Math.round(rect.height / 63) - 1;
		}
	};

	sortBy = (field) => {
		if (aStore.order === field) {
			aStore.order = `${field} desc`;
		} else {
			aStore.order = `${field}`;
		}
		this.init();
	};

	toggleEditPopup = () => {
		this.showFilterPopup = !this.showFilterPopup;
	};

	render() {
		const { children } = this.props;
		const { order, perPage, page } = aStore;

		return (
			<div className='audit-list'>
				<div className='audit-header'>
					<h2>{t('audit.title')}</h2>
					<Filters onChange={this.init} />
				</div>
				<Pager current={page} onChange={this.onPageChange} itemsPerPage={perPage} totalCount={aStore.records.totalCount} />
				<div className='audit-body'>
					<div className='audit-table'>
						<div className='audit-table-headers'>
							<div className='audit-table-header date' onClick={() => this.sortBy('createdAt')}>
								<SortIcon className={order === 'createdAt' ? 'icon-sorted' : undefined} />
								{t('date')}
							</div>
							<div className='audit-table-header description' onClick={() => this.sortBy('model')}>
								<SortIcon className={order === 'model desc' ? 'icon-sorted' : undefined} />
								{t('description')}
							</div>
							<div className='audit-table-header action' onClick={() => this.sortBy('action')}>
								<SortIcon className={order === 'action desc' ? 'icon-sorted' : undefined} />
								{t('audit.action')}
							</div>
							<div className='audit-table-header owner' onClick={() => this.sortBy('ownerId')}>
								<SortIcon className={order === 'ownerId desc' ? 'icon-sorted' : undefined} />
								{t('audit.owner')}
							</div>
						</div>
						<div className='audit-table-items-wrapper' ref={this.onListMount}>
							<div className='audit-table-items'>
								{aStore.records.map((record) => (
									<div key={record.id} className='audit-table-item' onClick={(el) => this.select(record, el)}>
										<div className='date'>{formatDate(record.createdAt, 'dd.MM.yyyy HH:mm:ss')}</div>
										<div className='description'>
											{store.model[record.model]?.INFO.description || <span>{record.model}</span>}
										</div>
										<div className={'action ' + record.action}>{t(`audit.${record.action}`)}</div>
										<div className='owner'>{record.owner ? fioShort(record.owner) : '-'}</div>
									</div>
								))}
							</div>
						</div>
					</div>
					{children}
				</div>
			</div>
		);
	}
}

