import React from 'react';
import { observer } from 'mobx-react';
import { DatePicker } from '@consta/uikit/DatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import { LabelField } from 'components';
import { Select } from '@smartplatform/ui';
import { t } from 'i18next';
import { observable } from 'mobx';

@observer
export default class Dates extends React.Component {
	@observable period = null;
	constructor(props) {
		super(props);
	}

	duplicateDate = () => {
		const { startDate, endDate } = this.props;
		if (startDate && !endDate) this.props.onChange('endDate')({ value: startDate });
		if (!startDate && endDate) this.props.onChange('startDate')({ value: endDate });
	};

	onPeriodChange = (value) => {
		this.period = value;
		let startDate = null;
		let endDate = null;
		let today = new Date();
		endDate = today;
		if (value === 'WEEK') {
			startDate = new Date(new Date().setDate(today.getDate() - 7));
		} else if (value === 'MONTH') {
			startDate = new Date(new Date().setMonth(today.getMonth() - 1));
		} else if (value === 'THREE_MONTHS') {
			startDate = new Date(new Date().setMonth(today.getMonth() - 3));
		} else if (value === 'QUARTER') {
			const quarterMonth = Math.floor(today.getMonth() / 3) * 3;
			startDate = new Date(today.getFullYear(), quarterMonth, 1);
		} else {
			endDate = null;
		}
		this.props.onChange('startDate')({ value: startDate });
		this.props.onChange('endDate')({ value: endDate });
	};

	render() {
		const { startDate, endDate, onChange } = this.props;

		const itemsPreset = [
			{ label: t('aircraft.period.week'), value: 'WEEK' },
			{ label: t('aircraft.period.month'), value: 'MONTH' },
			{ label: t('aircraft.period.threeMonths'), value: 'THREE_MONTHS' },
			{ label: t('aircraft.period.quarter'), value: 'QUARTER' },
		];
		return (
			<div className='dates'>
				<div className='wrapper-dates'>
					<DatePicker size='m' type='date' value={startDate} onChange={onChange('startDate')} withClearButton />
					<div className='value-duplicator'>
						{startDate && !endDate && (
							<div onClick={this.duplicateDate}>
								<FontAwesomeIcon icon={faArrowAltCircleRight} />
							</div>
						)}
						{endDate && !startDate && (
							<div onClick={this.duplicateDate}>
								<FontAwesomeIcon icon={faArrowAltCircleLeft} />
							</div>
						)}
					</div>
					<DatePicker size='m' type='date' value={endDate} onChange={onChange('endDate')} withClearButton />
				</div>
				<div className='wrapper-presets'>
					<LabelField label={t('aircraft.period.title')}>
						<Select items={itemsPreset} onChange={this.onPeriodChange} value={this.period} />
					</LabelField>
				</div>
			</div>
		);
	}
}
