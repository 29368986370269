import React from 'react';
import { inject, observer } from 'mobx-react';
import { ColorPicker, Popup } from '@smartplatform/ui';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import { t } from 'i18next';
import { Button } from 'components';

@inject('graphicReportStore')
@observer
export default class ColorsChart extends React.Component {
	@observable showPopup = false;
	constructor(props) {
		super(props);
		this.store = props.graphicReportStore;
	}

	togglePopUp = () => {
		this.showPopup = !this.showPopup;
	};

	render() {
		const { colorsChart, onChangeColor } = this.store;
		return (
			<div className='chart-settings'>
				<div className='icon' onClick={this.togglePopUp}>
					<FontAwesomeIcon icon={faGear} />
				</div>
				{this.showPopup && (
					<Popup showCloseButton canClose onClose={this.togglePopUp} className='popup-chart-settings'>
						<div className='content-settings'>
							<p className='head'>
								<strong>{t('aircraft.graphicReport.chartSettings')}</strong>
							</p>
							<div className='item-setting'>
								<p>{t('aircraft.graphicReport.engineColors')}</p>
								<div className='content'>
									{Object.keys(colorsChart).map((key) => (
										<div className='color-item'>
											{t('engine.title')} {key}
											<ColorPicker value={colorsChart[key]} onChange={onChangeColor(key)} />
										</div>
									))}
								</div>
							</div>
						</div>
					</Popup>
				)}
			</div>
		);
	}
}
