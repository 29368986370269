import { observable } from 'mobx';
import { endOfDay, format, formatISO, parseISO, startOfDay } from 'date-fns';
import store from 'client/store';
import t from 'i18n';

export class logStore {
	@observable uploadDate = null;
	@observable flightDate = null;
	@observable aircraft = null;
	@observable status = null;
	@observable filters = {};
	localStorage = store.local.importLog;
	duplicateErrorCode = 'logs.duplicate';

	constructor() {
		this.loadLocalStorage();
		this.updateFilters();
	}

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.updateFilters();
		this.saveValuesFilter();
	};

	loadLocalStorage = () => {
		const storageKeys = Object.keys(this.localStorage);
		if (storageKeys.length)
			storageKeys.forEach((key) => {
				this[key] = this.localStorage[key];
			});
	};

	saveValuesFilter = () => {
		this.localStorage['status'] = this.status;
		store.local.save();
	};

	onReset = () => {
		this.uploadDate = null;
		this.flightDate = null;
		this.aircraft = null;
		this.filters = {};
		this.status = null;
	};

	updateFilters() {
		const filters = {
			where: {
				and: [],
			},
		};

		if (this.uploadDate) {
			filters.where.and.push(
				{
					createdAt: {
						gte: startOfDay(this.uploadDate),
					},
				},
				{
					createdAt: {
						lte: endOfDay(this.uploadDate),
					},
				}
			);
		}

		if (this.flightDate) {
			filters.where.and.push(
				{
					flightDateFromFile: {
						gte: startOfDay(this.flightDate),
					},
				},
				{
					flightDateFromFile: {
						lte: endOfDay(this.flightDate),
					},
				}
			);
		}

		if (this.aircraft) {
			filters.where.and.push({ aircraftId: this.aircraft.id });
		}

		if (this.status) {
			if (this.status === 'success') {
				filters.where.and.push({ error: { eq: null }, warning: { eq: null } });
			}

			if (this.status === 'error') {
				filters.where.and.push({ or: [{ error: { neq: null } }, { warning: { neq: null } }] });
			}
		}

		this.filters = filters;
	}
}
