import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { Avatar } from '@smartplatform/ui';
import { fioShort } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

import './user.scss';

@observer
export class User extends React.Component {
	constructor(props) {
		super(props);
	}

	logout = (e) => {
		e.preventDefault();
		store.model.logout();
	};

	render() {
		return store.model.user ? (
			<div className='user-section' ref={this.onMount}>
				<Link to='/profile' className='profile-link'>
					<Avatar user={store.model.user} size={30} /> <span className='fio'>{fioShort(store.model.user)}</span>
				</Link>
				<a className='logout' href='#' onClick={this.logout}>
					{t('logout')}
				</a>
			</div>
		) : null;
	}
}

