import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { BasicList } from 'components';
import { Column } from '@smartplatform/ui';
import { camelCase } from 'lodash';
import { getLabelName } from 'client/tools';

@observer
export class List extends Component {
	constructor(props) {
		super(props);
		this.modelNameCamelCase = camelCase(props.modelName);
		this.model = store.model[props.modelName];
		this.access = store.model.ACL && store.model.ACL.INFO.WRITE;
	}

	render() {
		return (
			<BasicList model={this.model} disabledButton={!this.access}>
				<Column property='name' label={t('engineFamily.name')} />
				<Column property='numberBlades' label={t('engineFamily.numberBlades')} />
				<Column relation='manufacturer' property='name' label={t('engineManufacturer.name')} />
			</BasicList>
		);
	};
};