import React from 'react';
import { observable } from 'mobx';
import { Column, Pager, Table } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { ListStore, Toolbar } from 'components';
import { NOTIFY_SCHEDULE_INCLUDE } from './constants';
import { EditPopup } from './edit-popup';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export class TransportModule extends React.Component {
	@observable showEditPopup = false;
	@observable selectedRecord = null;

	constructor(props) {
		super(props);

		store.ui.title = 'Транспортный модуль';

		const model = store.model.NotifySchedule;

		const filters = {
			...NOTIFY_SCHEDULE_INCLUDE,
		};
		this.store = new ListStore({ path: props.path, filters, model });
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	toggleEditPopup = (record) => {
		this.selectedRecord = record || null;
		this.showEditPopup = !this.showEditPopup;
	};

	onClosePopup = () => {
		this.toggleEditPopup();
		this.store.reload();
	};

	render() {
		if (this.error) return <div className='error'>{this.error}</div>;
		const { onChange, totalCount, page, perPage, tableProps } = this.store;

		return (
			<div className='transport-module-page'>

				<h2>{t('transportData.title')}</h2>
				<Toolbar.AddButton onClick={() => this.toggleEditPopup()} />
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div id='table'>
					<Table {...tableProps} onRowClick={this.toggleEditPopup}>
						<Column property='active' label={t('transportData.active')} />
						<Column relation='type' property='name' label={t('notifyRecipient.type')} />
					</Table>
				</div>
				{this.showEditPopup && (
					<EditPopup
						onClose={this.onClosePopup}
						record={this.selectedRecord}
						reportForeignKey={this.reportForeignKey}
						reportRelation={this.props.reportRelation}
					/>
				)}
			</div>
		);
	}
}