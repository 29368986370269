import { observable } from 'mobx';
import store from 'client/store';
// import * as parser from 'cron-parser';

export const PER_PAGE = 15;

// Базовый стор для стандартного листа с поиском, пагинацией, запросом
export class ScheduleStore {
	@observable record = null;
	@observable hour = '0';
	@observable dayOfWeek = null;
	@observable day = null;
	@observable nextDate = null;
	@observable period = null;
	@observable group = null;
	@observable id = null;

	@observable isInitialized = false;

	//logs
	@observable notifyLogs = [];
	@observable page = 1;

	constructor(record) {
		this.model = store.model.NotifySchedule;
		this.record = record || new this.model();
		this.isNew = !this.id;
		this.init();
	}

	init = async () => {
		if (!this.isNew) await this.fetchLogs();
		// !this.record.cronString && this.setCronString();
		// this.parseCronString(this.record.cronString);
		// !this.record.nextDate && this.setNextDate();
		// this.setPeriod();
		this.isInitialized = true;
	};

	fetchLogs = async () => {
		this.notifyLogs = await store.model.NotifyLog.find({
			where: { scheduleId: this.record.id },
			order: 'id desc',
			limit: PER_PAGE,
			skip: (this.page - 1) * PER_PAGE,
		});
	};

	parseCronString = (string) => {
		if (!string) return;
		const arr = string.split(' ');
		this.hour = arr[1] !== '*' ? arr[1] : '0';
		this.day = arr[2] !== '*' ? arr[2] : null;
		this.dayOfWeek = arr[4] !== '*' ? arr[4] : null;
	};

	setCronString = () => (this.record.cronString = `0 ${this.hour || '0'} ${this.day || '*'} * ${this.dayOfWeek || '*'}`);
	setNextDate = () => (this.record.nextDate = parser.parseExpression(this.record.cronString).next().toString());
	setPeriod = () => {
		if (this.dayOfWeek) {
			this.period = 'WEEK';
			return;
		}
		if (this.day) {
			this.period = 'MONTH';
			return;
		}
		this.period = 'DAY';
	};

	onHourChange = (h) => {
		this.hour = h;
		this.setCronString();
		this.setNextDate();
	};
	onDayChange = (d) => {
		this.day = d;
		this.setCronString();
		this.setNextDate();
		this.period !== 'MONTH' && this.cleanPeriod();
	};
	onDayOfWeekChange = (dw) => {
		this.dayOfWeek = dw;
		this.setCronString();
		this.setNextDate();
		this.period !== 'WEEK' && this.cleanPeriod();
	};
	onPeriodChange = (period) => {
		this.period = period;
		switch (this.period) {
			case 'DAY': {
				this.day = null;
				this.dayOfWeek = null;
				break;
			}
			case 'WEEK': {
				this.dayOfWeek = '1';
				this.day = null;
				break;
			}
			case 'MONTH': {
				this.dayOfWeek = null;
				this.day = '1';
				break;
			}
		}
		this.setCronString();
		this.setNextDate();
	};

	cleanPeriod = () => (this.period = null);

	onReportChange = (r) => (this.record.report = r);
	onGroupChange = (g) => (this.group = g);
	onPageChange = async (page) => {
		this.page = page;
		this.fetchLogs();
	};

	beforeSave = () => (this.record.lastSend = new Date());
}
