import React from 'react';
import './plane-icon.scss';
import { SvgIcon } from 'components/svgIcon';

export function PlaneIcon(aircraft) {
	const numberEngines = aircraft.family?.numberEngines;
	let engines = [];
	engines = aircraft.engines();
	let iconName = aircraft.family?.isScrew ? 'screw-' : 'jet-';
	let nameEnginesIcon = Array(numberEngines).fill('0');

	for(const engine of engines){
		if(engine?.enginePosition) nameEnginesIcon[engine?.enginePosition - 1] = '1';
	}

	nameEnginesIcon =  nameEnginesIcon.join('');
	iconName += `${numberEngines}-`;
	iconName += nameEnginesIcon;

	return(
		<div className='plane-icon'>
			<div className='icon-container'>
				<SvgIcon name={iconName} />
			</div>
		</div>
	);
}

