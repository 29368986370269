import React from 'react';
import { RecordSelect } from '@smartplatform/ui';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { renderProject } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

export const ToolbarProjectSelect = observer(({ filter, label, ...restProps }) => {
	return (
		<div className='toolbar-project-select'>
			{label && <label>{label}</label>}
			<RecordSelect
				model={store.model.Project}
				computed={renderProject}
				filter={{ include: ['projectList'], ...filter }}
				searchPlaceholder={t('project.search')}
				itemsPerPage={10000}
				showSelectControls //из-за sortSelected дублируются записи
				className='project-select'
				{...restProps}
			/>
		</div>
	);
});

ToolbarProjectSelect.propTypes = {
	filter: PropTypes.object,
};

ToolbarProjectSelect.defaultProps = {
	filter: { order: 'name asc' },
};

