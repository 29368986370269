import { DndContext } from '@smartplatform/ui';
import 'bootstrap-4-grid/scss/grid.scss';
import { observer } from 'mobx-react';
import React from 'react';
import 'react-image-lightbox/style.css';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Restore, Login, Reset } from 'components';
import Layout from './layout';
import * as Pages from './pages';
import store from './store';
import items from './layout/sidebar/menu/config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/main.scss';
import { RewritePassword } from 'components/rewrite-password';
import { Notifications } from 'components/notifications';

const App = () => {
	if (!store.model.isInitialized) return null;
	if (!store.model.isAuthorized) {
		return (
			<Router history={store.history}>
				<div className='auth'>
					<Notifications />
					<Switch>
						{/*{store.model.User.INFO.CREATE && <Route path='/register' component={Register} />}*/}
						<Route path='/restore' component={Restore} />
						<Route path='/login' component={Login} />
						<Route path='/reset-password/:token' component={Reset} />
						<Redirect from='/*' to='/login' />
					</Switch>
				</div>
			</Router>
		);
	}

	const _passwordExpirationTime = parseInt(store.mergedConfig?.authentication?.password?.passwordExpirationTime);
	const enableRewrite = !!_passwordExpirationTime && _passwordExpirationTime !== 0;
	if (enableRewrite && (!store.model.user.passwordTimeout || new Date() > new Date(store.model.user.passwordTimeout))) {
		return (
			<Router history={store.history}>
				<div className='auth'>
					<Notifications />
					<Redirect from='/*' to='/rewrite-password' />
					<Route path='/rewrite-password' component={RewritePassword} />
				</div>
			</Router>
		);
	}

	const className = 'project-vars';

	const isAccessAllUser = store.model.ACL && store.model.ACL.INFO.WRITE && store.model.ACL.INFO.READ;
	const isAccessOperator = store.model.Engine && store.model.Engine.INFO.WRITE && store.model.Engine.INFO.READ;

	const menuPlanesItems = items[0].items;
	const menuAdminItems = items[1].items.filter((item) => {
		if (item.path === '/dictionaries' && isAccessOperator) {
			return true;
		}
		return isAccessAllUser;
	});

	return (
		<DndContext className={className} key={store.appKey}>
			<Router history={store.history}>
				<Layout>
					<ToastContainer position='top-right' autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} limit={6} theme='light' />
					<Notifications />
					<Switch>
						<Route path='/' exact>
							<Redirect to='/planes' />
						</Route>
						{menuPlanesItems.map(({ path, Component }) => {
							return <Route key={path} path={path} render={(routeProps) => <Component path={path} {...routeProps} />} />;
						})}
						{menuAdminItems.map(({ path, Component }) => {
							return <Route key={path} path={path} render={(routeProps) => <Component path={path} {...routeProps} />} />;
						})}

						<Route path='/profile' component={Pages.Profile} />
					</Switch>
				</Layout>
			</Router>
		</DndContext>
	);
};

export default observer(App);
