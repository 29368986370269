import React from 'react';
import store from 'client/store';
import { Tab, Tabs } from '@smartplatform/ui';
import { TransportModule } from './transport-module';
import { Audit } from './audit';
import t from 'i18n';
import PasswordSettings from 'client/pages/settings/password/PasswordSettings';
import { OperatorSetting } from 'client/pages/settings/operator';
import { MainSettings } from 'client/pages/settings/main-settings';
import { AuthenticationSettings } from 'client/pages/settings/authentication';

export const Settings = (props) => {
	const { path } = props.match;
	return (
		<Tabs>
			<Tab
				title={t('_settings.mainInfo')}
				path={path}
				render={(routeProps) => <MainSettings {...routeProps} />}
				onLoad={() => (store.ui.title = t('_settings.mainInfo'))}
				exact
			/>
			<Tab
				title={t('transportData.title')}
				path={`${path}/transport-module`}
				render={(routeProps) => <TransportModule {...routeProps} />}
				onLoad={() => (store.ui.title = t('transportData.title'))}
				exact
			/>
			<Tab title={t('audit.title')} path={`${path}/journal`} render={(routeProps) => <Audit {...routeProps} />} />
			<Tab title={t('passwordSettings.title')} path={path + '/password'} exact children={<PasswordSettings />} />
			<Tab title={t('operatorSettings.title')} path={`${path}/operator-settings`} exact children={<OperatorSetting />} />
			<Tab title={t('authenticationSettings.title')} path={`${path}/authentication-settings`} exact children={<AuthenticationSettings />} />
		</Tabs>
	);
};
