import store from 'client/store';
import { observable } from 'mobx';
import React from 'react';
import t from 'i18n';

export class aircraftStore {
	@observable aircraft = null;
	@observable isLoading = false;

	id = null;

	constructor(aircraftId) {
		this.id = aircraftId;
	}

	setTitle = () => {
		store.ui.title = t('aircraft.new');

		if (this.aircraft.id) {
			store.ui.title = t('aircraft.title');
		}
	};

	fetchAircraft = async (filter) => {
		let aircraft = new store.model.Aircraft();

		if (this.id) {
			this.isLoading = true;

			aircraft = await store.model.Aircraft.findById(this.id, {
				include: ['manufacturer', 'type', 'family', 'operator', 'holder', 'engines'],
			});
		}

		this.aircraft = aircraft;

		this.isLoading = false;

		this.setTitle();
	};
}
