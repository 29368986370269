import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { Chart } from './Chart';
import { PDFContent } from '../pdfReport';

import 'react-toastify/dist/ReactToastify.css';
import { Loader } from '@consta/uikit/Loader';
import { getLineOption } from 'client/pages/planes/graphicReport/right-side/options';

@inject('graphicReportStore')
@observer
export class RightSide extends Component {
	store = null;

	constructor(props) {
		super(props);
		this.store = props.graphicReportStore;
	}

	render() {
		const { charts, aircraftParams, engineParams, isChartsLoad } = this.store;

		//if (isLoading) return null;
		const mergedParams = [...aircraftParams, ...engineParams];
		const _charts = [];
		const orderedCharts = Array.from(charts.values()).sort((a, b) => {
			const indexParamA = mergedParams.findIndex((r) => r.code === a.param.code) || 0;
			const indexParamB = mergedParams.findIndex((r) => r.code === b.param.code) || 0;
			return indexParamA - indexParamB;
		});
		for (const itemChart of orderedCharts) {
			const option = getLineOption(itemChart);
			_charts.push(<Chart value={itemChart} paramId={itemChart.param.id} key={itemChart.param.code} option={option} />);
		}
		if (_charts.length === 0) {
			return <div>Выберите параметры</div>;
		}

		return (
			<div className='right-side'>
				{isChartsLoad ? (
					<Loader />
				) : (
					<>
						{_charts.map((component) => component)}
						<PDFContent />
					</>
				)}
			</div>
		);
	}
}
