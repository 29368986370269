import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';

import { CollapsedMenu } from './CollapsedMenu';
import { Loader } from '@consta/uikit/Loader';
import classNames from 'classnames';
import { ActionsPanel } from './ActionsPanel';

@inject('graphicReportStore')
@observer
export class LeftSide extends Component {
	store = null;

	constructor(props) {
		super(props);
		this.store = props.graphicReportStore;
	}

	render() {
		const { aircraftParams, engineParams, onParamChange, isLeftSideLoading } = this.store;

		const collapsedMenuConfig = [
			{
				params: aircraftParams,
				label: t('aircraft.name'),
			},
			{
				params: engineParams,
				label: t('engine.name'),
			},
		];

		return (
			<div className={classNames('left-side', { loading: isLeftSideLoading })}>
				{isLeftSideLoading && <Loader />}
				<ActionsPanel />
				<div className='params'>
					{collapsedMenuConfig.map(({ params, ...rest }, i) => (
						<CollapsedMenu key={i} items={params} onChange={onParamChange} {...rest} />
					))}
				</div>
			</div>
		);
	}
}
