import React from 'react';
import { observer } from 'mobx-react';
import { ColorValue, Column, DateValue, Pager, Table } from '@smartplatform/ui';
import store from 'client/store';
import { getLabelName } from 'client/tools';
import { Toolbar, ListStore, BasicList } from 'components';

import { observable } from 'mobx';
import { EXCLUDED_FIELDS } from './constants';
import PropTypes from 'prop-types';

@observer
export class List extends React.Component {
	static propTypes = {
		listRelations: PropTypes.array,
		listProperties: PropTypes.array,
		excludedProperties: PropTypes.array,
	};

	static defaultProps = {
		listRelations: [],
		listProperties: [],
		excludedProperties: [],
	};

	@observable error = null;
	constructor(props) {
		super(props);
		console.log('list');
		this.modelName = props.modelName;
		this.model = store.model[this.modelName];
		this.access = store.model.ACL && store.model.ACL.INFO.WRITE;
		if (!this.model) {
			this.error = 'Invalid model/no read access';
		} else {
			const { listProperties, listRelations, excludedProperties } = props;
			const { PROPERTIES, RELATIONS } = this.model;
			console.log(RELATIONS);
			this.relations = [...listRelations];
			this.relations = this.relations.map((relation) => {
				if (RELATIONS[relation.relation]) {
					relation.model = RELATIONS[relation.relation].model;
				}
				return relation;
			});
			if (!listProperties.length) {
				// если мы не передаём проперти , то берем все за исключением технических ,объектов и гео
				const modelForeignKeys = Object.values(RELATIONS).map(({ foreignKey }) => foreignKey);
				this.properties = Object.keys(PROPERTIES).filter((prop) => {
					return (
						![...EXCLUDED_FIELDS, ...modelForeignKeys].includes(prop) &&
						!['Object', 'Geography'].includes(PROPERTIES[prop].type)
					);
				});
			} else {
				this.properties = [...listProperties];
			}

			if (excludedProperties.length) {
				this.properties = this.properties.filter((prop) => !excludedProperties.includes(prop));
			}

			// фильтры
			const relationFields = listRelations.map(({ relation }) => RELATIONS[relation]?.foreignKey);
			const fields = ['id', ...this.properties, ...relationFields];
			const include = listRelations.map(({ relation, property, properties = [] }) => ({
				relation,
				scope: { fields: property || properties.filter(Boolean) },
			}));
			this.store = new ListStore({ path: props.path, filters: { fields, include }, model: this.model });
		}
	}

	componentDidMount() {
		if (!this.error) this.store.setPerPage();
	}

	get exportUrl() {
		const esc = encodeURIComponent;
		const path = '/api/modelRecordsToExcel';
		const { limit, skip, _totalCount, ...restQuery } = this.store.query;
		const cols = [];
		const modelName = this.modelName.replace(/^View/, '');
		this.properties.forEach((property) => {
			cols.push({ property, label: getLabelName(property, modelName) });
		});
		this.relations.forEach(({ relation, property }) => {
			cols.push({ property, label: getLabelName(relation, modelName), relation });
		});

		const params = { modelName: this.modelName, filters: {}, cols };

		// уборка пустых квери параметров
		if (restQuery instanceof Object) {
			for (const key in restQuery) {
				const isFilledKey = restQuery[key]?.length || Object.keys(restQuery[key] || {}).length;
				if (isFilledKey) {
					params.filters[key] = restQuery[key];
				}
			}
		}

		const query = '?params=' + esc(JSON.stringify(params));
		return path + query;
	}

	render() {
		if (this.error) return this.error;
		const { onChange, totalCount, page, perPage, search, onSearch, create, tableProps } = this.store;


		console.log(this.relations);
		console.log(tableProps);
		return (
			<BasicList {...tableProps} model={this.model} filter={tableProps.query} disabledButton={!this.access}>
				{this.properties.map((prop) => {
					const label = getLabelName(prop, this.modelName.replace(/^View/, ''));
					const width = prop === 'id' ? 100 : undefined;
					let children;
					if (prop === 'color') children = <ColorValue />;
					else if (this.model.PROPERTIES[prop]?.type === 'Date') {
						children = <DateValue format='dd.MM.yyyy' />;
					}
					return (
						<Column key={prop} label={label} property={prop} width={width}>
							{children}
						</Column>
					);
				})}
				{this.relations.map(({ relation, property, computed, model }) => {
					const label = getLabelName('title' || relation, model);
					return <Column relation={relation} property={property} key={relation + property} computed={computed} label={label} />;
				})}
			</BasicList>
		);
	}
}

