import React from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import List from './List';
import Single from './Single';
import './style.scss';
import store from 'client/store';

@observer
export class Audit extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const path = this.props.match.path + '/';

		if (!(store.model.RoleMapping && store.model.RoleMapping.INFO.WRITE)) return <Redirect to='/' />;

		return (
			<div className='audit-page'>
				<List path={path}>
					<Switch>
						<Route path={`${path}:id`}>
							<Single />
						</Route>
					</Switch>
				</List>
			</div>
		);
	}
}

