import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import store from 'client/store';
import t from 'i18n';
import { BasicList, TruncateText, IconButton, DeleteIconButton } from 'components';
import { Column, Popover } from '@smartplatform/ui';
import { relative, renderFaIcons } from 'client/tools';
import { Text } from '@consta/uikit/Text';

@inject('store')
@observer
export class ImportLogTable extends Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	getInit = (init) => {
		this.init = init;
	};

	ext = (name) => name.match(/\.([^.]+)$/);

	renderStatus = (record) => {
		let text = t('logs.success');
		let view = 'success';
		let paramNumbers = record.formatParamNumbers + ' / ' + record.valuesParamNumbers + ' / ' + record.referenceParamNumbers;

		if (record.warning) {
			text = t(`${record.warning}`);
			view = 'warning';
		}

		if (record.error) {
			text = t(`${record.error}`);
			view = 'alert';
			paramNumbers = '';
			if (this.ext(record.filename)[1] === 'xlsx' && record.error === 'logs.invalidParams') {
				paramNumbers = record.formatParamNumbers + ' / ' + record.referenceParamNumbers;
			}
		}

		return (
			<Text size='s' view={view}>
				<TruncateText>
					{text}
					&nbsp;
					{paramNumbers}
				</TruncateText>
			</Text>
		);
	};

	renderView = (record) => {
		const isAdmin = store.model.ACL && store.model.ACL.INFO.WRITE;

		return (
			<Popover content={t('openFile')} position={!isAdmin ? 'left' : 'top'}>
				<IconButton
					noBorder
					className='btn-table-logbook'
					icon={renderFaIcons.eyeIcon}
					onClick={() => {
						this.openedFile = record;
						this.isPopupOpen = true;
					}}
				>
					{}
				</IconButton>
			</Popover>
		);
	};

	renderDownload = (record) => {
		return (
			<Popover content={t('downloadFile')}>
				<IconButton
					noBorder
					icon={renderFaIcons.downloadFile}
					className='btn-table-logbook'
					onClick={(e) => {
						e.stopPropagation();
						let link = record.downloadFile('filename');
						const downloadLink = document.createElement('a');
						downloadLink.href = link;
						downloadLink.rel = 'noopener noreferrer';
						downloadLink.target = '_blank';
						downloadLink.click();
						downloadLink.remove();
					}}
				/>
			</Popover>
		);
	};

	removeAllRecordsWithDate = async (record) => {
		const filters = {
			where: {
				and: [
					{
						flightTime: {
							gte: new Date(record.flightDateFromFile),
						},
					},

					{
						flightTime: {
							lte: new Date(record.flightDateFromFile),
						},
					},
				],
			},
		};
		const filtersByLogId = { where: { logId: record.id } };

		const [engineInputs, aircraftInputs] = await Promise.all([store.model.EngineInput.find(filters), store.model.AircraftInput.find(filters)]);
		const [engineInputsByLogId, aircraftInputsByLogId] = await Promise.all([
			store.model.EngineInput.find(filtersByLogId),
			store.model.AircraftInput.find(filtersByLogId),
		]);

		await Promise.all([...aircraftInputs, ...engineInputs, ...engineInputsByLogId, ...aircraftInputsByLogId].map((record) => record.delete()));
	};

	renderRemoveButton = (record) => {
		return (
			<DeleteIconButton
				noBorder
				className='btn-table-logbook'
				popPosition={'left'}
				onConfirm={async () => {
					await this.removeAllRecordsWithDate(record);
					await record.delete();
					await this.init();
				}}
			/>
		);
	};

	renderCreatedAt = (record) => {
		return relative(record.createdAt);
	};

	renderActions = (record) => {
		const isAdmin = store.model.ACL && store.model.ACL.INFO.WRITE;

		return (
			<div className='wrapper-controls'>
				{this.renderView(record)}
				{isAdmin && this.renderDownload(record)}
				{isAdmin && this.renderRemoveButton(record)}
			</div>
		);
	};

	render() {
		const { filters } = this.store;
		const { onRowClick } = this.props;

		return (
			<BasicList
				renderCustomToolbar={() => {}}
				model={store.model.ImportLog}
				onRowClick={onRowClick}
				title={t('logbook.title')}
				filter={filters}
				getInit={this.getInit}
				rowHeight={32}
			>
				<Column property='filename' width={270} label={t('importLog.filename')} />
				<Column computed={(record) => record.fileSize.replace('Б', '').trim() + ' Б'} width={100} label={t('importLog.fileSize')} />
				<Column computed={this.renderCreatedAt} width={200} label={t('uploadDate')} />
				<Column computed={this.renderStatus} label={t('importLog.status')} />
				<Column computed={this.renderActions} width={100} />
			</BasicList>
		);
	}
}
