import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { Tab, Tabs } from '@smartplatform/ui';
import { Engine } from './Engine';
import { Loader } from '@consta/uikit/Loader';

const PATH = '/planes/:aircraftId/engines';

@inject('aircraftStore')
@observer
export class Engines extends Component {
	@observable record = null;

	constructor(props) {
		super(props);
		this.store = props.aircraftStore;
	}

	setTabs = () => {
		const numberEngines = this.store.aircraft.family?.numberEngines;

		if (!numberEngines) {
			return 'Количество двигателей не указано';
		}

		return Array.from({ length: numberEngines }, (_, i) => {
			const props = {
				path: i === 0 ? `${PATH}` : `${PATH}/${i + 1}`,
				title: `Двигатель ${i + 1}`,
				exact: i === 0,
				render: (routeProps) => <Engine {...routeProps} enginePosition={i + 1} aircraft={this.store.aircraft} />,
			};

			return <Tab key={i} {...props} />;
		});
	};

	render() {
		if (this.store.isLoading) return <Loader />;

		return <Tabs params={{ aircraftId: this.store.id }}>{this.setTabs()}</Tabs>;
	}
}

