const _COLUMNS = [];

//*------------------------------------N2

const N2_COLUMNS = [
	{ property: 'ZPCN25_1', label: 'ZPCN25', isSelected: true },
	{ property: 'ZPCN25_2', label: 'ZPCN25', isSelected: true },
];

N2_COLUMNS.forEach((item) => {
	item.min = 59;
	item.max = 105;
});

//*------------------------------------F/F

export const FF_COLUMNS = [
	{ property: 'C_FFD_1', label: 'C_FFD', isSelected: true },
	{ property: 'C_FFD_2', label: 'C_FFD', isSelected: true },
];

FF_COLUMNS.forEach((item) => {
	item.min = 118;
	item.max = 130;
	item.phaseName = 'CRUISE';
});

//*------------------------------------EGT

export const EGT_COLUMNS = [
	{ property: 'ZT49_1', label: 'ZT49', isSelected: true },
	{ property: 'ZT49_2', label: 'ZT49', isSelected: true },
];

export const ALFA = 0.2;

EGT_COLUMNS.forEach((item) => {
	item.min = 20;
	item.max = 80;
});

//*------------------------------------VIB

const SHARED_VIB_COLUMNS = [
	{ property: 'ZVB1F_1', label: 'ZVB1F', isSelected: true },
	{ property: 'ZVB1F_2', label: 'ZVB1F', isSelected: true },
];

const TAKEOFF_VIB_COLUMNS = [
	{ property: 'ZVB1R_1', label: 'ZVB1R', isSelected: true },
	{ property: 'ZVB1R_2', label: 'ZVB1R', isSelected: true },
	{ property: 'ZVB2F_1', label: 'ZVB2F', isSelected: true },
	{ property: 'ZVB2F_2', label: 'ZVB2F', isSelected: true },
	{ property: 'ZVB2R_1', label: 'ZVB2R', isSelected: true },
	{ property: 'ZVB2R_2', label: 'ZVB2R', isSelected: true },
];

TAKEOFF_VIB_COLUMNS.forEach((item) => {
	item.phaseName = 'TAKEOFF';
});

const CRUISE_VIB_COLUMNS = [];

CRUISE_VIB_COLUMNS.forEach((item) => {
	item.phaseName = 'CRUISE';
});

export const VIB_COLUMNS = [...SHARED_VIB_COLUMNS, ...CRUISE_VIB_COLUMNS, ...TAKEOFF_VIB_COLUMNS].map((item) => {
	item.min = 0;
	item.max = 5;
	item.isAllowedChangeBoundaries = true;
	return item;
});

export const COLUMNS = _COLUMNS.concat(SHARED_VIB_COLUMNS, TAKEOFF_VIB_COLUMNS, CRUISE_VIB_COLUMNS, FF_COLUMNS, N2_COLUMNS);

export const COLUMN_WIDTH = 150;
