import React, { Component } from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import t from 'i18n';
import { BasicList } from 'components';
import { Column } from '@smartplatform/ui';
import { camelCase } from 'lodash';

@observer
export class List extends Component {
    constructor(props) {
        super(props);
        this.modelNameCamelCase = camelCase(props.modelName);
        this.model = store.model[props.modelName];
		this.access = store.model.ACL && store.model.ACL.INFO.WRITE;
    }

    render() {
        return (
            <BasicList model={this.model} disabledButton={!this.access}>
                <Column property='name' label={t('aircraftOperator.name')} />
                <Column property='title' label={t('aircraftOperator.siteTitle')} />
                <Column property='active' label={t('aircraftOperator.active')} />
            </BasicList>
        );
    }
}

