import React, { Component } from 'react';
import { Provider, observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { Text } from '@consta/uikit/Text';
import { FileField } from '@consta/uikit/FileField';
import { Button } from '@consta/uikit/Button';
import { Loader } from '@consta/uikit/Loader';
import { Popup } from '@smartplatform/ui';
import { ImportLogTable } from './ImportLogTable';
import { logStore } from './store';
import { LogbookToolbar } from './LogbookToolbar';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import './logbook.scss';

//todo promisePool
@observer
export class Logbook extends Component {
	@observable isPopupOpen = false;
	@observable isLoading = false;
	@observable errorFiles = [];
	@observable successFiles = [];

	formatParameters = [];
	valuesParameters = [];
	openedFile = null;
	controlParams = {};
	flightPhase = null;
	aircraft = null;
	flightDate = new Date();
	importedLogs = [];
	importedLogsValues = [];

	constructor(props) {
		super(props);
		this.store = new logStore();
		this.formatString = [];
		this.valuesString = [];
	}

	onImport = async (e) => {
		this.isLoading = true;

		const files = e.target.files;

		for (const file of files) {
			const isUploadingFiles = this.ext(file.name)[1] === 'xlsx';

			const importLog = new store.model.ImportLog({
				filename: file.name,
				fileSize: file.size,
				fileContent: isUploadingFiles ? 'В разработке... ' : await file.text(),
			});

			if (isUploadingFiles) {
				await (new Promise((resolve) => {
					const reader = new FileReader();
					reader.readAsBinaryString(file);
					reader.onload = async (e) => {
						const buffer = e.target.result;
						await importLog.save();
						await importLog.importFile(buffer);
						await importLog.processImport2();
						resolve();
					}
				}));
			} else {
				await importLog.save();
				await importLog.processImport2();
			}
		}
		await store.model.ImportLog.processNotify();

		this.isLoading = false;
	};

	onRowClick = (record) => {
		this.openedFile = record;
		this.isPopupOpen = true;
	};

	ext = (name) => name.match(/\.([^.]+)$/);

	render() {
		if (this.isLoading) return <Loader />;

		let popup = null;
		if (this.isPopupOpen) {
			popup = (
				<Popup
					onClose={() => {
						this.openedFile = null;
						this.isPopupOpen = false;
					}}
					header={t('importLog.fileContent')}
				>
					<Theme preset={presetGpnDefault}>
						{this.openedFile.fileContent ? (
							<>
								<pre dangerouslySetInnerHTML={{ __html: this.openedFile.fileContent }} />
								{this.openedFile.error && (
									<Text size='s' view='alert'>
										{t(`${this.openedFile.error}`)}
									</Text>
								)}
							</>
						) : (
							t('importLog.noFileContent')
						)}
					</Theme>
				</Popup>
			);
		}

		return (
			<Provider store={this.store}>
				<div className='logbook'>
					{popup}
					<LogbookToolbar />
					<FileField onChange={this.onImport} multiple accept={['.dat', '.sge', '.xlsx']}>
						{(props) => <Button {...props} label='Загрузить' />}
					</FileField>
					{(this.errorFiles.length > 0 || this.successFiles.length > 0) && (
						<div>
							{this.errorFiles.length > 0 && (
								<div>
									<Text view='alert' size='s'>
										Ошибка
									</Text>
									{this.errorFiles.map((error, i) => (
										<Text view='alert' size='s' key={i}>
											{error}
										</Text>
									))}
								</div>
							)}
							{this.successFiles.length > 0 && (
								<div>
									<Text view='success' size='s'>
										{t('logs.success')}
									</Text>
									{this.successFiles.map((success, i) => (
										<Text view='success' size='s' key={i}>
											{success}
										</Text>
									))}
								</div>
							)}
						</div>
					)}
					<ImportLogTable onRowClick={this.onRowClick} />
				</div>
			</Provider>
		);
	}
}

