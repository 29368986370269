import React from 'react';
import { Popover } from '@smartplatform/ui';
import { Scale } from './Scale';
import { isNumber } from 'lodash';
import { format } from 'date-fns';

const _COLUMNS = [
	{
		property: 'flightTime',
		width: 130,
		label: 'flightTime',
		isSelected: true,
		className: '',
		computed(item, record) {
			return format(new Date(record[item.property]), 'dd.MM.yyyy HH:MM');
		},
	},
];

//*------------------------------------N2

const computedN1Values = (item, record) => {
	const { property, max, min } = item;
	let content = record[property];
	if (isNumber(record[property])) {
		const value = content;
		content = (
			<Popover content={value}>
				<Scale value={value} max={max} min={min} item={item} />
			</Popover>
		);
	}

	return content;
};

const N2_COLUMNS = [
	{ property: 'n2_1', label: 'ZPCN25', isSelected: true },
	{ property: 'n2_2', label: 'ZPCN25', isSelected: true },
];

N2_COLUMNS.forEach((item) => {
	item.min = 59;
	item.max = 105;
	item.computed = computedN1Values;
});

//*------------------------------------F/F

const computedFfValues = (item, record) => {
	const { property, max, min } = item;

	let content = record[property];
	if (isNumber(record[property])) {
		let value = content;
		content = (
			<Popover content={value}>
				<Scale value={value} max={max} min={min} item={item} />
			</Popover>
		);
	}

	return content;
};

export const FF_COLUMNS = [
	{ property: 'C_FFD_1', label: 'C_FFD', isSelected: true },
	{ property: 'C_FFD_2', label: 'C_FFD', isSelected: true },
];

FF_COLUMNS.forEach((item) => {
	item.min = 118;
	item.max = 130;
	item.computed = computedFfValues;
	item.phaseName = 'CRUISE';
});

//*------------------------------------EGT

const computedEgtValues = (item, record) => {
	const { property, max, min } = item;

	let content = record[property];
	if (isNumber(record[property])) {
		let value = ((item.maxLimit - record[property]) / 3.5).toFixed(3);
		// console.log('>>>>>> 2222222');
		// console.log('item >> ', item);
		content = (
			<Popover content={value}>
				<Scale value={value} max={max} min={min} item={item} />
			</Popover>
		);
	}

	return content;
};

export const EGT_COLUMNS = [
	{ property: 'egt_1', label: 'ZT49', isSelected: true },
	{ property: 'egt_2', label: 'ZT49', isSelected: true },
];

EGT_COLUMNS.forEach((item) => {
	item.min = 20;
	item.max = 80;
	item.computed = computedEgtValues;
});

//*------------------------------------VIB

const computedVibValues = (item, record) => {
	const { property, max, min } = item;
	let content = record[property];
	if (isNumber(record[property])) {

		let value = record[property].toFixed(3);
		content = (
			<Popover content={value}>
				<Scale value={value} max={max} min={min} item={item} />
			</Popover>
		);
	}

	return content;
};

const SHARED_VIB_COLUMNS = [
	{ property: 'ZVB1F_1', label: 'ZVB1F', isSelected: true },
	{ property: 'ZVB1F_2', label: 'ZVB1F', isSelected: true },
];

const TAKEOFF_VIB_COLUMNS = [
	{ property: 'ZVB1R_1', label: 'ZVB1R', isSelected: true },
	{ property: 'ZVB1R_2', label: 'ZVB1R', isSelected: true },
	{ property: 'ZVB2F_1', label: 'ZVB2F', isSelected: true },
	{ property: 'ZVB2F_2', label: 'ZVB2F', isSelected: true },
	{ property: 'ZVB2R_1', label: 'ZVB2R', isSelected: true },
	{ property: 'ZVB2R_2', label: 'ZVB2R', isSelected: true },
];

TAKEOFF_VIB_COLUMNS.forEach((item) => {
	item.phaseName = 'TAKEOFF';
});

const CRUISE_VIB_COLUMNS = [];

CRUISE_VIB_COLUMNS.forEach((item) => {
	item.phaseName = 'CRUISE';
});

export const VIB_COLUMNS = [...SHARED_VIB_COLUMNS, ...CRUISE_VIB_COLUMNS, ...TAKEOFF_VIB_COLUMNS].map((item) => {
	item.min = 0;
	item.max = 5;
	item.computed = computedVibValues;
	item.isAllowedChangeBoundaries = true;
	return item;
});

export const COLUMNS = _COLUMNS.concat(SHARED_VIB_COLUMNS, TAKEOFF_VIB_COLUMNS, CRUISE_VIB_COLUMNS, FF_COLUMNS, N2_COLUMNS);

export const COLUMN_WIDTH = 150;