import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';

@observer
export class Journal extends Component {
	render() {
		return (
			<div>
				<div>Journal</div>
			</div>
		);
	}
}
