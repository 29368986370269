import { set } from 'lodash';

export const parseConfig = (configRecords) => {
	const parsedConfig = {};
	for (let config of configRecords) {
		const value = !!config.imageId ? config.image.downloadFile('filename') : config.hiddenValue || config.value;
		set(parsedConfig, config.code, value);
	}
	return parsedConfig;
};
