import React from 'react';
import t from 'i18n';
import { Settings } from 'components/settings/Settings';
import store from 'client/store';

export class OperatorSetting extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const propsFields = {
			siteName: {
				type: 'text',
				props: {
					title: t('operatorSettings.siteName'),
					defaultValue: '',
				},
			},
			logo: {
				type: 'attachment',
				props: {
					title: t('operatorSettings.logo'),
					accept: 'image/*',
					defaultValue: null,
				},
			},
			favicon: {
				type: 'attachment',
				props: {
					title: t('operatorSettings.favicon'),
					accept: 'image/*',
					defaultValue: null,
				},
			},
		};

		return <Settings title={t('operatorSettings.title')} propsFields={propsFields} serverConfig={store.serverConfig?.operator} settingsCode='operator' />;
	}
}
