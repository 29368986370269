export const DEFAULT_PRIORITY = 1000;
export const KANBAN_INITIAL_LIMIT = 20;
export const TABLE_ROW_HEIGHT = 48;
export const REQUIRED_FLIGHT_PHASE_NAME = ['TAKEOFF', 'CRUISE'];
export const KG_TO_LBS = 2.2046;

import t from 'i18n';

export const NOTIFY_SCHEDULE_INCLUDE = {
	include: [{ relation: 'notifyLogs', scope: { include: ['status', 'type'] } }, 'roles', 'recipients'],
};

export const PERIODS = [
	{ label: t('period.everyday'), value: 'DAY' },
	{ label: t('period.everyweek'), value: 'WEEK' },
	{ label: t('period.everymonth'), value: 'MONTH' },
];

export const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((item, index) => ({
	label: t('day.' + item),
	value: String(index + 1),
}));

export const DAYS = Array(31)
	.fill(0)
	.map((item, index) => ({ label: index + 1, value: String(index + 1) }));

export const HOURS = Array(24)
	.fill(0)
	.map((item, index) => ({ label: index, value: String(index) }));

