import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import aircraftSrc from 'client/img/pdfReport/airplane.png';
import engineSrc from 'client/img/pdfReport/Aircraft-turbine-icon-Rotating-engine-b-Graphics-58089330-1.png';
import { renderFaIcons } from 'client/tools';
import { endOfDay, format, startOfDay } from 'date-fns';
import store from 'client/store';
import t from 'i18n';

import './pdfContent.scss';

@inject('graphicReportStore')
@observer
export class PDFContent extends Component {
	constructor(props) {
		super(props);
		this.store = props.graphicReportStore;
	}

	updateTranslations = () => {};

	render() {
		const { pdfReportRef, aircraft, flightPhase, startDate, endDate } = this.store;
		const translateNode = 'aircraft.graphicReport.pdf';
		return (
			<div id='pdf-export'>
				<div className='pdf-content' ref={pdfReportRef}>
					<div className='block'>
						<img src={aircraftSrc} alt='' />
						<div>
							<div> {t(`${translateNode}.aircraft`, { lng: 'en' })}</div>
							<div>{t(`${translateNode}.aircraft`, { lng: 'ru' })}</div>
						</div>
						<div>{aircraft.serialNumber}</div>
						<div>{aircraft.type?.name}</div>
					</div>
					<div className='block'>
						{renderFaIcons.clock}
						{!startDate && !endDate ? (
							<div>
								<div>{t(`${translateNode}.allTime`, { lng: 'en' })}</div>
								<div>{t(`${translateNode}.allTime`, { lng: 'ru' })}</div>
							</div>
						) : startDate && !endDate ? (
							<div>
								<div>{`${t(`${translateNode}.from`)} ${format(startOfDay(startDate), 'dd-MM-yyyy')}`}</div>
								<div>dd-mm-yyyy</div>
							</div>
						) : !startDate && endDate ? (
							<div>
								<div>{`${t(`${translateNode}.to`)} ${format(endOfDay(endDate), 'dd-MM-yyyy')}`}</div>
								<div>dd-mm-yyyy</div>
							</div>
						) : (
							<div>
								<div>{`${format(startOfDay(startDate), 'dd-MM-yyyy')} - ${format(endOfDay(endDate), 'dd-MM-yyyy')}`}</div>
								<div>dd-mm-yyyy - dd-mm-yyyy</div>
							</div>
						)}
					</div>
					<div className='block'>
						<div>
							<div> {t(`${translateNode}.operator`, { lng: 'en' })}</div>
							<div>{t(`${translateNode}.operator`, { lng: 'ru' })}</div>
						</div>
						<div>{aircraft.operator?.name}</div>
					</div>
					<div className='block'>
						<img src={engineSrc} alt='' />
						<div>
							<div> {t(`${translateNode}.engine`, { lng: 'en' })}</div>
							<div>{t(`${translateNode}.engine`, { lng: 'ru' })}</div>
						</div>
						<div>
							{aircraft.engines()?.map((engine, i) => {
								return (
									<div key={i}>
										{engine.serialNumber}({i + 1})
									</div>
								);
							})}
						</div>
					</div>
					<div className='block'>
						<div>
							<div> {t(`${translateNode}.phase`, { lng: 'en' })}</div>
							<div>{t(`${translateNode}.phase`, { lng: 'ru' })}</div>
						</div>
						<div>{flightPhase.phaseName}</div>
					</div>
					<div className='block'>
						<div>
							{store.model.user.firstName} &nbsp;
							{store.model.user.lastName}
						</div>
					</div>
					{this.store.isSmoothed && (
						<div className='block'>
							<div>
								<div> {t(`${translateNode}.smoothing`, { lng: 'ru' })}</div>
								<div> {t(`${translateNode}.smoothing`, { lng: 'en' })}</div>
							</div>
							<div>
								<div>{t(`${translateNode}.enable`, { lng: 'ru' })}</div>
								<div>{t(`${translateNode}.enable`, { lng: 'en' })}</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}
