import React, { Component, createRef } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import autoAnimate from '@formkit/auto-animate';
import classNames from 'classnames';

@observer
export class AutoAnimate extends Component {
	ref;

	constructor(props) {
		super(props);
		this.ref = createRef();
	}

	componentDidMount() {
		this.ref.current && autoAnimate(this.ref.current, { ...this.props.options });
	}

	render() {
		return (
			<div ref={this.ref} className={classNames('auto-animate-wrapper', this.props.className)}>
				{this.props.children}
			</div>
		);
	}
}

