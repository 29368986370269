import store from 'client/store';
import t from 'i18n';

export const checkModelAccess = (model) => {
	if (!store.model[model]?.INFO.READ) {
		return t('modelAccessDenied') + ` ${model}`;
	}
	return null;
};

