import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ModelEdit, Attachments } from 'components';
import { Checkbox, Field, Popconfirm } from '@smartplatform/ui';

import store from 'client/store';
import t from 'i18n';

@observer
export class Edit extends Component {
	@observable record = null;
	@observable name = '';
	@observable error = null;
	@observable active = null;

	constructor(props) {
		super(props);
		this.model = store.model[props.modelName];
		this.id = parseInt(props.match.params.id);
		this.path = props.path;
		this.access = store.model.ACL && store.model.ACL.INFO.WRITE;
		this.init();
	}

	init = async () => {
		const record = await store.model[this.props.modelName].find({
			where: { id: this.id },
		});
		this.active = record[0].active;
	};

	getRecord = (record) => {
		this.record = record;
	};

	beforeDelete = () => this.props.getValidatedRemove?.(this.record);

	beforeSave = async (record) => {
		this.error = null;
		if (record.active && !this.active) {
			const record = await store.model[this.props.modelName].find({
				where: { active: true },
			});
			if (record[0]) {
				record[0].active = false;
				record[0].save();
			}
		}
	};

	onSave = () => store.route.push({ path: this.path });

	onCancel = () => store.route.push({ path: this.path });

	onChangeOperator = () => {
		this.record.active = true;
	};

	render() {
		return (
			<ModelEdit
				model={this.model}
				id={this.id}
				getRecord={this.getRecord}
				className='universal-edit'
				path={this.path}
				beforeSave={this.beforeSave}
				beforeDelete={this.beforeDelete}
				onSave={this.onSave}
				onCancel={this.onCancel}
				disableSave={!this.access}
				disableDelete={!this.access}
			>
				<Field property='name' label={t('aircraftOperator.title')} disabled={!this.access} isRequired />
			</ModelEdit>
		);
	}
}
