import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { Form, ModelEdit } from 'components';
import { Field, NumberInput, Row, Input, RecordSelect, Select } from '@smartplatform/ui';
import { Text } from '@consta/uikit/Text';
import { camelCase } from 'lodash';
import { toast } from 'react-toastify';

import './mainInfo.scss';
import { RegExpInput } from 'components/inputs';

const ITEMS_WEIGHT_UNITS = [
	{
		label: 'KG',
		value: 'KG',
	},
	{
		label: 'LBS',
		value: 'LBS',
	},
];

@inject('aircraftStore')
@observer
export class MainInfo extends Component {
	@observable error = null;
	@observable manRecord = null;

	constructor(props) {
		super(props);
		this.store = props.aircraftStore;
		this.id = this.store.id;
		this.access = store.model.ACL && store.model.ACL.INFO.WRITE;
	}

	onError = (error) => {
		let errorMessage = error.message.replace(/".*"/gis, '');
		if (error.code == 23505) {
			this.error = t('mainInfoErrorMessage');
		} else {
			this.error = t(`aircraft.${camelCase(errorMessage)}`);
		}
	};

	onManufacturerChange = (value) => {
		if (value) {
			this.store.aircraft.family = null;
			this.store.aircraft.type = null;
		}
	};

	onFamilyChange = (value) => {
		if (value) {
			this.store.aircraft.type = null;
			this.store.aircraft.AircraftFamily = value;
			this.store.aircraft.manufacturer = value.manufacturer;
		}
	};

	onTypeChange = (value) => {
		this.store.aircraft.AircraftType = value;
		this.store.aircraft.family = value.family;
		this.store.aircraft.manufacturer = value.family.manufacturer;
	};

	onWeightUnitChange = (value) => {
		this.store.aircraft.weightUnits = value;
	};

	beforeDelete = () => this.props.getValidatedRemove?.(this.record);

	render() {
		if (!this.store.aircraft) return null;

		return (
			<div className='main-content'>
				<Form
					record={this.store.aircraft}
					onError={this.onError}
					beforeSave={() => {
						this.error = null;
					}}
					stay
					onDelete={() => {
						store.route.push({ path: '/planes' });
					}}
					onCancel={() => {
						store.route.push({ path: '/planes' });
					}}
					onSave={() => {
						store.route.push({ path: '/planes' });
					}}
					disableSave={
						this.store.aircraft.manufacturer == null ||
						this.store.aircraft.family == null ||
						this.store.aircraft.type == null ||
						this.store.aircraft.weightUnits == null ||
						!this.access
					}
					disableDelete={!this.access}
				>
					<Row>
						<Field property='serialNumber' label={t('ID')} disabled={!this.access}>
							<RegExpInput regExp={/[^a-z0-9-_]/gi} style={this.error ? { border: '1px solid red' } : null} />
							{this.error && (
								<Text view='alert' size='s'>
									{this.error}
								</Text>
							)}
						</Field>
						<Field relation='manufacturer' property='name' label={t('aircraftManufacturer.title')} disabled={!this.access} isRequired>
							<RecordSelect model={store.model.AircraftManufacturer} value={this.record?.manufacturer} onChange={this.onManufacturerChange} isRequired />
						</Field>
					</Row>
					<Row>
						<Field relation='holder' property='name' label={t('aircraftHolder.title')} disabled={!this.access} isRequired />
						<Field relation='family' property='name' label={t('aircraftFamily.title')} disabled={!this.access} isRequired>
							<RecordSelect
								model={store.model.AircraftFamily}
								value={this.record?.family}
								filter={{
									include: ['manufacturer'],
									where: { manufacturerId: this.store?.aircraft.manufacturerId },
								}}
								onChange={this.onFamilyChange}
								disabled={!this.access}
								isRequired
							/>
						</Field>
					</Row>
					<Row>
						<Field relation='operator' property='name' label={t('aircraftOperator.title')} disabled={!this.access} isRequired />
						<Field relation='type' property='name' label={t('aircraftType.title')} disabled={!this.access} isRequired>
							<RecordSelect
								model={store.model.AircraftType}
								value={this.record?.type}
								filter={{
									include: ['family'],
									where: { familyId: this.store?.aircraft.familyId },
								}}
								onChange={this.onTypeChange}
								disabled={!this.access}
								isRequired
							/>
						</Field>
					</Row>
					<Row>
						<Field relation='family' property='numberEngines' label={t('aircraftFamily.numberEngines')} disabled />
						<Field property='weightUnits' label={t('aircraft.weightUnits')} disabled={!this.access} isRequired>
							<Select items={ITEMS_WEIGHT_UNITS} value={this.record?.weightUnits} onChange={this.onWeightUnitChange} />
						</Field>
					</Row>
				</Form>
			</div>
		);
	}
}
