import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@smartplatform/ui';

export default class RefreshButton extends React.Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired,
		disabled: PropTypes.bool,
	};

	static defaultProps = {
		disabled: false,
	};

	render() {
		return (
			<Button variant='primary' size='sm' onClick={this.props.onClick} disabled={this.props.disabled}>
				<FontAwesomeIcon icon={faSyncAlt} />
			</Button>
		);
	}
}

