import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { ButtonGroup, Column, Pager, Popover, RecordSelect, Table } from '@smartplatform/ui';
import { Scale } from './Scale';
import { BUTTONS } from '../graphicReport/constants';
import { Button } from 'components';
import { trendReportStore } from './store';
import { Loader } from '@consta/uikit/Loader';
import { isNumber } from 'lodash';
import { COLUMN_WIDTH } from './constants';
import { DatePicker } from '@consta/uikit/DatePicker';
import { ReportToolbar } from './toolbar';
import classNames from 'classnames';
import SearchIcon from 'client/img/icons/search_black.svg';
import { Switch } from '@consta/uikit/Switch';

import './trendReport.scss';

@inject('aircraftStore')
@observer
export class TrendReport extends Component {
	constructor(props) {
		super(props);
		this.aircraftId = props.aircraftStore.id;
		this.store = new trendReportStore(this.aircraftId, props.aircraftStore.aircraft);
		this.records = this.store.records;
	}

	renderVib = (property) => (record) => {
		let content = record[property];
		if (isNumber(record[property])) {
			let value = record[property].toFixed(3);
			content = (
				<Popover content={value}>
					<Scale value={value} />
				</Popover>
			);
		} else {
			console.log(property, 'is not number');
		}
		return content;
	};

	handleViewChange = (property) => {
		this.store.onViewChange(property);
	};

	render() {
		const { isLoading } = this.props.aircraftStore;

		const {
			onPhaseChange,
			flightPhase,
			aircraft,
			onAircraftChange,
			records,
			selectedColumns,
			mergedColumns,
			isLoading: isTrendReportLoading,
			onChange,
			perPage,
			page,
			startDate,
			endDate,
			onDateChange,
			buttonIsPressed,
		} = this.store;

		if (isLoading || isTrendReportLoading) return <Loader />;

		const columns = selectedColumns
			.filter((item) => !item.phaseName || item.phaseName === flightPhase.phaseName)
			.filter((item) => item.isSelected)
			.flatMap((item, i, array) => {
				const itemClass = classNames({
					engine1: item.property[item.property.length - 1] === '1',
					engine2: item.property[item.property.length - 1] === '2',
				});
				/*const buttonPressed = classNames({
					'column-title-button-zoom-pressed': this.store.getButtonState(item.property),
					'column-title-button-zoom': !this.store.getButtonState(item.property),
				})*/
				const regex = /VB/;
				const items = [];
				items.push(
					<Column
						key={item.property}
						width={COLUMN_WIDTH}
						{...item}
						computed={(record) => {
							let content = record[item.property];
							if (item.computed) {
								content = item?.computed(item, record);
							}
							return content;
						}}
						className='border-column'
						label={
							<div className='column-title'>
								<div className='column-title-parameter-name'>
									<span className={itemClass}>{t(`parameter.${item.label}`)}</span>
								</div>
								{item.property !== 'flightTime' && regex.test(item.property) ? (
									<div className='column-title-buttons'>
										<span className='zoom-100'>100%</span>
										<Switch
											size='l'
											checked={this.store.getButtonState(item.property)}
											onChange={() => this.handleViewChange(item.property)}
											disabled={item.max > 5}
										/>
										<SearchIcon className='zoom-icon' />
									</div>
								) : null}
								<div className='column-title-parameter-diapason'>
									<div>
										<BoundariesChangeWrapper
											item={item}
											property={'min'}
											subDisabled={item.min}
											sumDisabled={item.max - 1 <= item.min}
											isAllowedChangeBoundaries={item?.isAllowedChangeBoundaries}
										>
											<span>{item.min}</span>
										</BoundariesChangeWrapper>
									</div>
									<div>
										<BoundariesChangeWrapper
											item={item}
											property={'max'}
											subDisabled={item.min + 1 >= item.max}
											sumDisabled={item.max}
											isAllowedChangeBoundaries={item?.isAllowedChangeBoundaries}
										>
											<span>{item.max}</span>
										</BoundariesChangeWrapper>
									</div>
								</div>
							</div>
						}
					/>
				);

				if (i !== array.length - 1) {
					items.push(<Column className='empty-column' style={{ backgroundColor: 'white' }} computed={() => ' '} />);
				}
				return items;
			});

		return (
			<Provider trendReportStore={this.store}>
				<div>
					<RecordSelect
						model={store.model.Aircraft}
						filter={{
							include: ['engines'],
						}}
						property='serialNumber'
						value={aircraft}
						onChange={onAircraftChange}
						isRequired
						style={{ marginRight: '10px' }}
						disabled={true}
					/>
					<ButtonGroup className='phaseButtons'>
						{BUTTONS.map((phaseValue) => (
							<Button
								key={phaseValue.phaseName}
								onClick={() => onPhaseChange(phaseValue)}
								variant={flightPhase.phaseName === phaseValue.phaseName ? 'primary' : 'default'}
							>
								{phaseValue.phaseName}
							</Button>
						))}
					</ButtonGroup>
					<div className='dates'>
						{[
							{ value: startDate, onChangeProp: 'startDate', maxDate: endDate },
							{ value: endDate, onChangeProp: 'endDate', minDate: startDate },
						].map(({ value, onChangeProp, maxDate, minDate, ...rest }, i) => (
							<DatePicker key={i} size='m' type='date' value={value} onChange={onDateChange(onChangeProp)} {...rest} withClearButton />
						))}
					</div>
					<ReportToolbar />
					<div className='trend-report-wrapper'>
						<Table className='trend-report-table' rows={records}>
							{columns}
						</Table>
						<Pager current={page} totalCount={records.totalCount || 0} onChange={onChange('page')} itemsPerPage={perPage} noCount />
					</div>
				</div>
			</Provider>
		);
	}
}

const BoundariesChangeWrapper = ({ children, item, isAllowedChangeBoundaries, property, subDisabled, sumDisabled, ...rest }) => {
	return (
		<>
			{isAllowedChangeBoundaries ? (
				<>
					{!subDisabled && (
						<span
							onClick={() => {
								item[property] -= 1;
							}}
						></span>
					)}

					{children}
					{!sumDisabled && (
						<span
							onClick={() => {
								item[property] += 1;
							}}
						></span>
					)}
				</>
			) : (
				<>{children}</>
			)}
		</>
	);
};
