import React from 'react';
import './settings.scss';
import t from 'i18n';
import { SettingsStore } from 'components/settings/settingStore';
import store from 'client/store';
import { Switch } from '@consta/uikit/Switch';
import { BlockedFieldsMessage, LabelField } from 'components';
import { Button, Input, NumberInput, SlideInput } from '@smartplatform/ui';
import { Loader } from '@consta/uikit/Loader';
import { observer } from 'mobx-react';
import { DragNDropField } from '@consta/uikit/DragNDropField';
import ImageUploader from 'components/settings/image-uploader/ImageUploader';

@observer
export class Settings extends React.Component {
	constructor(props) {
		super(props);
		this.store = new SettingsStore(props);
		store.ui.title = props.title || t('_settings.title');
	}

	renderFields = () => {
		const { settingProperties, onChange, propsFields, disabledFields, onImageChange } = this.store;
		let fields = [];
		for (const field in settingProperties) {
			const type = propsFields?.[field]?.type;
			const title = propsFields?.[field]?.props?.title || '';
			if (type === 'boolean') {
				fields.push(
					<div className='item-setting-switch' key={field}>
						<span>{title}</span>
						<Switch
							checked={settingProperties[field]}
							onChange={(e) => onChange(field)(e.checked)}
							disabled={disabledFields.has(field)}
							size='l'
							{...propsFields?.[field].props}
						/>
					</div>
				);
			} else if (type === 'number') {
				fields.push(
					<LabelField key={field} id={field} label={title}>
						<NumberInput value={settingProperties[field]} onChange={onChange(field)} {...propsFields?.[field].props} disabled={disabledFields.has(field)} />
					</LabelField>
				);
			} else if (type === 'range') {
				fields.push(
					<LabelField key={field} id={field} label={title}>
						<div className='wrapper-slide-input'>
							<NumberInput value={settingProperties[field]} onChange={onChange(field)} {...propsFields?.[field].props} disabled={disabledFields.has(field)} />
							<SlideInput min={3} max={64} step={1} value={settingProperties[field]} onChange={onChange(field)} disabled={disabledFields.has(field)} />
						</div>
						{(settingProperties[field] < propsFields?.[field].props.min || settingProperties[field] > propsFields?.[field].props.max) && (
							<p className='invalid-message'>
								{t('authenticationSettings.invalidRange', {
									min: propsFields?.[field].props.min,
									max: propsFields?.[field].props.max,
								})}
							</p>
						)}
					</LabelField>
				);
			} else if (type === 'attachment') {
				fields.push(
					<LabelField key={field} id={field} label={title}>
						<ImageUploader value={settingProperties[field]} onChange={onChange(field)} {...propsFields?.[field].props} />
					</LabelField>
				);
			} else if (type === 'btn-group') {
				fields.push(
					<LabelField key={field} id={field} label={title}>
						<div className='wrapper-btn-group'>
							{propsFields?.[field]?.props.items.map((itemBtn) => {
								return (
									<Button key={itemBtn} onClick={() => onChange(field)(itemBtn)} variant={itemBtn === settingProperties[field] ? 'primary' : 'default'}>
										{itemBtn}
									</Button>
								);
							})}
						</div>
					</LabelField>
				);
			} else {
				fields.push(
					<LabelField key={field} id={field} label={title}>
						<Input value={settingProperties[field]} onChange={onChange(field)} {...propsFields?.[field].props} />
					</LabelField>
				);
			}
		}
		return fields;
	};

	render() {
		const { title } = this.props;
		const { onSave, updatedSettingsKeys, isLoading, disabledFields } = this.store;
		if (isLoading) return <Loader />;
		const contentSettings = this.renderFields();

		return (
			<div className='settings-content'>
				{disabledFields.size > 0 && <BlockedFieldsMessage />}
				<p className='head-group'>{title || t('_settings.title')}</p>
				<div className='wrapper-content-settings'>{contentSettings}</div>
				<Button variant='primary' onClick={onSave} className='mt-2' disabled={updatedSettingsKeys.length === 0}>
					{t('save')}
				</Button>
			</div>
		);
	}
}
