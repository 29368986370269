import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from 'components';
import { Popconfirm } from '@smartplatform/ui';
import classNames from 'classnames';
import t from 'i18n';

export const DeleteIconButton = ({
	confirmMessage = t('delete'),
	onConfirm,
	portalClassName,
	popPosition,
	disabled,
	noBorder,
	onActivate,
	size,
	contentClassName,
	popClassName,
	className,
	...otherButtonProps
}) => (
	<Popconfirm
		content={confirmMessage}
		onConfirm={onConfirm}
		portalClassName={portalClassName}
		position={popPosition}
		disabled={disabled}
		onActivate={onActivate}
		contentClassName={classNames('Theme_color_gpnDefault', contentClassName)}
		className={popClassName}
	>
		<IconButton
			icon={<FontAwesomeIcon icon={faTrashCan} />}
			className={classNames('delete-btn', className)}
			disabled={disabled}
			noBorder={noBorder}
			size={size}
			{...otherButtonProps}
		/>
	</Popconfirm>
);

DeleteIconButton.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	confirmMessage: PropTypes.any,
	portalClassName: PropTypes.string,
};

