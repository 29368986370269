import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { ModelEdit } from 'components';
import { Field, NumberInput, RecordSelect, Input } from '@smartplatform/ui';
import { camelCase } from 'lodash';
import { Text } from '@consta/uikit/Text';

@observer
export class Edit extends Component {
    @observable record = null;
    @observable name = '';
	@observable error = null;

    constructor(props) {
        super(props);
        this.model = store.model[props.modelName];
        this.id = parseInt(props.match.params.id);
		this.path = props.path;
		this.access = store.model.ACL && store.model.ACL.INFO.WRITE;
	}

    getRecord = (record) => {
        this.record = record;
    };

	beforeDelete = () => this.props.getValidatedRemove?.(this.record);

	onError = (error) => {
		const errorMessage = error.message.replace(/".*"/gis, '');
		if (error.code == 23505) {
			this.error = t('errorMessage');
		} else {
			this.error = t(`aircraft.${camelCase(errorMessage)}`);
		};
	};

	render() {
		return (
			<ModelEdit
				model={this.model}
				id={this.id}
				getRecord={this.getRecord}
				className='universal-edit'
				path={this.path}
				onError={this.onError}
				beforeSave={() => {
					this.error = null;
				}}
				onCancel={() => {
					store.route.push({ path: this.path });
				}}
				onSave={() => {
					store.route.push({ path: this.path });
				}}
				beforeDelete={this.beforeDelete}
				disableSave={
					(this.record?.name == null ||
					this.record?.numberBlades == null ||
					this.record?.manufacturer == null || !this.access)
				}
				disableDelete={!this.access}
			>
				<Field
					relation='manufacturer'
					property='name'
					label={t('engineManufacturer.title')}
					disabled={!this.access}
					isRequired
				>
					<RecordSelect model={store.model.EngineManufacturer} value={this.record?.manufacturer} />
				</Field>
				<Field
					property='name'
					label={t('engineFamily.name')}
					isRequired
					disabled={!this.record?.manufacturerId || !this.access}
				>
					<Input style={this.error ? { border: '1px solid red' } : null} />
					{this.error && (
						<Text view='alert' size='s'>
							{this.error}
						</Text>
					)}
				</Field>
				<Field
					property='numberBlades'
					label={t('engineFamily.numberBlades')}
					isRequired
					disabled={!this.record?.name || !this.access}
				>
					<NumberInput integerOnly={true} />
				</Field>
			</ModelEdit>
		);
	}
}

