import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import store from 'client/store';
import t from 'i18n';
import { ButtonGroup, Checkbox, RecordSelect, Select } from '@smartplatform/ui';
import { BUTTONS } from '../graphicReport/constants';
import { Button, LabelField } from 'components';
import { trendReportStore } from './store';
import { Loader } from '@consta/uikit/Loader';
import { DatePicker } from '@consta/uikit/DatePicker';
import { ReportToolbar } from './toolbar';
import { format } from 'date-fns';
import EChartsReact from 'echarts-for-react';
import classNames from 'classnames';
import { Switch } from '@consta/uikit/Switch';
import SearchIcon from '../../../img/icons/search_black.svg';
import { ALFA } from './constants';

import './trendReport.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import Dates from 'client/pages/planes/components/dates/Dates';

@inject('aircraftStore')
@observer
export class TrendReportV2 extends Component {
	constructor(props) {
		super(props);
		this.aircraftId = props.aircraftStore.id;
		this.store = new trendReportStore(this.aircraftId, props.aircraftStore.aircraft);
		this.records = this.store.records;

		// при просмотре отчета, создается запись в журнале (о просмотре отчета)
		// убрал пока
		// const newAuditItem = new store.model.Audit({
		// 	action: 'trendReport',
		// 	model: 'Audit',
		// });
		// newAuditItem.save()
	}

	handleViewChange = (property) => {
		this.store.onViewChange(property);
	};

	renderConf(record) {
		return (
			<div className='item-conf'>
				{record.label}
				{!!record.current && <span>{t('installed')}</span>}
			</div>
		);
	}

	render() {
		const { isLoading } = this.props.aircraftStore;

		const {
			onPhaseChange,
			flightPhase,
			aircraft,
			onAircraftChange,
			records,
			selectedColumns,
			isLoading: isTrendReportLoading,
			startDate,
			endDate,
			onDateChange,
			getTrendOption,
			getLabelOption,
			activeEngines,
			isSmoothed,
			enginesConfigurationItems,
			configuration,
			onChangeConfiguration,
			getSegmentsByConf,
			onChangeSmoothed,
		} = this.store;

		const flightTimeArr = records.map((record) => format(new Date(record.flightTime), 'dd.MM.yyyy HH:mm'));
		const background = '#e2e8f0';
		const columns = selectedColumns.filter((item) => !item.phaseName || item.phaseName === flightPhase.phaseName).filter((item) => item.isSelected);
		const regex = /VB/;

		const chartsHeadContent = [];
		const chartsContent = [];

		chartsHeadContent.push(
			<div className='trend-report-param-title-wrapper'>
				<div className='column-title-parameter-name'>
					<span>{t(`parameter.flightTime`)}</span>
				</div>
			</div>
		);

		chartsContent.push(
			<div className='trend-report-param'>
				<EChartsReact
					style={{
						height: flightTimeArr?.length * 30,
						width: '100%',
						background,
					}}
					opts={{ locale: store.local.appLanguage }}
					option={getLabelOption(flightTimeArr)}
				/>
			</div>
		);

		for (const item of columns) {
			let label = item.label;
			if (item.label === 'ZT49') label = 'ZT49_M';
			const property = item.property;
			const itemClass = classNames({
				engine1: property[property.length - 1] === '1',
				engine2: property[property.length - 1] === '2',
			});
			if (activeEngines.map((item) => item.toString()).includes(property[property.length - 1])) return null;

			let smoothedOld = null;

			const segments = getSegmentsByConf(item.property);
			let values = [];

			for (const segment of segments) {
				const valuesSegment = segment.data;
				const smoothedValues = valuesSegment.map((record, index) => {
					if (smoothedOld === null && isSmoothed) {
						smoothedOld = record.val ? record.val : null;
					} else if (isSmoothed) {
						smoothedOld = record.val ? smoothedOld + ALFA * (record.val - smoothedOld) : null;
					} else {
						smoothedOld = record.val ? record.val : null;
					}
					if (['ZT49_2', 'ZT49_1'].includes(item.property)) {
						return {
							val: ((item.maxLimit - smoothedOld) / 3.5)?.toFixed(3),
							date: format(new Date(record.date), 'dd.MM.yyyy HH:mm'),
							enginesConfiguration: segment.configuration,
						};
					}
					return {
						val: smoothedOld?.toFixed(3),
						date: format(new Date(record.date), 'dd.MM.yyyy HH:mm'),
						enginesConfiguration: segment.configuration,
					};
				});
				values = [...values, ...smoothedValues];
			}
			smoothedOld = null;

			const limits = {
				max: values.some((value) => +value >= item.max) && item.max,
				min: values.some((value) => +value <= item.min) && item.min,
			};

			const zooming = this.store.getButtonState(property)
				? {
						max: undefined,
						min: undefined,
					}
				: {
						max: 0,
						min: 5,
					};

			if (values)
				chartsContent.push(
					<>
						<div className='trend-report-param'>
							<EChartsReact
								style={{
									height: flightTimeArr?.length * 30,
									width: '100%',
									background,
								}}
								opts={{ locale: store.local.appLanguage }}
								option={getTrendOption({ flightTimeArr, values, limits, zooming })}
							/>
						</div>
					</>
				);

			chartsHeadContent.push(
				<div className='trend-report-param-title-wrapper'>
					<div className='column-title-parameter-name'>
						<span className={itemClass}>{t(`parameter.${label}`, { lng: store.mergedConfig?.language || 'en' })}</span>
					</div>
					{regex.test(property) ? (
						<div className='column-title-buttons'>
							<span className='zoom-100'>100%</span>
							<Switch size='l' checked={this.store.getButtonState(property)} onChange={() => this.handleViewChange(property)} />
							<SearchIcon className='zoom-icon' />
						</div>
					) : null}
				</div>
			);
		}

		if (isLoading || isTrendReportLoading) return <Loader />;

		return (
			<Provider trendReportStore={this.store}>
				<div className='wrapper-trend-tab'>
					<div className='wrapper-filter'>
						<RecordSelect
							model={store.model.Aircraft}
							filter={{
								include: ['engines'],
							}}
							property='serialNumber'
							value={aircraft}
							onChange={onAircraftChange}
							isRequired
							style={{ marginRight: '10px' }}
							disabled={true}
						/>
						<ButtonGroup className='phaseButtons'>
							{BUTTONS.map((phaseValue) => (
								<Button
									key={phaseValue.phaseName}
									onClick={() => onPhaseChange(phaseValue)}
									variant={flightPhase.phaseName === phaseValue.phaseName ? 'primary' : 'default'}
								>
									{phaseValue.phaseName}
								</Button>
							))}
						</ButtonGroup>
						<div className='second-row'>
							{!!enginesConfigurationItems.length && (
								<LabelField label={t('aircraft.configuration')} className='engines-conf'>
									<Select
										items={enginesConfigurationItems}
										value={configuration}
										onChange={onChangeConfiguration}
										isRequired
										size='s'
										computed={this.renderConf}
									/>
								</LabelField>
							)}
							<LabelField label={t('aircraft.isSmoothed')} className='smoothed'>
								<Switch size='l' checked={isSmoothed} onChange={onChangeSmoothed} />
							</LabelField>
						</div>
						<Dates startDate={startDate} endDate={endDate} onChange={onDateChange} />
						<ReportToolbar />
					</div>
					<div className='wrapper-heads'>{chartsHeadContent}</div>
					<div className='wrapper-contents'>{chartsContent}</div>
				</div>
			</Provider>
		);
	}
}
