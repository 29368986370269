import React, { Component } from 'react';
import { Provider, observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { ModelEdit } from 'components';
import { Field, Row, Tab, Tabs } from '@smartplatform/ui';
import { MainInfo } from './mainInfo';
import { Engines } from './engines';
import { aircraftStore } from './store';
import { GraphicReport } from './graphicReport';
import { TableReport } from './tableReport';
import { Loader } from '@consta/uikit/Loader';
import { TrendReport } from './trendsReport';
import { TrendReportV2 } from './trendsReportV2';

const PATH = '/planes/:aircraftId';

@observer
export class SinglePage extends Component {
	constructor(props) {
		super(props);
		this.id = parseInt(props.match.params?.id);
		this.store = new aircraftStore(this.id);
		this.store.fetchAircraft();
	}

	render() {
		if (!this.store.aircraft) return <Loader />;

		return (
			<Provider aircraftStore={this.store}>
				<Tabs params={{ aircraftId: this.id }}>
					<Tab
						title={t('aircraft.mainInfo')}
						path={`/planes/${':aircraftId' || 'create'}`}
						render={(routeProps) => <MainInfo {...routeProps} />}
						exact
					/>
					<Tab
						title={t('engine.plural')}
						path={`${PATH}/engines`}
						render={(routeProps) => <Engines {...routeProps} />}
						disabled={!this.id}
					/>
					<Tab
						title={t('aircraft.graphicReport.title')}
						path={`${PATH}/graphic-report`}
						render={(routeProps) => <GraphicReport {...routeProps} />}
						disabled={!this.id}
					/>
					<Tab
						title={t('aircraft.tableReport.title')}
						path={`${PATH}/table-report`}
						disabled={!this.id}
						render={(routeProps) => <TableReport {...routeProps} />}
					/>
					{/*<Tab*/}
					{/*	title={t('aircraft.trendsReport.title')}*/}
					{/*	path={`${PATH}/trends-report`}*/}
					{/*	disabled={!this.id}*/}
					{/*	render={(routeProps) => <TrendReport {...routeProps} />}*/}
					{/*/>*/}
					<Tab
						title={t('aircraft.trendsReport.title')}
						path={`${PATH}/trends-report`}
						disabled={!this.id}
						render={(routeProps) => <TrendReportV2 {...routeProps} />}
					/>
				</Tabs>
			</Provider>
		);
	}
}

