import { observable } from 'mobx';

class AuditStore {

	@observable records = [];
	@observable selected = null;
	@observable page = 1;
	@observable perPage = 20;
	@observable startDate = null;
	@observable endDate = null;
	@observable action = null;
	@observable owner = null;
	@observable model = null;
	@observable order = 'id desc';

}

export default new AuditStore();
