import menuConfig from './layout/sidebar/menu/config';

const submenu = menuConfig.reduce((acc, item) => {
	acc[item.id] = item.isOpenByDefault;
	return acc;
}, {});

export const localStoreConfig = {
	appLanguage: 'en',
	submenu,
	aircraft: {
		graphicReport: {
			flightPhase: null,
			startDate: null,
			endDate: null,
			selectedParams: {},
			language: 'en',
			menu: {
				aircraft: {
					isOpen: true,
				},
				engine: {
					isOpen: true,
				},
			},
		},
		tableReport: {
			flightPhase: null,
			startDate: null,
			endDate: null,
			selectedColumns: {},
		},
		trendsReport: {
			flightPhase: null,
			startDate: null,
			endDate: null,
			selectedColumns: {},
		},
	},
	importLog: {
		status: null,
	},
};
