import React from 'react';
import { observer } from 'mobx-react';

import { Mobile } from '../components';
import { Header } from './header/Header';
import Sidebar from './sidebar/Sidebar';
import store from 'client/store';
import './style.scss';

@observer
export default class Layout extends React.Component {
	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const siteTitle = document.querySelector('title');
		const favicon = document.querySelector('link[rel="icon"]');

		if (store.appSettings.title) siteTitle.innerHTML = store.appSettings.title;
		if (store.appSettings.favicon) favicon.setAttribute('href', store.appSettings.favicon);
	};

	onPageMount = (el) => {
		if (el) {
			el.addEventListener('scroll', this.onScroll);
		}
	};

	onScroll = (e) => {
		// console.log('onScroll', e.target.scrollTop);
		window.dispatchEvent(new CustomEvent('myscroll', { detail: { scrollTop: e.target.scrollTop } }));
	};

	render() {
		return (
			<div className='layout'>
				<Header />
				<div className='content'>
					<Sidebar />
					<div className='page' ref={this.onPageMount}>
						{store.ui.title && (
							<Mobile>
								<div className='page-title'>{store.ui.title || ''}</div>
							</Mobile>
						)}
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}
