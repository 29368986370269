import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { v4 as uuid } from 'uuid';

import './ripple.scss';

/**
 * Для использования родителю нужно добавить стиль position:relative или добавить обертку Ripple.Wrapper
 * над родительским компонентом Ripple
 */

@observer
export class Ripple extends Component {
	@observable ripplesArray = [];
	@observable isMouseDown = false;
	@observable currentRipple = null;

	timeout;

	//добавляем 1 процент к размеру от текущего - нужно для кнопок квадратных размеров, чтобы ripple корректно
	// подходил по краям
	addRippleSize = (size) => size + size * 0.01;

	calculateRippleSize = ({ mouseCoordinate, firstBoundary, secondBoundary }) => {
		const rangeBetweenCursorAndFirstBoundary = mouseCoordinate - firstBoundary;
		const rangeBetweenCursorAndSecondBoundary = secondBoundary - mouseCoordinate;

		return rangeBetweenCursorAndFirstBoundary > rangeBetweenCursorAndSecondBoundary
			? rangeBetweenCursorAndFirstBoundary
			: rangeBetweenCursorAndSecondBoundary;
	};

	onMouseDown = ({ pageX, pageY, currentTarget }) => {
		const { center } = this.props;

		this.isMouseDown = true;

		const rippleContainer = currentTarget.getBoundingClientRect();
		const { height, width, left, right, top, bottom, x, y } = rippleContainer;

		let mouseCoordinate = pageX,
			firstBoundary = left,
			secondBoundary = right,
			rippleSize,
			rippleX,
			rippleY;

		if (center) {
			rippleSize = height > width ? height / 2 : width / 2;
			rippleSize = this.addRippleSize(rippleSize);
			rippleX = width / 2 - rippleSize / 2;
			rippleY = height / 2 - rippleSize / 2;
		} else {
			if (height > width) {
				mouseCoordinate = pageY;
				firstBoundary = top;
				secondBoundary = bottom;
			}

			rippleSize = this.calculateRippleSize({
				mouseCoordinate,
				firstBoundary,
				secondBoundary,
			});

			rippleSize = this.addRippleSize(rippleSize);

			rippleX = pageX - x - rippleSize / 2;
			rippleY = pageY - y - rippleSize / 2;
		}

		const newRipple = {
			x: rippleX,
			y: rippleY,
			rippleSize,
			id: uuid(),
		};

		this.ripplesArray.push(newRipple);
		this.currentRipple = newRipple;
	};

	removeRipple = () => {
		if (this.ripplesArray.length) {
			this.isMouseDown = false;
			this.timeout = setTimeout(() => {
				const oldRipple = [...this.ripplesArray].shift();
				if (oldRipple?.id !== this.currentRipple?.id) {
					this.ripplesArray.shift();
				}
			}, 520);
			//таймаут должен совпадать с длительностью transition
		}
	};

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	render() {
		return (
			<div
				className='ripple-container'
				onMouseDown={this.onMouseDown}
				onMouseLeave={() => {
					this.currentRipple = null;
					this.removeRipple();
				}}
				onMouseUp={() => {
					this.currentRipple = null;
				}}
				onClick={this.removeRipple}
				onTouchStart={this.onMouseDown}
				onTouchEnd={this.removeRipple}
			>
				{this.ripplesArray.length > 0 &&
					this.ripplesArray.map((ripple, i, array) => (
						<span
							key={ripple.id}
							className='touch-ripple'
							style={{
								width: ripple.rippleSize,
								top: ripple.y,
								left: ripple.x,
								height: ripple.rippleSize,
								opacity: this.isMouseDown && i === array.length - 1 ? 'var(--ripple-opacity)' : '',
							}}
						/>
					))}
			</div>
		);
	}
}

Ripple.Wrapper = RippleWrapper;

const RippleWrapper = ({ children }) => {
	return <div className='ripple-wrapper'>{children}</div>;
};

