import t from 'i18n';
import { renderStatus, renderStatusViewEngine } from './tools';
import { DeleteErrorEditWrapper } from './pages/dictionaries/deleteErrorEditWrapper';

export const DICTIONARIES = [
	{
		title: t('plane.title'),
		type: 'submenu',
		items: [
			{
				Wrapper: DeleteErrorEditWrapper,
				modelName: 'AircraftManufacturer',
			},
			{
				Wrapper: DeleteErrorEditWrapper,
				modelName: 'AircraftFamily',
				editProperties: ['name'],
				editRelations: [{ relation: 'manufacturer', property: 'name' }],
				listProperties: ['name'],
				listRelations: [{ relation: 'manufacturer', property: 'name' }],
			},
			{
				Wrapper: DeleteErrorEditWrapper,
				modelName: 'AircraftType',
			},
			{
				Wrapper: DeleteErrorEditWrapper,
				modelName: 'AircraftHolder',
			},
			{
				Wrapper: DeleteErrorEditWrapper,
				modelName: 'AircraftOperator',
			},
		],
	},
	{
		title: t('engine.title'),
		type: 'submenu',
		items: [
			{
				Wrapper: DeleteErrorEditWrapper,
				modelName: 'ViewEngine',
				modelNameEdit: 'Engine',
				editRelations: [
					{ relation: 'manufacturer', property: 'name' },
					{ relation: 'family', properties: ['name', 'numberBlades', 'manufacturer'] },
					{ relation: 'type', property: 'name' },
					{ relation: 'holder', property: 'name' },
					{ relation: 'aircraft', property: 'serialNumber', disabled: true },
					{
						relation: 'status',
						computed: renderStatus,
						properties: ['id', 'name', 'color'],
						filter: {
							where: {
								code: {
									nin: ['spare', 'onwing'],
								},
							},
						},
					},
				],
				editProperties: ['serialNumber', 'thrustRating'],
				listRelations: [],
				listProperties: [
					{ property: 'serialNumber' },
					{ property: 'engineStatusName', computed: renderStatusViewEngine },
					{ property: 'engineTypeName' },
					{ property: 'engineFamilyName' },
					{ property: 'engineManufacturerName' },
					{ property: 'engineHolderName' },
					{ property: 'aircraftSerialNumber' },
				],
			},
			{
				Wrapper: DeleteErrorEditWrapper,
				modelName: 'EngineManufacturer',
			},
			{
				Wrapper: DeleteErrorEditWrapper,
				modelName: 'EngineFamily',
				editProperties: ['name', 'numberBlades'],
				editRelations: [{ relation: 'manufacturer', property: 'name' }],
				listProperties: ['name', 'numberBlades'],
			},
			{
				Wrapper: DeleteErrorEditWrapper,
				modelName: 'EngineType',
				editRelations: [
					{ relation: 'manufacturer', property: 'name' },
					{ relation: 'family', property: 'name' },
				],
				editProperties: ['name', 'code'],
				listProperties: ['name'],
				listRelations: [
					{ relation: 'family', property: 'name' },
					{ relation: 'manufacturer', property: 'name' },
				],
			},
			{
				Wrapper: DeleteErrorEditWrapper,
				modelName: 'EngineHolder',
			},
			{
				Wrapper: DeleteErrorEditWrapper,
				modelName: 'EngineStatus',
				disabled: (record) => ['spare', 'onwing'].includes(record?.code),
			},
		],
	},
];
