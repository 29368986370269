import React from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { checkModelAccess } from 'client/tools/models/checkModelAccess';
import store from 'client/store';
import { Form } from 'components';
import classNames from 'classnames';
import { getModelRequiredFields } from 'client/tools';

@withRouter
@observer
export class ModelEdit extends React.Component {
	static propTypes = {
		model: PropTypes.any,
		title: PropTypes.any,
		path: PropTypes.string,
		returnTo: PropTypes.string,
		getRecord: PropTypes.func,
		beforeSave: PropTypes.func,
		onSave: PropTypes.func,
		stay: PropTypes.bool,
		noDelete: PropTypes.bool,
		noSave: PropTypes.bool,
		disabled: PropTypes.bool,
		filter: PropTypes.object,
		disableSave: PropTypes.bool,
		disableDelete: PropTypes.bool,
		beforeDelete: PropTypes.func,
		onCancel: PropTypes.func,
		className: PropTypes.string,
		checkRequiredFields: PropTypes.bool,
	};
	static defaultProps = {
		stay: true,
		checkRequiredFields: true,
	};

	@observable record = null;
	@observable error = null;
	@observable isLoading = true;

	isNew = false;

	constructor(props) {
		super(props);
		this.modelName = this.props.model.INFO.name;
		this.error = checkModelAccess(this.modelName);
		if (!this.error) {
			if (props.title) store.ui.title = props.title;
			this.init();
		}
	}

	@action init = async () => {
		this.isLoading = true;
		if (this.props.id) {
			this.error = null;
			try {
				this.record = await this.props.model.findById(this.props.id, this.props.filter);
				this.props.getRecord && this.props.getRecord(this.record);
			} catch (e) {
				this.error = e.message;
			}
		} else {
			const { id, ...rest } = this.props.match.params;
			this.record = new this.props.model(rest);
			this.props.getRecord && this.props.getRecord(this.record);
			this.isNew = true;
		}
		if (this.props.getRecord) {
			this.props.getRecord(this.record);
		}
		this.isLoading = false;
	};

	onError = (error) => {
		console.log('onError', error);
		this.props.onError?.(error);
	};

	back = () => {
		store.route.push({ path: this.props.path });
	};

	onCancel = () => {
		store.route.push({ path: this.props.path });
	};

	get requiredFields() {
		let record = this.record;
		const viewRegExp = /^View/;
		const isView = viewRegExp.test(this.modelName);
		if (isView) {
			const coreModel = this.record.STORE[this.modelName.replace(viewRegExp, '')];
			if (coreModel) {
				record = new coreModel(this.record);
			}
		}
		return getModelRequiredFields(record.MODEL);
	}

	render() {
		if (this.error) return <div className='error'>{this.error}</div>;
		if (this.isLoading) return '...';

		let {
			children,
			beforeSave,
			noDelete,
			noSave,
			noCancel,
			disabled,
			controls,
			disableSave,
			stay,
			deleteTitle,
			beforeDelete,
			onCancel,
			onSave,
			className,
			checkRequiredFields,
			disableDelete
		} = this.props;

		if (checkRequiredFields) {
			const isRequiredFilled = this.record ? !!this.requiredFields.find((field) => !this.record[field]) : true;
			disableSave = isRequiredFilled || disableSave;
		}

		const props = {
			record: this.record,
			onDelete: this.back,
			noDelete,
			noCancel,
			noSave,
			disabled,
			onSave: onSave,
			onError: this.onError,
			beforeSave,
			disableSave,
			stay,
			onCancel: onCancel || this.onCancel,
			deleteTitle,
			beforeDelete,
			disableDelete
		};
		if (controls) props.controls = controls;

		const _className = classNames('fixed-page', className);
		return (
			!this.isLoading && (
				<div className={_className}>
					<Form {...props}>{children}</Form>
				</div>
			)
		);
	}
}

