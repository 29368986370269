import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import './scale.scss';
import { COLUMN_WIDTH, VIB_COLUMNS } from './constants';
import classNames from 'classnames';
import { trendReportStore } from './store';

@observer
export class Scale extends Component {
	constructor(props) {
		super(props);
		cache = {}
	}

	render() {
		const { value, max, min, item } = this.props;
		const regex = /VB/;

		let _max = max;
		if (value > max) {

			if (!cache[item.property]) {
				cache[item.property] = {};
			}
			cache[item.property].max = true;
		}

		let _min = min;
		if (value < _min) {
			_min = value;

			if (!cache[item.property]) {
				cache[item.property] = {};
			}
			cache[item.property].min = true;
		}

		let tagStyle = ({ left: ((value - min) / (_max - min)) * (COLUMN_WIDTH + 10) - 1 });
		if (value >= max) {
			tagStyle = ({ right: -18 })
		} else if (value <= min)  {
			tagStyle = ({ left: -18 })
		}

		return (
			<div className='trend-scale' style={{ display: 'flex' }}>
				{cache[item.property]?.min && <span className='min-container' style={{ width: 20 }}></span>}
				<div className={classNames('tag-container', { overlapped: cache[item.property] })}>
					<span className='tag' style={tagStyle}></span>
				</div>
				{regex.test(item.property) && _max > 5 ?
					cache[item.property]?.max && <span className='max-container' style={{ left: ((5 - min) / (_max - min)) * (COLUMN_WIDTH + 20) - 1 }}></span> :
					cache[item.property]?.max && <span className='max-container-def' style={{ width: 20 }}></span>}
			</div>
		);
	}
}

let cache = {};