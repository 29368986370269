import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';

import './authLayout.scss';

@observer
export class AuthLayout extends Component {
	render() {
		return (
			<div className='auth-layout'>
				<div className='auth-background'>
					<div className='background-layout'></div>
					<div className='slogan'>
						<div>
							<i>для движения</i>
						</div>
						<div>
							<strong>к большим целям</strong>
						</div>
					</div>
				</div>

				{this.props.children}
			</div>
		);
	}
}

