import React from 'react';
import { inject, observer } from 'mobx-react';
import { Column, Pager, Table } from '@smartplatform/ui';
import { PER_PAGE } from './store';
import { NOTIFY_COLORS, MESSAGE_TYPE_ICONS } from 'client/tools/notifyStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'i18n';
import './style.scss';

@inject('store')
@observer
export class NotifyLogsList extends React.Component {
	renderAttachment = (rec) => (
		<a download={rec.reportfile} href={rec.downloadFile('reportfile')}>
			{rec.reportfile}
		</a>
	);

	renderStatus = ({ type, status, response }) =>
		status && (
			<div title={response}>
				{status.name} <FontAwesomeIcon color={NOTIFY_COLORS[status.code]} icon={MESSAGE_TYPE_ICONS[type.code]} />
			</div>
		);

	render() {
		const { notifyLogs, page, onPageChange } = this.props.store;
		if (!notifyLogs.length) return null;
		return (
			<div className='notify-logs-list'>
				{/*<div className='header'>*/}
				{/*	<h2>{t('notifyLog.plural')}</h2>*/}
				{/*	<Pager current={page} onChange={onPageChange} itemsPerPage={PER_PAGE} totalCount={notifyLogs.totalCount} />*/}
				{/*</div>*/}
				{/*<Table rows={notifyLogs} clickable={false}>*/}
				{/*	<Column width={100} property='date' label={t('date')} />*/}
				{/*	<Column width={110} computed={this.renderStatus} label={t('notifyStatus.title')} />*/}
				{/*	<Column property='email' label={t('email.title')} />*/}
				{/*	<Column computed={this.renderAttachment} label={t('attachment')} />*/}
				{/*</Table>*/}
			</div>
		);
	}
}
