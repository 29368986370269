import React from 'react';
import { observer } from 'mobx-react';
import { Desktop } from 'client/components/responsive';
import { User } from './User';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import store from 'client/store';

import './header.scss';
import { Button, IconButton } from 'components';
import { renderFaIcons } from 'client/tools';
import { ButtonGroup } from '@smartplatform/ui';
import { observable } from 'mobx';

@observer
export class Header extends React.Component {
	constructor(props) {
		super(props);
	}

	toggleMenu = () => {
		store.ui.menu = !store.ui.menu;
	};

	render() {
		return (
			<header className='header'>
				<div className='mobile-menu' onClick={this.toggleMenu}>
					<IconButton icon={renderFaIcons.burger} />
				</div>
				<Desktop>
					<div className='header-left'>{store.ui.title || ''}</div>
				</Desktop>

				{store.model.isAuthorized && (
					<div className='header-right'>
						<User />
					</div>
				)}
			</header>
		);
	}
}

