import store from 'client/store';
import { observable, action, toJS } from 'mobx';
import { BUTTONS } from '../graphicReport/constants';
import { endOfDay, format, startOfDay } from 'date-fns';
import t from 'i18n';
import i18next from 'i18next';
import { COLUMNS, EGT_COLUMNS } from './constants';

//todo переписать логику, добавить в фазы поле required, чтобы брать фазы по этому свойству, а не хордокодом

export class trendReportStore {
	@observable flightPhase = BUTTONS[0];
	@observable items = {};
	@observable selectedColumns = COLUMNS;
	@observable mergedColumns = [];
	@observable aircraft = null;
	@observable records = [];
	@observable isEdit = false;
	@observable isLoading = false;
	@observable startDate = null;
	@observable endDate = null;
	@observable key = 0;
	@observable page = 1;
	@observable zoomedColumns = {};
	@observable buttonIsPressed = true;

	perPage = 30;
	selectedFlightPhase = null;
	localStorage = store.local.aircraft.trendsReport;

	buttons = observable.array([]);

	constructor(aircraftId, aircraft) {
		this.id = aircraftId;
		this.aircraft = aircraft;
		this.zoomedColumns = {
			ZVB1F_1: { min: null, max: null },
			ZVB1F_2: { min: null, max: null },
			ZVB1R_1: { min: null, max: null },
			ZVB1R_2: { min: null, max: null },
			ZVB2F_1: { min: null, max: null },
			ZVB2F_2: { min: null, max: null },
			ZVB2R_1: { min: null, max: null },
			ZVB2R_2: { min: null, max: null },
		};

		this.init();
	}

	@action
	addButton(property) {
		this.buttons.push({ property: property, isPressed: true });
	}

	@action
	setButtonState(property) {
		const button = this.buttons.find((b) => b.property === property);
		if (button) {
			button.isPressed = !button.isPressed;
		}
	}

	getButtonState(property) {
		const button = this.buttons.find((b) => b.property === property);
		return button ? button.isPressed : true;
	}

	init = async () => {
		this.isLoading = true;
		this.flightPhase = this.localStorage.flightPhase ?? this.flightPhase;
		this.selectedFlightPhase = await this.fetchFlightPhase();
		const param = await this.fetchParameter('zt49');
		const value = await this.fetchParameterValues(param.id);

		EGT_COLUMNS.forEach((item) => {
			item.maxLimit = value;
		});

		this.selectedColumns = [...COLUMNS, ...EGT_COLUMNS];
		this.startDate = this.localStorage.startDate && new Date(this.localStorage.startDate);
		this.endDate = this.localStorage.endDate && new Date(this.localStorage.endDate);

		const where = {
			aircraftId: this.id,
			phaseId: this.selectedFlightPhase.id,
			and: [],
		};

		if (this.startDate) {
			where.and.push({
				flightTime: {
					gte: startOfDay(this.startDate),
				},
			});
		}

		if (this.endDate) {
			where.and.push({
				flightTime: {
					lte: endOfDay(this.endDate),
				},
			});
		}

		this.query = { where, order: 'flightTime asc', limit: this.perPage, skip: this.page * this.perPage - this.perPage };

		try {
			this.records = await store.model.ViewInput.find(this.query);

			this.zoomedColumns = {
				ZVB1F_1: { min: null, max: null },
				ZVB1F_2: { min: null, max: null },
				ZVB1R_1: { min: null, max: null },
				ZVB1R_2: { min: null, max: null },
				ZVB2F_1: { min: null, max: null },
				ZVB2F_2: { min: null, max: null },
				ZVB2R_1: { min: null, max: null },
				ZVB2R_2: { min: null, max: null },
			};

			this.records.forEach((viewInput) => {
				// Обновляем минимальные и максимальные значения для каждого параметра
				for (const param of Object.keys(this.zoomedColumns)) {
					this.zoomedColumns[param].min = Math.min(this.zoomedColumns[param].min, viewInput[param]);
					this.zoomedColumns[param].max = Math.max(this.zoomedColumns[param].max, viewInput[param]);
				}
			});

			await this.initButtons();

			this.buttons.forEach((button) => {
				const result = this.selectedColumns.find((column) => button.property === column.property);
				if (button.property === result.property) {
					const prop = result.property;
					if (this.zoomedColumns.hasOwnProperty(prop)) {
						result.min = this.zoomedColumns[prop].min;
						result.max = this.zoomedColumns[prop].max;
						const diff = (result.max - result.min) * 0.1;
						result.max = result.max + diff;
						result.max = Number(result.max).toFixed(3);
					}
				}
			});
		} catch (e) {
			console.error('Fetching orders error:', e);
		}

		this.onMergeColumns();

		this.isLoading = false;
	};

	initButtons() {
		const tempArray = Object.keys(this.zoomedColumns).map((key) => {
			return { property: key, isPressed: true };
		});

		tempArray.map((item) => {
			let button = item.property;
			this.addButton(button);
		});
	}

	onViewChange = (property) => {
		if (!this.getButtonState(property)) {
			this.selectedColumns.find((column) => {
				if (column.property === property) {
					const prop = column.property;
					if (this.zoomedColumns.hasOwnProperty(prop)) {
						column.min = this.zoomedColumns[prop].min;
						column.max = this.zoomedColumns[prop].max;
						const diff = (column.max - column.min) * 0.1;
						column.max = column.max + diff;
						column.max = Number(column.max).toFixed(3);
					}
				}
			});
			this.setButtonState(property);
		} else {
			this.selectedColumns.find((column) => {
				if (column.property === property) {
					const prop = column.property;
					if (this.zoomedColumns.hasOwnProperty(prop)) {
						column.min = 0;
						column.max = 5;
					}
				}
			});
			this.setButtonState(property);
		}
	};

	onMergeColumns = () => {
		const mergedArray = [];
		const tempObject = {};

		for (const obj of this.selectedColumns) {
			if (!tempObject[obj.label]) {
				tempObject[obj.label] = { ...obj, properties: [] };
			}
			tempObject[obj.label].properties.push(obj.property);
		}

		for (const label in tempObject) {
			mergedArray.push(tempObject[label]);
		}

		this.mergedColumns = mergedArray;
	};

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.init();
	};

	fetchParameterValues = async (paramId) => {
		const value = await store.model.ParameterLibrary.find({
			where: {
				phaseId: this.selectedFlightPhase.id,
				parameterId: paramId,
			},
		});

		return value[0].maxLimit;
	};

	fetchParameter = async (code) => {
		const param = await store.model.Parameter.find({
			where: {
				code: {
					ilike: code,
				},
			},
			fields: ['id'],
		});

		return param[0];
	};

	fetchFlightPhase = async () => {
		const selectedPhase = (
			await store.model.Phase.find({
				where: {
					name: this.flightPhase.phaseName,
				},
			})
		)[0];

		return selectedPhase;
	};

	onPhaseChange = async (value) => {
		this.flightPhase = value;

		this.localStorage.flightPhase = this.flightPhase;
		store.local.save();
		await this.init();
	};

	onAircraftChange = async (value) => {
		this.aircraft = value;
		this.id = this.aircraft.id;
		await this.init();
	};

	onDateChange = (prop) => async (object) => {
		const { value, e } = object;
		this[prop] = new Date(value);
		this.localStorage[prop] = value;
		store.local.save();

		this.init();
	};

	onToggleEdit = (isEdit) => () => {
		this.isEdit = isEdit;
	};

	onEditConfirm = () => {
		this.onToggleEdit(false)();
	};
}
