import React from 'react';
import { FieldPassword } from 'components/auth/components';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { checkPassword } from 'client/tools/checkPassword';
import { renderFaIcons, stringToBoolean } from 'client/tools';
import { t } from 'i18next';
import classNames from 'classnames';

const RULE_FIELDS = ['requireNumber', 'requireUppercase', 'requireLowercase', 'requireSpecialChars'];

@observer
export default class PasswordWithValidate extends React.Component {
	@observable minLength = 6;
	rules = [];
	@observable invalidRules = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		if (this.props.options) {
			for (const key in this.props.options) {
				if (key === 'minLength') {
					this[key] = parseInt(this.props.options[key]);
				} else if (RULE_FIELDS.includes(key)) {
					const boolVal = typeof this.props.options[key] === 'string' ? stringToBoolean(this.props.options[key]) : this.props.options[key];
					if (boolVal === true) this.rules.push(key);
				}
			}
		}
		if (!Object.keys(this.props.options || {}).some((key) => key !== 'minLength')) this.rules.push('requireNumber');
		if (this.props.value) this.invalidRules = checkPassword(this.props.value, this.minLength, this.rules);
	};

	onChange = (event) => {
		this.invalidRules = !!event.target.value ? checkPassword(event.target.value, this.minLength, this.rules) : [];
		if (this.props.onChange) this.props.onChange(event);
		if (this.props.onCheckInvalid) this.props.onCheckInvalid(this.invalidRules);
	};

	renderItemHint = (prop, index) => {
		const invalid = this.invalidRules.includes(prop);
		return (
			<div className={classNames('item-hint-password', { invalid: invalid })} key={index}>
				{invalid ? renderFaIcons.xMark : renderFaIcons.checkIcon}
				{prop !== 'minLength' ? t(`password.validation.${prop}`) : t(`password.validation.${prop}`, { length: this[prop] })}
			</div>
		);
	};

	render() {
		const { value } = this.props;

		return (
			<div className='password-with-validate'>
				<FieldPassword {...this.props} onChange={(e) => this.onChange(e)} />
				{!!value && (
					<div className='wrapper-password-hint'>
						{this.renderItemHint('minLength')}
						{this.rules.map((prop, index) => this.renderItemHint(prop, index))}
					</div>
				)}
			</div>
		);
	}
}
