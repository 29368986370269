import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import store from 'client/store';
import { Ripple, TruncateText } from 'components';

@observer
export default class MenuItem extends React.Component {
	@observable badgeCount;

	constructor(props) {
		super(props);
		this.init();
	}

	init() {
		// Если указано событие по которому нужно обновлять badge подписываемся на него
		// когда это событие срабатывает, то вызываем обновление коунтера
		if (this.props.badgeEvent) {
			store.socket.on(this.props.badgeEvent, (data) => {
				this.update();
			});
		}
		this.update();
	}

	isActive = (match) => {
		if (match) {
			// так как нельзя менять состояние в момент рендера, откладываем выполнение
			setTimeout(() => {
				store.ui.activeSubMenu = this.props.parentCode;
				this.props.changeVisibility(true);
			}, 0);
		}
		return !!match;
	};

	/**
	 * Обновляем bangeCount через вызов badgeFunc
	 */
	update = async () => {
		if (this.props.badgeFunc) {
			this.badgeCount = await this.props.badgeFunc(store);
		}
	};

	render() {
		const { item } = this.props;
		const { label, path, icon, reactIcon } = item;
		if (item.enabled && !item.enabled(store)) return null;

		const className = classNames('submenu__items--item');
		let renderIcon = <span className='no-icon' />;
		if (icon) renderIcon = <FontAwesomeIcon icon={icon} />;
		if (reactIcon) renderIcon = reactIcon;

		const tasks = this.badgeCount ? 'badge' : '';

		return (
			<NavLink to={path} className={className} activeClassName='active' isActive={this.isActive}>
				<span className='icon'>{renderIcon}</span>
				<div className={classNames('title', tasks)}>
					<TruncateText>{label}</TruncateText>
					{/* {(this.badgeCount && <span className='count'>{this.badgeCount}</span>) || null} */}
				</div>
				<Ripple />
			</NavLink>
		);
	}
}

