import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import AttachIcon from '../../img/icons/attach.svg';
import { FormContext, Loader } from '@smartplatform/ui';
import { Attachment } from './Attachment';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

const bytesToSize = (bytes) => {
	var sizes = [t('bytes'), t('Kb'), t('Mb'), t('Gb'), t('Tb')];
	if (bytes === 0) return '0 ' + sizes[0];
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};

@observer
export default class Attachments extends React.Component {
	static propTypes = {
		record: PropTypes.object.isRequired,
		relation: PropTypes.string,
		property: PropTypes.string,
		canUpload: PropTypes.bool,
		withFormContext: PropTypes.bool,
		onUpload: PropTypes.func,
		onDelete: PropTypes.func,
		uploadTitle: PropTypes.string,
		accept: PropTypes.string,
		noDelete: PropTypes.bool,
		softDelete: PropTypes.bool,
		multiple: PropTypes.bool,
	};

	static contextType = FormContext;

	static defaultProps = {
		relation: 'attachments',
		property: 'filename',
		canUpload: false,
		softDelete: false,
		withFormContext: false,
		multiple: true,
		uploadTitle: t('attachFiles'),
	};

	@observable attachments = [];
	@observable newFiles = [];
	@observable errors = [];
	@observable isUploading = false;

	constructor(props) {
		super(props);
		this.record = this.props.record;
		this.id = this.props.record.MODEL.name + '-' + this.props.relation + '-' + this.record.id;
		const modelName = this.props.record.MODEL.RELATIONS[this.props.relation].model;
		this.model = store.model[modelName];
		this.init();
	}

	init = async () => {
		if (this.record.id) {
			const attachments = this.record[this.props.relation];
			this.attachments = attachments().then ? await attachments() : attachments();
		}
	};

	onAttach = async (e) => {
		const newFiles = [];
		this.errors = [];
		for (let file of e.target.files) {
			const attachment = new this.model();
			attachment[this.props.property] = file.name;
			newFiles.push({
				attachment,
				file,
				uploadProgress: 0,
				done: false,
				error: null,
			});
		}
		this.newFiles = newFiles;
		this.isUploading = true;
		const promises = this.newFiles.map(this.upload);
		await Promise.all(promises);
		this.isUploading = false;
		this.errors = this.newFiles.filter((uploadObj) => !!uploadObj.error);
		this.newFiles = [];
	};

	handleFileProgress = (uploadObj, event) => {
		uploadObj.uploadProgress = event.percent;
	};

	upload = async (uploadObj) => {
		await uploadObj.attachment.save();
		try {
			const res = await this.uploadFile(uploadObj);
			if (this.props.withFormContext) {
				this.context.form.addManyToMany(this.props.relation, uploadObj.attachment.id);
			} else await this.props.record[this.props.relation].add(uploadObj.attachment.id);

			uploadObj.done = true;
			this.attachments.push(uploadObj.attachment);
			this.props.onUpload?.(uploadObj.attachment);
		} catch (error) {
			if (error.status === 413) {
				uploadObj.error = t('file.fileTooBig');
			} else {
				uploadObj.error = t('file.uploadError');
			}
			await uploadObj.attachment.delete();
		}
	};

	uploadFile = (uploadObj) =>
		new Promise((resolve, reject) => {
			uploadObj.attachment
				.uploadFile(this.props.property, uploadObj.file)
				.on('progress', (event) => this.handleFileProgress(uploadObj, event))
				.end((error, result) => {
					console.log({ error, result });
					if (!error) {
						resolve(result);
					} else {
						reject(error);
					}
				});
		});

	onDelete = async (attachment) => {
		const index = this.attachments.findIndex((_attachment) => _attachment.id === attachment.id);
		if (index !== -1) this.attachments.splice(index, 1);
		if (this.props.withFormContext) {
			this.context.form.removeManyToMany(this.props.relation, attachment.id);
		} else {
			if (this.props.softDelete) {
				await this.props.record[this.props.relation].remove(attachment.id);
			} else {
				await attachment.delete();
			}
		}

		this.props.onDelete?.(attachment);
	};

	render() {
		const hideNotMultiple = !this.props.multiple ? this.attachments.length === 0 : true;

		return (
			<div className='attachments'>
				{this.props.canUpload && !this.isUploading && hideNotMultiple && (
					<div className='upload'>
						<AttachIcon />
						<input type='file' id={this.id} onChange={this.onAttach} multiple={this.props.multiple} accept={this.props.accept} />
						<label htmlFor={this.id}>{this.props.uploadTitle}</label>
					</div>
				)}
				{this.attachments.length > 0 && (
					<div className='list'>
						{this.attachments.map((attachment) => (
							<Attachment key={attachment.id} attachment={attachment} onDelete={this.onDelete} noDelete={this.props.noDelete} />
						))}
					</div>
				)}

				{this.isUploading && (
					<div className='new-files'>
						{this.newFiles.map((uploadObj, i) => {
							return uploadObj.done && !uploadObj.error ? (
								<Attachment key={uploadObj.attachment.id} attachment={uploadObj.attachment} isNew />
							) : (
								<div key={i} className='new-file'>
									<div className='icon'>
										<Loader size={14} />
									</div>
									<div className='info'>
										<div className='file-name'>{uploadObj.file.name}</div>
										{uploadObj.uploadProgress !== undefined && (
											<div className='progress'>
												{t('file.uploading')}: <em>{Math.round(uploadObj.uploadProgress) + '%'}</em> из <em>{bytesToSize(uploadObj.file.size)}</em>
											</div>
										)}
									</div>
								</div>
							);
						})}
					</div>
				)}

				{this.errors.length > 0 && (
					<div className='upload-errors'>
						{this.errors.map((uploadObj, i) => {
							return (
								<div key={i} className='new-file upload-error'>
									<div className='icon'>
										<FontAwesomeIcon icon={faExclamationTriangle} />
									</div>
									<div className='info'>
										<div className='file-name'>{uploadObj.file.name}</div>
										<div className='error-msg'>{uploadObj.error}</div>
									</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}
