import t from 'i18n';
import { KG_TO_LBS } from '../constants';
import { formatDate } from 'client/tools';

export const getLineOption = ({ series, param, isConvert }) => ({
	title: {
		show: series?.[0].data.length === 0,
		textStyle: {
			color: 'grey',
			fontSize: 20,
		},
		text: t('aircraft.graphicReport.noData'),
		left: 'center',
		top: 'center',
	},
	tooltip: {
		trigger: 'axis',
		axisPointer: {
			type: 'cross',
		},
	},
	legend: {
		show: true,
	},
	grid: {
		left: 10,
		bottom: '10%',
		width: '95%',
		top: '15%',
		containLabel: true,
		show: true,
	},
	xAxis: {
		type: 'time',
		axisLabel: {
			rotate: 30,
			formatter: (value) => {
				return formatDate(new Date(value), 'yyyy.MM.dd');
			},
		},
	},
	yAxis: {
		type: 'value',
		min: function (value) {
			return value.min;
		},
		max: function (value) {
			return value.max;
		},
	},
	series: convert(series, param.code, isConvert),
});

function convert(series, paramCode, isConvert) {
	if (series && paramCode === 'ZWF36') {
		return series.map((engine) => {
			const convertedEngine = { ...engine };
			convertedEngine.data = [];

			engine.data.forEach((element, index) => {
				convertedEngine.data[index] = [...element];
				convertedEngine.data[index][1] = (element[1] / (isConvert ? KG_TO_LBS : 1)).toFixed(1);
			});
			return convertedEngine;
		});
	} else if (series && paramCode === 'AGW') {
		return series.map((engine) => {
			const convertedEngine = { ...engine };
			convertedEngine.data = [];

			engine.data.forEach((element, index) => {
				convertedEngine.data[index] = [...element];
				convertedEngine.data[index][1] = element[1];
			});
			return convertedEngine;
		});
	}

	return series;
}
