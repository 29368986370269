import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from '@smartplatform/ui';
import { fio, fioShort } from 'client/tools';
import { TruncateText } from '../text';

const User = (props) => {
	const { user, size, full, text } = props;

	const renderContent = () => {
		let _content = text;

		if (!_content) {
			_content = full ? fio(user) : fioShort(user);

			if (!_content) {
				_content = user.username || props.user.email || `userId: ${user.id}`;
			}
		}

		return _content;
	};

	let content = renderContent();

	return (
		<div className='avatar-fio'>
			<Avatar user={user} size={size} />
			{/* <div className='fio'>{content}</div> */}
			<TruncateText className='fio'>{content}</TruncateText>
		</div>
	);
};

User.propTypes = {
	user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	size: PropTypes.number,
	full: PropTypes.bool,
};

User.defaultProps = {
	size: 22,
};

export default User;

