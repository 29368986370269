import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPaperclip,
	faPen,
	faUserPlus,
	faXmark,
	faChartLine,
	faEye,
	faEyeSlash,
	faCheck,
	faClone,
	faInfoCircle,
	faTag,
	faPlus,
	faPlusCircle,
	faChevronDown,
	faChevronUp,
	faFlag,
	faBars,
	faGear,
	faArrowLeft,
	faFileArrowDown,
	faClock,
	faUserLock,
	faLock,
	faCrown,
} from '@fortawesome/free-solid-svg-icons';

const renderFaIcons = {
	attachIcon: faPaperclip,
	editIcon: faPen,
	memberIcon: faUserPlus,
	xMark: faXmark,
	chartIcon: faChartLine,
	eyeIcon: faEye,
	eyeSlashIcon: faEyeSlash,
	checkIcon: faCheck,
	copyIcon: faClone,
	info: faInfoCircle,
	priceListIcon: faTag,
	plus: faPlus,
	circlePlus: faPlusCircle,
	arrowDown: faChevronDown,
	arrowUp: faChevronUp,
	flag: faFlag,
	burger: faBars,
	settings: faGear,
	arrowLeft: faArrowLeft,
	downloadFile: faFileArrowDown,
	clock: faClock,
	userLock: faUserLock,
	lock: faLock,
	crown: faCrown,
};

Object.keys(renderFaIcons).forEach((faIcon) => {
	renderFaIcons[faIcon] = <FontAwesomeIcon icon={renderFaIcons[faIcon]} />;
});

export { renderFaIcons };
