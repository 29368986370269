import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as dictionaries from './dictonaries-list';

export const ModelRoute = (props) => {
	const { editProperties, listProperties, editRelations, listRelations, Wrapper, ...restProps } = props;

	const name = restProps.modelName.replace(/^View/, '');
	const { List, Edit } = dictionaries[name] || dictionaries.UniversalDictionary;

	const editProps = { editProperties, editRelations, ...restProps };
	const listProps = { listProperties, listRelations, ...restProps };

	return (
		<Switch>
			<Route path={restProps.path} exact children={(match) => <List {...listProps} match={match} />} />
			<Route
				path={`${restProps.path}/:id`}
				children={({ match }) => (
					<ConditionalWrapper Wrapper={Wrapper} modelName={restProps.modelName}>
						{(wrapperData) => {
							console.log('wrapper props pass to edit', wrapperData);
							return <Edit {...editProps} match={match} {...wrapperData} />;
						}}
					</ConditionalWrapper>
				)}
			/>
		</Switch>
	);
};

const ConditionalWrapper = ({ Wrapper, children, ...restProps }) =>
	Wrapper ? <Wrapper {...restProps}>{children}</Wrapper> : <>{children()}</>;

