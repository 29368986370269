import React from 'react';
import t from 'i18n';
import { Settings } from 'components/settings/Settings';
import store from 'client/store';
import i18next from 'i18next';

export class MainSettings extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const propsFields = {
			isConverKgToLbs: {
				type: 'boolean',
				props: {
					title: t('_settings.conversionFuelFlow'),
					defaultValue: false,
				},
			},
			enableTableReportPagination: {
				type: 'boolean',
				props: {
					title: t('_settings.enableTableReportPagination'),
					defaultValue: true,
				},
			},
			language: {
				type: 'btn-group',
				props: {
					title: t('aircraft.language'),
					items: ['en', 'ru'],
					defaultValue: store.local.appLanguage,
				},
			},
		};

		return <Settings title={t('_settings.mainInfo')} propsFields={propsFields} serverConfig={store.serverConfig} settingsCode={null} />;
	}
}
