import React from 'react';
import { inject, observer } from 'mobx-react';
import { RefreshButton, Button, CancelButton } from 'components';
import {
	TAKEOFF_AIRCRAFT_PARAMETER_NAMES,
	TAKEOFF_ENGINE_PARAMETER_NAMES,
	CRUISE_AIRCRAFT_PARAMETER_NAMES,
	CRUISE_ENGINE_PARAMETER_NAMES,
} from '../../graphicReport/constants';
import { Select } from '@smartplatform/ui';
import { EditColumnsPopup } from './editPopup';
import t from 'i18n';

import './orderReportToolbar.scss';

@inject('tableReportStore')
@observer
export class ReportToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.tableReportStore;
	}

	render() {
		const { isLoading, limit, onLimitChange, downloadReport, isEdit, onToggleEdit, onChangeEngine, styleEngineBtn, printPdf } = this.store;

		const disabled = isEdit || isLoading;

		return (
			<div className='report-toolbar'>
				<div className='action-bar'>
					<div className='form-field'>
						{/* <Select
							items={[REPORT_SELECT_ITEMS]}
							value={limit}
							size='sm'
							onChange={onLimitChange}
							noSearch
							width={100}
							disabled={disabled}
							isRequired
							itemsPerPage={10000}
						/> */}
					</div>
					<div className='actions'>
						<Button size='sm' disabled={disabled} onClick={downloadReport} text={t('downloadXLSX')} />
						<Button size='sm' disabled={disabled} onClick={printPdf} text={t('downloadPDF')} />
						<Button size='sm' disabled={disabled} onClick={onToggleEdit(true)} text={t('aircraft.tableReport.editReport')} />
						<div className='legend'>
							<div className={styleEngineBtn(1)} onClick={onChangeEngine(1)}>
								<div className='eng1button' />
								<span className='engText'>Двигатель-1</span>
							</div>
							<div className={styleEngineBtn(2)} onClick={onChangeEngine(2)}>
								<div className='eng2button' />
								<span className='engText'>Двигатель-2</span>
							</div>
						</div>
						{isEdit && <EditColumnsPopup />}
					</div>
				</div>
			</div>
		);
	}
}
