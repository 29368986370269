import React from 'react';
import { observer } from 'mobx-react';
import Menu from './menu/Menu';
import store from 'client/store';
import { Link } from 'react-router-dom';
import './sidebar.scss';

@observer
export default class Sidebar extends React.Component {
	constructor(props) {
		super(props);
	}

	onOverlayClick = () => {
		store.ui.menu = false;
	};
	render() {
		const logo = store.ui.logo;
		return (
			<>
				<div className={'mobile-overlay' + (store.ui.menu ? ' active' : '')} onClick={this.onOverlayClick} />
				<div className={'sidebar' + (store.ui.menu ? ' active' : '')}>
					<div className='logo'>
						{logo && (
							<Link to='/'>
								<img src={logo} />
							</Link>
						)}
					</div>
					<Menu />
				</div>
			</>
		);
	}
}
