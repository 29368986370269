import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { ModelEdit } from 'components';
import { Field, RecordSelect } from '@smartplatform/ui';
import { renderStatus } from 'client/tools';
import { camelCase } from 'lodash';
import { Text } from '@consta/uikit/Text';
import { RegExpInput } from 'components/inputs';
import { Loader } from '@consta/uikit/Loader';

const ONWING_STATUS = ['onwing'];

@observer
export class Edit extends Component {
	@observable record = null;
	@observable error = null;
	@observable disableDelete = true;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.model = store.model[props.modelNameEdit];
		this.id = parseInt(props.match.params.id);
		this.path = props.path;
		this.access = store.model.ACL && store.model.ACL.INFO.WRITE;
		this.checkEnableDelete();
	}

	checkEnableDelete = async () => {
		this.disableDelete = !!(await store.model.EngineInput.find({ where: { engineId: this.id }, limit: 1, fields: ['id'] })).length;
		this.isLoading = false;
	};

	getRecord = (record) => {
		this.record = record;
	};

	onManufacturerChange = (value) => {
		if (value) {
			this.record.family = null;
			this.record.type = null;
		}
	};

	onFamilyChange = (value) => {
		if (value) {
			this.record.type = null;
			this.record.family = value;
			this.record.manufacturer = value.manufacturer;
		}
	};

	onTypeChange = (value) => {
		this.record.EngineType = value;
		this.record.family = value.family;
		this.record.manufacturer = value.family.manufacturer;
	};

	onSave = () => store.route.push({ path: this.path });

	onCancel = () => store.route.push({ path: this.path });

	beforeDelete = () => this.props.getValidatedRemove?.(this.record);

	onError = (error) => {
		const errorMessage = error.message.replace(/".*"/gis, '');
		if (error.code == 23505) {
			this.error = t('engineErrorMessage');
		} else {
			this.error = t(`aircraft.${camelCase(errorMessage)}`);
		}
	};

	render() {
		if (this.isLoading) return <Loader />;
		return (
			<ModelEdit
				model={this.model}
				id={this.id}
				getRecord={this.getRecord}
				className='universal-edit'
				path={this.path}
				onError={this.onError}
				onSave={this.onSave}
				onCancel={this.onCancel}
				beforeDelete={this.beforeDelete}
				beforeSave={() => {
					this.error = null;
				}}
				disableSave={this.record?.type == null || this.record?.manufacturer == null || this.record?.family == null || !this.access}
				disableDelete={!this.access}
				noDelete={this.disableDelete}
			>
				<Field property='serialNumber' label={t('engine.serialNumber')} disabled={!this.access} isRequired>
					<RegExpInput regExp={/[^a-z0-9-_]/gi} style={this.error ? { border: '1px solid red' } : null} />
					{this.error && (
						<Text view='alert' size='s'>
							{this.error}
						</Text>
					)}
				</Field>
				<Field property='thrustRating' label={t('engine.thrustRating')} disabled={!this.access} isRequired />
				<Field relation='manufacturer' property='name' label={t('engineManufacturer.title')} disabled={!this.access} isRequired>
					<RecordSelect model={store.model.EngineManufacturer} value={this.record?.manufacturer} onChange={this.onManufacturerChange} isRequired />
				</Field>
				<Field relation='family' property='name' label={t('engineFamily.title')} disabled={!this.access} isRequired>
					<RecordSelect
						model={store.model.EngineFamily}
						value={this.record?.family}
						filter={{
							include: ['manufacturer'],
							where: { manufacturerId: this.record?.manufacturerId },
						}}
						onChange={this.onFamilyChange}
						isRequired
					/>
				</Field>
				<Field relation='type' property='name' label={t('engineType.title')} disabled={!this.access} isRequired>
					<RecordSelect
						model={store.model.EngineType}
						value={this.record?.type}
						filter={{
							include: ['family', 'manufacturer'],
							where: {
								familyId: this.record?.familyId,
							},
						}}
						onChange={this.onTypeChange}
						isRequired
					/>
				</Field>
				<Field relation='holder' property='name' label={t('engineHolder.title')} disabled={!this.access} isRequired />
				<Field relation='aircraft' property='serialNumber' label={t('aircraft.title')} disabled />
				<Field
					relation='status'
					computed={renderStatus}
					filter={{
						where: {
							code: {
								nin: ONWING_STATUS,
							},
						},
					}}
					disabled={ONWING_STATUS.includes(this.record?.status?.code) || !this.access}
					label={t('engineStatus.title')}
					isRequired
				/>
			</ModelEdit>
		);
	}
}
