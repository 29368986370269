import React, { Component } from 'react';
import store from 'client/store';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import { Popup } from '@smartplatform/ui';
import { Button, CancelButton } from 'components';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import { Checkbox } from '@consta/uikit/Checkbox';

import t from 'i18n';

import './editColumnsPopup.scss';

@inject('tableReportStore')
@observer
export class EditColumnsPopup extends Component {
	//@observable selectedColumns = [];
	@observable mergedColumns = [];

	constructor(props) {
		super(props);
		this.store = props.tableReportStore;
		this.mergedColumns = this.store.mergedColumns
			.map((column) => ({ ...column }))
			.filter((item) => {
				return item.phaseName === this.store.flightPhase.phaseName || !item.phaseName;
			});
	}

	onChange = (value, index) => {
		this.mergedColumns[index].isSelected = value;
	};

	onConfirm = () => {
		this.store.mergedColumns = [...this.mergedColumns];
		this.store.localStorage.selectedColumns[this.store.flightPhase.phaseName] = [...this.mergedColumns];
		store.local.save();

		this.store.onToggleEdit(false)();
	};

	onSelectAll = () => {
		this.mergedColumns.forEach((item) => (item.isSelected = true));
	};

	onClearAll = () => {
		for (const item of this.mergedColumns) {
			if (item.property !== 'flightTime') item.isSelected = false;
		}
	};

	render() {
		return (
			<Popup onClose={this.store.onToggleEdit(false)} className='report-order-popup-edit'>
				<Theme preset={presetGpnDefault}>
					<div className='columns'>
						{this.mergedColumns.map((column, index) => {
							let isDisabled = false;
							if (column.label === 'flightTime') {
								isDisabled = true;
							}
							return (
								<Checkbox
									key={column.label}
									className='checkbox'
									checked={column.isSelected}
									onChange={() => this.onChange(!column.isSelected, index)}
									label={t(`parameter.${column.label}`, { lng: store.mergedConfig?.language || 'en' })}
									disabled={isDisabled}
								/>
							);
						})}
					</div>
					<div className='actions'>
						<div className='text-actions'>
							<span onClick={this.onSelectAll}>{t('selectAll')}</span>
							<span onClick={this.onClearAll}>{t('clearAll')}</span>
						</div>
						<div className='button-actions'>
							<Button text={t('confirm')} onClick={this.onConfirm} />
							<CancelButton onClick={this.store.onToggleEdit(false)} />
						</div>
					</div>
				</Theme>
			</Popup>
		);
	}
}
