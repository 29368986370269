import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { formatDate, fioShort } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import InfoIcon from 'client/img/icons/info.svg';
import { Loader } from '@smartplatform/ui';
import { Card } from 'components';

const HIDDEN_FIELDS = {
	User: ['password', 'verificationToken'],
	AccessToken: ['id'],
};

@withRouter
@observer
export default class Single extends React.Component {
	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.id !== this.props.match.params.id) this.init();
	}

	init = async () => {
		this.id = this.props.match.params.id;
		if (this.id) {
			this.record = await store.model.Audit.findById(this.id);
		}
	};

	render() {
		if (!this.props.match.params.id) return <Loader />;
		if (!this.record) return <Loader />;

		const record = this.record;
		return (
			<Card title={t('info')} icon={<InfoIcon />} className='audit-single'>
				<div className='audit-single-body'>
					<div className='audit-single-items'>
						<div className='audit-single-item'>
							<label>{t('date')}</label>
							{formatDate(record.createdAt, 'dd.MM.yyyy HH:mm:ss')}
						</div>
						<div className='audit-single-item'>
							<label>{t('description')}</label>
							{store.model[record.model].INFO.description || <span>{record.model}</span>}
						</div>
						<div className={'audit-single-item'}>
							<label>{t('audit.action')}</label>
							{t(`audit.${record.action}`)}
						</div>
						<div className='audit-single-item'>
							<label>{t('audit.owner')}</label>
							{record.owner ? fioShort(record.owner) : '-'}
						</div>
					</div>
					<hr />
					{record.instance && typeof record.instance === 'object' ? (
						<div className='audit-single-items'>
							{Object.keys(record.instance).map((key) => {
								const isDate = store.model[record.model].PROPERTIES[key]?.type === 'Date';
								let value = record.instance[key];
								if (isDate) value = formatDate(value, 'dd.MM.yyyy HH:mm:ss');
								if (typeof value === 'string') {
									if (value === '') {
										value = '-';
									}
								} else {
									value = JSON.stringify(value);
								}
								if (HIDDEN_FIELDS[record.model] && HIDDEN_FIELDS[record.model].includes(key)) {
									value = <span className='hidden'>{t('audit.hidden')}</span>;
								}
								return (
									<div key={key} className='audit-single-item'>
										<label>{key}</label>
										<div className='value'>{value}</div>
									</div>
								);
							})}
						</div>
					) : null}
				</div>
			</Card>
		);
	}
}

