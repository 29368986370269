import React from 'react';
import classNames from 'classnames';
import { IconButton } from './IconButton';
import { renderFaIcons } from 'client/tools';
import t from 'i18n';

import './iconButton.scss';

const disableRipple = true;

const makeIconButton =
	({ className, ...rest }) =>
	(props) =>
		<IconButton {...rest} {...props} className={classNames(props.className, className)} />;

export const ChartButton = makeIconButton({ icon: renderFaIcons.chartIcon });
export const EditButton = makeIconButton({ icon: renderFaIcons.editIcon, className: 'edit-btn' });
export const AttachButton = makeIconButton({
	endText: t('files'),
	icon: renderFaIcons.attachIcon,
	className: 'attach-button',
});
export const AddUserButton = makeIconButton({
	endText: t('members'),
	icon: renderFaIcons.memberIcon,
	className: 'add-user-button',
});
export const CancelIconButton = makeIconButton({
	icon: renderFaIcons.xMark,
	className: 'cancel-button-icon',
	disableRipple,
});
export const ApproveIconButton = makeIconButton({ icon: renderFaIcons.checkIcon, className: 'check-button-icon' });

export { IconButton } from './IconButton';
export { DeleteIconButton } from './DeleteIconButton';

