import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { Field, RecordSelect } from '@smartplatform/ui';
import { Button, Form } from 'components';
import { renderStatus } from 'client/tools';

import './engine.scss';

@inject('aircraftStore')
@observer
export class Engine extends Component {
	@observable engine = null;
	@observable engines = [];
	@observable isInstalled = false;
	@observable engineSpareStatus = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const aircraftId = parseInt(this.props.match.params.aircraftId);

		const engine = (
			await store.model.Engine.find({
				where: {
					aircraftId,
					enginePosition: this.props.enginePosition,
				},
			})
		)[0];
		this.access = store.model.ACL && store.model.ACL.INFO.WRITE;

		const engineStatuses = await store.model.EngineStatus.find({
			where: {
				code: 'spare',
			},
		});

		this.engineSpareStatus = engineStatuses[0];

		if (engine) {
			this.engine = engine;
			this.isInstalled = true;
		}

		this.engines = await store.model.Engine.find({
			where: {
				aircraftId: null,
			},
		});
	};

	onChange = (engine) => {
		this.removePrevEngines();

		this.engine = engine;
	};

	removePrevEngines = async () => {
		const enginesSamePosition = this.props.aircraft.engines().filter((engine) => engine.enginePosition === this.props.enginePosition);

		for (const engine of enginesSamePosition) {
			engine.aircraftId = null;
			engine.aircraft = null;
			await engine.save();
		}
	};

	fetchStatus = (statusCode) => {
		return store.model.EngineStatus.find({
			where: {
				code: statusCode,
			},
		});
	};

	save = async () => {
		this.isInstalled = true;

		const engineStatus = (await this.fetchStatus('onwing'))[0];

		this.engine.aircraft = this.props.aircraft;
		this.engine.aircraftId = this.props.aircraft.id;
		this.engine.enginePosition = this.props.enginePosition;
		this.engine.status = engineStatus;
		this.engine.statusId = engineStatus.id;

		await this.engine.save();
	};

	removeEngine = async () => {
		this.isInstalled = false;

		const engineStatus = (await this.fetchStatus('spare'))[0];

		this.engine.aircraft = null;
		this.engine.aircraftId = null;
		this.engine.enginePosition = null;
		this.engine.status = engineStatus;
		this.engine.statusId = engineStatus.id;

		await this.engine.save();
		this.engines.push(this.engine);
		this.engine = null;
	};

	render() {
		if (this.isLoading) return null;

		return (
			<div className='engine'>
				<div className='engine-data'>
					<RecordSelect
						model={store.model.Engine}
						value={this.engine}
						property='serialNumber'
						showValue={this.engine?.serialNumber}
						onChange={this.onChange}
						filter={{
							where: {
								id: {
									inq: this.engines.map((engine) => engine.id),
								},
								statusId: this.engineSpareStatus?.id,
							},
						}}
						disabled={this.isInstalled}
					/>
					{this.access && (
						<Button onClick={this.isInstalled ? this.removeEngine : this.save} variant={this.isInstalled ? 'danger' : 'primary'} disabled={!this.engine}>
							{this.isInstalled ? 'Демонтаж двигателя' : 'Монтаж двигателя'}
						</Button>
					)}
				</div>
				{this.engine && (
					<div className='engine-form'>
						<Form record={this.engine} noSave noDelete noCancel disabled>
							<Field relation='manufacturer' property='name' />
							<Field relation='family' property='name' />
							<Field relation='type' property='name' />
							<Field property='serialNumber' />
							<Field relation='status' property='name' computed={renderStatus} />
							<Field relation='holder' property='name' />
						</Form>
					</div>
				)}
			</div>
		);
	}
}
