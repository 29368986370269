import React, { Component } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { observable } from 'mobx';
import { LeftSide } from './left-side';
import { RightSide } from './right-side';
import { graphicReportStore } from './store';
import { Loader } from '@consta/uikit/Loader';

import store from 'client/store';
import t from 'i18n';

import './graphicReport.scss';

@inject('aircraftStore')
@observer
export class GraphicReport extends Component {
	store = null;

	constructor(props) {
		super(props);
		this.id = parseInt(props.match.params?.aircraftId);
		this.store = new graphicReportStore(this.id, props.aircraftStore.aircraft);
		//this.store.init();

		// при просмотре отчета, создается запись в журнале (о просмотре отчета)
		// убрал пока
		// const newAuditItem = new store.model.Audit({
		// 	action: 'graphicReport',
		// 	model: 'Audit',
		// });
		// newAuditItem.save()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.store.aircraft) {
			this.store.aircraft = this.props.aircraftStore.aircraft;
		}
	}

	render() {
		const { isLoading } = this.props.aircraftStore;
		const { key, isInit } = this.store;

		if (isLoading || !isInit) return <Loader />;

		return (
			<Provider graphicReportStore={this.store}>
				<div className='graphic-report' key={key}>
					<LeftSide />
					<RightSide />
				</div>
			</Provider>
		);
	}
}
