import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Checkbox } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

const SYSTEM_MODELS = ['User', 'Role', 'ACL', 'RoleMapping'];
const HIDDEN_MODELS = ['Model', 'Container', 'Attachment', 'AccessToken', 'Comment', 'Setting'];

@observer
export default class ACL extends React.Component {
	@observable acls = {};
	@observable delta = {};

	constructor(props) {
		super(props);

		if (store.model.ACL.INFO.WRITE) {
			const appModels = Object.keys(store.model._models)
				.filter((modelName) => !HIDDEN_MODELS.includes(modelName))
				.sort()
				.filter((modelName) => !SYSTEM_MODELS.includes(modelName));
			this.modelNames = [...appModels, ...SYSTEM_MODELS];
			this.init();
		}

		if (props.instance) {
			props.instance({
				init: this.init,
				save: this.save,
				deleteAll: this.deleteAll,
			});
		}
	}

	init = async () => {
		await this.loadExistingAcls();

		const acls = {};
		this.modelNames.forEach((modelName) => {
			acls[modelName] = {
				read: this.existingAcls[modelName] ? !!this.existingAcls[modelName].read : false,
				write: this.existingAcls[modelName] ? !!this.existingAcls[modelName].write : false,
				execute: this.existingAcls[modelName] ? !!this.existingAcls[modelName].execute : false,
				description: store.model._models[modelName].INFO.description,
			};
		});

		this.acls = acls;
	};

	loadExistingAcls = async () => {
		const records = await store.model.ACL.find({
			where: {
				and: [{ principalType: 'ROLE' }, { permission: 'ALLOW' }, { principalId: this.props.role.name }],
			},
		});

		this.existingAcls = {};
		records.forEach((acl) => {
			if (!this.existingAcls[acl.model]) this.existingAcls[acl.model] = {};
			if (acl.accessType === 'READ') this.existingAcls[acl.model].read = { id: acl.id };
			if (acl.accessType === 'WRITE') this.existingAcls[acl.model].write = { id: acl.id };
			if (acl.accessType === 'EXECUTE') this.existingAcls[acl.model].execute = { id: acl.id };
		});
	};

	save = async () => {
		const promises = [];
		Object.keys(this.acls).forEach((modelName) => {
			let modelUpdates = {};
			const acl = this.acls[modelName];
			const serverAcl = this.existingAcls[modelName];
			if (!serverAcl) {
				Object.keys(acl).forEach((accessType) => {
					if (acl[accessType]) modelUpdates[accessType] = acl[accessType];
				});
			} else {
				Object.keys(acl).forEach((accessType) => {
					if ((!serverAcl[accessType] && acl[accessType]) || (!!serverAcl[accessType] && !acl[accessType])) {
						modelUpdates[accessType] = acl[accessType];
					}
				});
			}
			Object.keys(modelUpdates).forEach((accessType) => {
				if (modelUpdates[accessType]) {
					const acl = new store.model.ACL();
					acl.model = modelName;
					acl.property = '*';
					acl.accessType = accessType.toUpperCase();
					acl.permission = 'ALLOW';
					acl.principalType = 'ROLE';
					acl.principalId = this.props.role.name;
					promises.push(acl.save());
				} else {
					promises.push(store.model.ACL.deleteById(serverAcl[accessType].id));
				}
			});
		});
		await Promise.all(promises);
	};

	deleteAll = async () => {
		const records = await store.model.ACL.find({
			where: {
				and: [{ principalType: 'ROLE' }, { permission: 'ALLOW' }, { principalId: this.props.role.name }],
			},
		});

		const promises = records.map((r) => r.delete());
		await Promise.all(promises);
	};

	render() {
		if (!store.model.ACL.INFO.WRITE) return 'Access denied';

		return (
			<div className='acls'>
				<h2>{t('acls')}</h2>

				<table className='acl-table'>
					<thead>
						<tr>
							<th className='model-name'>{t('acl.model')}</th>
							<th className='description'>{t('description')}</th>
							<th>{t('acl.read')}</th>
							<th>{t('acl.write')}</th>
							{/*<th>{t('acl.execute')}</th>*/}
						</tr>
					</thead>
					<tbody>
						{Object.keys(this.acls).map((modelName) => {
							const acl = this.acls[modelName];
							return (
								<tr key={modelName} className={SYSTEM_MODELS.includes(modelName) ? 'system' : ''}>
									<td className='model-name'>{modelName}</td>
									<td className='description'>{acl.description}</td>
									<td>
										<Checkbox value={acl.read} onChange={(value) => (acl.read = value)} />
									</td>
									<td>
										<Checkbox value={acl.write} onChange={(value) => (acl.write = value)} />
									</td>
									{/*<td><Checkbox value={acl.execute} onChange={value => acl.execute = value} /></td>*/}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	}
}
