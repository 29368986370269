import React from 'react';
import { Input } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export class RegExpInput extends React.Component{
	@observable value = '';
	constructor(props) {
		super(props);
		this.value = this?.props?.value;
	}

	onChange = (val, e) => {
		const {regExp} = this.props;
		this.value = regExp ? val.replace(regExp, '') : val;
		this.props.onChange && this.props.onChange(this.value, e);
	}

	render(){
		const newProps = {
			...this.props,
			value: this.value,
			onChange: this.onChange
		}

		delete newProps['regExp'];

		return(
			<Input {...newProps} />
		);
	}
}