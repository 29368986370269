import classNames from 'classnames';
import React from 'react';
import './style.scss';

export const Card = ({ children, title, icon, className }) => {
	const _className = classNames(className, 'card');
	return (
		<div className={_className}>
			<div className='card-title'>
				{icon}
				<h2>{title}</h2>
			</div>
			<div className='card-content'>{children}</div>
		</div>
	);
};
