import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { ModelEdit } from 'components';
import { Field, RecordSelect, Input } from '@smartplatform/ui';
import { camelCase } from 'lodash';
import { Text } from '@consta/uikit/Text';

@observer
export class Edit extends Component {
	@observable record = null;
	@observable name = '';
	@observable error = null;

	constructor(props) {
		super(props);
		this.model = store.model[props.modelName];
		this.id = parseInt(props.match.params.id);
		this.path = props.path;
		this.access = store.model.ACL && store.model.ACL.INFO.WRITE;
	}

	onError = (error) => {
		const errorMessage = error.message.replace(/".*"/gis, '');
		if (error.code == 23505) {
			this.error = t('errorMessage');
		} else {
			this.error = t(`aircraft.${camelCase(errorMessage)}`);
		};
	};

	getRecord = (record) => {
		this.record = record;
		console.log(record);
	};

	onManufacturerChange = () => {
		this.record.name = '';
		this.record.family = '';
	};

	beforeDelete = () => this.props.getValidatedRemove?.(this.record);

	onSave = () => store.route.push({ path: this.path });

	onCancel = () => store.route.push({ path: this.path });

	render() {
		return (
			<ModelEdit
				model={this.model}
				id={this.id}
				getRecord={this.getRecord}
				className='universal-edit'
				path={this.path}
				onError={this.onError}
				beforeSave={() => {
					this.error = null;
				}}
				beforeDelete={this.beforeDelete}
				onSave={this.onSave}
				onCancel={this.onCancel}
				disableSave={!this.access}
				disableDelete={!this.access}
			>
				<Field
					relation='manufacturer'
					property='name'
					label={t('aircraftManufacturer.title')}
					disabled={!this.access}
					isRequired
				>
					<RecordSelect
						model={store.model.AircraftManufacturer}
						value={this.record?.manufacturer}
						onChange={this.onManufacturerChange}
						isRequired
					/>
				</Field>
				<Field
					relation='family'
					property='name'
					label={t('aircraftFamily.title')}
					isRequired
					disabled={!this.record?.manufacturerId || !this.access}
				>
					<RecordSelect
						model={store.model.AircraftFamily}
						value={this.record?.family}
						isRequired
						filter={{
							include: ['manufacturer'],
							where: {manufacturerId: this.record?.manufacturerId}
						}}
						onChange={this.onFamilyChange}
					/>
				</Field>
				<Field property='name' label={t('name')} disabled={!this.record?.familyId || !this.access} isRequired>
					<Input style={this.error ? { border: '1px solid red' } : null} />
					{this.error && (
						<Text view='alert' size='s'>
							{this.error}
						</Text>
					)}
				</Field>
			</ModelEdit>
		);
	};
};