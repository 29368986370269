import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import { tableReportStore } from './store';
import { Button } from 'components';
import { ButtonGroup, Column, Table, Popover, RecordSelect, Select, Pager } from '@smartplatform/ui';
import { Loader } from '@consta/uikit/Loader';
import { DatePicker } from '@consta/uikit/DatePicker';
import { ReportToolbar } from './toolbar';
import classNames from 'classnames';
import './tableReport.scss';

import store from 'client/store';
import t from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import Dates from 'client/pages/planes/components/dates/Dates';

@inject('aircraftStore')
@observer
export class TableReport extends Component {
	store = null;

	constructor(props) {
		super(props);
		this.aircraftId = props.aircraftStore.id;
		this.store = new tableReportStore(this.aircraftId);

		// при просмотре отчета, создается запись в журнале (о просмотре отчета)
		// убрал пока
		// const newAuditItem = new store.model.Audit({
		// 	action: 'tableReport',
		// 	model: 'Audit',
		// });
		// newAuditItem.save()
	}

	renderConf(record) {
		return (
			<div className='item-conf'>
				{record.label}
				{!!record.current && <span>{t('installed')}</span>}
			</div>
		);
	}

	render() {
		const {
			onPhaseChange,
			flightPhase,
			mergedColumns,
			onDateChange,
			startDate,
			endDate,
			key,
			onAircraftChange,
			aircraft,
			activeEngines,
			isLoading,
			BUTTONS,
			isInit,
			enginesConfigurationItems,
			configuration,
			onChangeConfiguration,
			perPage,
			page,
			onPageChange,
			totalCount,
			enablePagination,
		} = this.store;

		if (!isInit) return <Loader />;

		const _selectedColumns = mergedColumns
			.filter((column) => column.isSelected)
			.flatMap((column) => {
				return column.properties.map((property) => {
					const propertyClass = classNames({
						engine1: property[property.length - 1] === '1',
						engine2: property[property.length - 1] === '2',
					});
					if (activeEngines.map((item) => item.toString()).includes(property[property.length - 1])) return null;
					return (
						<Column
							{...column}
							key={property}
							property={property.replace('-', '_')}
							className={propertyClass}
							label={
								<Popover className={propertyClass} content={t(`parameter.${column.label}`)}>
									{property}
								</Popover>
							}
						/>
					);
				});
			})
			.filter((column) => column !== null);
		return (
			<Provider tableReportStore={this.store}>
				<div className='table-report' key={key}>
					<div className='topToolbar'>
						<div className='firstRow'>
							<RecordSelect
								model={store.model.Aircraft}
								filter={{
									include: ['engines'],
								}}
								property='serialNumber'
								value={aircraft}
								onChange={onAircraftChange}
								isRequired
								style={{ marginRight: '10px' }}
								disabled={true}
							/>
							<ButtonGroup className='phaseButtons'>
								{BUTTONS.map((phaseValue) => (
									<Button
										key={phaseValue.phaseName}
										onClick={() => onPhaseChange(phaseValue)}
										variant={flightPhase.phaseName === phaseValue.phaseName ? 'primary' : 'default'}
									>
										{phaseValue.phaseName}
									</Button>
								))}
							</ButtonGroup>
						</div>

						{!!enginesConfigurationItems.length && (
							<div className='configurations'>
								{t('aircraft.configuration')}
								<Select
									items={enginesConfigurationItems}
									value={configuration}
									onChange={onChangeConfiguration}
									isRequired
									size='s'
									computed={this.renderConf}
								/>
							</div>
						)}
						<Dates startDate={startDate} endDate={endDate} onChange={onDateChange} />
						<ReportToolbar />
					</div>
					<div className='table-report-wrapper'>
						{isLoading ? (
							<div className='table-report-wrapper-loading'>
								<Loader />
							</div>
						) : (
							<>
								<Table rows={this.store.records}>{_selectedColumns}</Table>
								{enablePagination && <Pager current={page} onChange={onPageChange} totalCount={totalCount} itemsPerPage={perPage} />}
							</>
						)}
					</div>
				</div>
			</Provider>
		);
	}
}
