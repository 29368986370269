import React from 'react';
import t from 'i18n';
import { Settings } from 'components/settings/Settings';
import store from 'client/store';

export class AuthenticationSettings extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const propsFields = {
			triesLimitBeforeCaptcha: {
				type: 'number',
				props: {
					title: t('authenticationSettings.triesLimitBeforeCaptcha'),
					defaultValue: 0,
				},
			},
			triesLimitBeforeBlock: {
				type: 'number',
				props: {
					title: t('authenticationSettings.triesLimitBeforeBlock'),
					defaultValue: 5,
				},
			},
			daysNoActivityBeforeBlocked: {
				type: 'number',
				props: {
					title: t('authenticationSettings.daysNoActivityBeforeBlocked'),
					defaultValue: 0,
				},
			},
		};

		return (
			<Settings
				title={t('authenticationSettings.title')}
				propsFields={propsFields}
				serverConfig={store.serverConfig?.authentication?.login}
				settingsCode='authentication.login'
			/>
		);
	}
}
