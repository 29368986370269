import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { ModelEdit } from 'components';
import { Field, NumberInput, RecordSelect, Input, Checkbox, Popover } from '@smartplatform/ui';
import { camelCase } from 'lodash';
import { Text } from '@consta/uikit/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { getRoleNames } from 'client/tools';

@observer
export class Edit extends Component {
    @observable record = null;
    @observable name = '';
	@observable error = null;

    constructor(props) {
        super(props);
        this.model = store.model[props.modelName];
        this.id = parseInt(props.match.params.id);
		this.path = props.path;
		this.access = store.model.ACL && store.model.ACL.INFO.WRITE;
	}

	onError = (error) => {
		const errorMessage = error.message.replace(/".*"/gis, '');
		if (error.code == 23505) {
			this.error = t('errorMessage');
		} else {
			this.error = t(`aircraft.${camelCase(errorMessage)}`);
		};
	};

    getRecord = (record) => {
        this.record = record;
    };

	beforeDelete = () => this.props.getValidatedRemove?.(this.record);

	onSave = () => store.route.push({ path: this.path });

	onCancel = () => store.route.push({ path: this.path });

	render() {

		const labelScrewRender = (
			<>
				{t('aircraftFamily.isScrew')}
				<Popover  content={t('aircraftFamily.isScrewHint')} position='top'>
					<FontAwesomeIcon icon={faQuestionCircle} />
				</Popover>
			</>
		);

		return (
			<ModelEdit
				model={this.model}
				id={this.id}
				getRecord={this.getRecord}
				className='universal-edit'
				path={this.path}
				onError={this.onError}
				beforeSave={() => {
					this.error = null;
				}}
				beforeDelete={this.beforeDelete}
				onSave={this.onSave}
				onCancel={this.onCancel}
				disableSave={!this.access}
				disableDelete={!this.access}
			>
				<Field property='name' label={t('aircraftFamily.name')} disabled={!this.access} isRequired>
					<Input style={this.error ? { border: '1px solid red' } : null} />
					{this.error && (
						<Text view='alert' size='s'>
							{this.error}
						</Text>
					)}
				</Field>
				<Field property='numberEngines' label={t('aircraftFamily.numberEngines')} disabled={!this.access}>
					<NumberInput integerOnly={true} />
				</Field>
				<Field relation='manufacturer' property='name' label={t('aircraftManufacturer.title')} disabled={!this.access} isRequired>
					<RecordSelect model={store.model.AircraftManufacturer} value={this.record?.manufacturer} />
				</Field>
				{getRoleNames().includes('admin') && (
					<Field property='isScrew' className='filed-with-hint' label={labelScrewRender}>
						<Checkbox checked={this.record?.isScrew} disabled={!this.access} />
					</Field>
				)}
			</ModelEdit>
		);
	}
}

