import React from 'react';
import { DatePicker, RecordSelect, Select } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store';
import { fioShort } from 'client/tools';
import { observer } from 'mobx-react';
import aStore from './store';
import { isBefore } from 'date-fns';

const EXCLUDED_MODELS = ['Audit', 'Container', 'Email', 'Telegram'];

@observer
export default class Filters extends React.Component {
	constructor(props) {
		super(props);
	}

	onOwnerChange = (owner) => {
		aStore.owner = owner;
		aStore.page = 1;
		this.props.onChange();
	};

	onActionChange = (action) => {
		aStore.action = action;
		aStore.page = 1;
		this.props.onChange();
	};

	onModelChange = (model) => {
		aStore.model = model;
		aStore.page = 1;
		this.props.onChange();
	};

	onStartDateChange = (date) => {
		aStore.startDate = date;
		if (aStore.startDate && aStore.endDate && isBefore(new Date(aStore.endDate), new Date(aStore.startDate)))
			aStore.endDate = aStore.startDate;
		aStore.page = 1;
		this.props.onChange();
	};

	onEndDateChange = (date) => {
		aStore.endDate = date;
		if (aStore.startDate && aStore.endDate && isBefore(new Date(aStore.endDate), new Date(aStore.startDate)))
			aStore.startDate = aStore.endDate;
		aStore.page = 1;
		this.props.onChange();
	};

	render() {
		const { startDate, endDate, model, action, owner } = aStore;
		const models = Object.keys(store.model._models)
			.filter((modelName) => !/^View.+/.test(modelName))
			.filter((modelName) => !EXCLUDED_MODELS.includes(modelName))
			.sort((a, b) => (a > b ? 1 : -1))
			.map((modelName) => ({ label: store.model[modelName].INFO.description || modelName, value: modelName }));

		return (
			<div className='filters'>
				<DatePicker value={startDate} onChange={this.onStartDateChange} showTimeSelect={false} placeholder={t('date1')} />
				<DatePicker value={endDate} onChange={this.onEndDateChange} showTimeSelect={false} placeholder={t('date2')} />
				<Select
					noTotal
					maxItems={10}
					itemsPerPage={1000}
					items={models}
					value={model}
					onChange={this.onModelChange}
					className='drop-down-md'
					showValue={
						model !== null ? store.model[model].INFO.description || model : <span className='hint'>{t('audit.model')}</span>
					}
					position='bottomLeft'
					width={200}
				/>
				<Select
					noTotal
					maxItems={10}
					itemsPerPage={1000}
					items={[
						{ label: t('audit.create'), value: 'create' },
						{ label: t('audit.update'), value: 'update' },
						{ label: t('audit.delete'), value: 'delete' },
						{ label: t('audit.authorizationUser'), value: 'authorizationUser' },
						{ label: t('audit.unAuthorizationUser'), value: 'unAuthorizationUser' },
					]}
					value={action}
					onChange={this.onActionChange}
					className='drop-down-md'
					showValue={action ? t(`audit.${action}`) : <span className='hint'>{t('audit.action')}</span>}
					position='bottomLeft'
					noSearch
					width={200}
				/>
				<RecordSelect
					noTotal
					maxItems={10}
					itemsPerPage={1000}
					model={store.model.User}
					value={owner}
					onChange={this.onOwnerChange}
					computed={fioShort}
					className='drop-down-md'
					showValue={owner ? fioShort(owner) : <span className='hint'>{t('audit.owner')}</span>}
					width={200}
				/>
			</div>
		);
	}
}

