import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Row, Field, Input, MaskedInput, Checkbox, Popconfirm } from '@smartplatform/ui';
import { Section, ImageLoader, Form } from 'components';
import { fioShort, validateEmail } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

import './profile.scss';
import PasswordWithValidate from 'components/auth/components/field-password/PasswordWithValidate';
import { FieldPassword } from 'components/auth/components';
import { triggerErrorNotification } from 'components/notifications';
import { format } from 'date-fns';

@observer
export default class Profile extends React.Component {
	@observable record = null;
	@observable error = null;
	@observable isLoading = true;
	@observable password = '';
	@observable password2 = '';
	@observable passwordOld = null;
	@observable isPasswordVisible = false;
	@observable validPasswordComplexity = true;
	@observable passwordOld = null;

	id;

	constructor(props) {
		super(props);
		store.ui.title = t('user.profile');
		if (props.match.params.id) this.id = props.match.params.id;
		else if (props.create) this.id = null;
		else this.id = store.model.user.id;
		this.init();
	}

	init = async () => {
		this.error = null;
		await store.fetchDbServerConfig();
		if (this.id) {
			try {
				this.record = await store.model.User.findById(this.id);
			} catch (e) {
				this.error = e.message;
			}
		} else {
			try {
				this.record = new store.model.User();
			} catch {}
		}
		const _passwordExpirationTime = parseInt(store.mergedConfig?.authentication?.password?.countUniqueCharts);
		this.enableUniqueCharts = !!_passwordExpirationTime && _passwordExpirationTime !== 0 && store.model.user.id === this.record.id;
		this.isLoading = false;
	};

	beforeSave = (user) => {
		try {
			if (!user.email) {
				throw new Error(t('user.emptyEmail'));
			} else if (!validateEmail(user.email)) {
				throw new Error(t('user.invalidEmail'));
			}
			if (!user.id) {
				if (this.password.length === 0) {
					throw new Error(t('password.empty'));
				} else if (this.password2.length === 0) {
					throw new Error(t('password.repeat'));
				}
			}

			if (this.password.length > 0 || this.password2.length > 0) {
				if (this.enableUniqueCharts && !this.passwordOld) throw new Error(t('password.validation.passwordOld'));
				if (this.password !== this.password2) {
					throw new Error(t('password.noMatch'));
				} else {
					user.password = this.password;
					user.passwordOld = this.enableUniqueCharts ? this.passwordOld : null;
				}
			}
		} catch (error) {
			const message = error.message;
			triggerErrorNotification(message);
			return false;
		}
	};

	togglePasswordVisible = () => {
		this.isPasswordVisible = !this.isPasswordVisible;
	};

	onChangePassword = (prop) => (e) => {
		this[prop] = e.target.value;
	};

	onCancel = () => {
		if (store.model.User?.INFO.WRITE) store.route.push({ path: `/users` });
		else store.route.push({ path: `/management/tasks` });
	};

	onChangeBlocked = () => {
		this.record.isBlocked = !this.record.isBlocked;
		this.record.blockedStatus = this.record.isBlocked
			? t('manualLocking', { userShort: fioShort(store.model.user), dateBlocked: format(new Date(), 'dd.MM.yyyy') })
			: null;
	};

	renderIsBlocked = () => {
		const isBlocked = this.record.isBlocked;
		const popUpContent = isBlocked ? t('profile.notBlocked') : t('profile.blocked');

		return (
			<Popconfirm content={popUpContent} onConfirm={this.onChangeBlocked}>
				<Checkbox checked={isBlocked} label={t('user.isBlocked')} />
			</Popconfirm>
		);
	};

	onSave = () => {
		this.record.password = undefined;
		store.route.push({ path: `/users` });
	};

	onDelete = () => store.route.push({ path: `/users` });

	onEmailMount = (el) => {
		if (el && !this.record.id) el.focus();
	};

	render() {
		if (!store.model.user) return null;
		if (this.isLoading) return '...';
		if (!this.record && this.error) return <div className='error'>{this.error}</div>;

		let isUserAbleToBlockUser = (store.model.ACL && store.model.ACL.INFO.WRITE) || false;
		let isMyAccount = store.model.user.id === this.record.id;
		const disableSaveForm = !this.record.email || !this.record.lastName || !this.record.firstName;
		const myProfile = this.id ? parseInt(this.id) === store.model.user.id : false;

		return (
			<div className='profile-page fixed-page'>
				<Section className='profile'>
					<header>{t('profile.title')}</header>
					<Form
						record={this.record}
						stay
						noDelete={isMyAccount || !isUserAbleToBlockUser}
						onSave={this.onSave}
						beforeSave={this.beforeSave}
						onCancel={this.onCancel}
						disableSave={!this.record.email || !this.validPasswordComplexity}
						checkRequiredFields={false}
					>
						<Field property='avatar'>
							<ImageLoader width={300} height={300} scale={1} />
						</Field>
						<Row>
							<Field property='email' label={t('user.email')} isRequired>
								<Input placeholder='qwe@gmail.com' getRef={this.onEmailMount} autoComplete='new-password' />
							</Field>
							<Field property='lastName' label={t('user.lastName')} isRequired>
								<Input placeholder={t('profile.enterLastName')} autoComplete='new-password' />
							</Field>
						</Row>
						<Row>
							<Field property='firstName' label={t('user.firstName')} isRequired>
								<Input placeholder={t('profile.enterName')} autoComplete='new-password' />
							</Field>
							<Field property='middleName' label={t('user.middleName')}>
								<Input placeholder={t('profile.enterMiddleName')} autoComplete='new-password' />
							</Field>
						</Row>
						<Row>
							<Field property='phone' label={t('profile.phone')}>
								<MaskedInput placeholder={'+7 (123) 456 78-90'} mask='+7 (999) 999 99-99' autoComplete='new-password' />
							</Field>
							<div></div>
							{/*<Field property='telegram' label={t('user.telegram')}>
								<Input placeholder={t('profile.enterId')} />
							</Field>*/}
						</Row>
						<Row>
							<div className='form-field'>
								<PasswordWithValidate
									label={t('password.new')}
									value={this.password}
									onChange={this.onChangePassword('password')}
									onCheckInvalid={(items) => (this.validPasswordComplexity = !items.length)}
									options={store.mergedConfig?.authentication?.password}
								/>
								{!this.password && <small>{t('password.newPasswordHint')}</small>}
							</div>
							<div className='form-field'>
								<FieldPassword
									label={t('password.repeat')}
									value={this.password2}
									onChange={this.onChangePassword('password2')}
									disabled={!this.password && !this.password2}
								/>
							</div>
						</Row>

						{this.enableUniqueCharts && this.record.id === store.model.user.id && (
							<Row>
								<div className='form-field'>
									<FieldPassword label={t('password.old')} value={this.passwordOld} onChange={this.onChangePassword('passwordOld')} />
								</div>
								<div></div>
							</Row>
						)}

						<Row>
							<div className='custom-field-wrapper'>
								<Checkbox
									checked={this.record.protectionNoActivity}
									label={t('profile.protectionNoActivity')}
									onChange={() => (this.record.protectionNoActivity = !this.record.protectionNoActivity)}
								/>
							</div>
						</Row>

						{store.isAdmin && this.record.isBlocked && this.record.blockedStatus && (
							<div className='custom-field-wrapper'>
								<p>{t('profile.blockedStatus')}</p>
								<p>{this.record.blockedStatus}</p>
							</div>
						)}

						{!myProfile && <Row>{this.record?.id && isUserAbleToBlockUser && this.renderIsBlocked()}</Row>}

						{this.error && <div className='error'>{this.error}</div>}
					</Form>
				</Section>
			</div>
		);
	}
}
