import React from 'react';
import { ColorPicker, DatePicker, Field, Tab, Tabs, Input } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store';
import { ModelEdit, HasManyList } from 'components';
import { EXCLUDED_FIELDS } from './constants';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { getLabelName } from 'client/tools';
import PropTypes from 'prop-types';
import { camelCase, property } from 'lodash';
import { Text } from '@consta/uikit/Text';
import './style.scss';
@observer
export class Edit extends React.Component {
	static propTypes = {
		editProperties: PropTypes.array,
		excludeProperties: PropTypes.array,
		excludeRelations: PropTypes.array,
	};

	static defaultProps = {
		editProperties: [],
		excludeProperties: [],
		excludeRelations: [],
	};

	@observable error = null;

	@observable record = null;
	filter = {};

	constructor(props) {
		super(props);
		this.modelName = props.modelName;
		this.path = props.path;
		this.model = store.model[this.modelName];
		this.id = parseInt(props.match.params.id);
		this.access = store.model.ACL && store.model.ACL.INFO.WRITE;

		if (!this.model) {
			this.error = 'Invalid model/no read access';
		} else {
			const { editProperties, editRelations, excludedProperties } = props;
			const excluded = [...EXCLUDED_FIELDS, ...excludedProperties];
			const { PROPERTIES, RELATIONS } = this.model;
			//properties убираются исключения , ключи реляций ИЛИ editProperties
			const modelForeignKeys = Object.values(RELATIONS).map(({ foreignKey }) => foreignKey);
			this.properties = Object.entries(PROPERTIES).reduce((acc, [key, value]) => {
				if (![...modelForeignKeys, ...excluded].includes(key)) {
					if (editProperties.length && !editProperties.includes(key)) {
						return acc;
					}
					acc.push({ ...value, name: key });
				}
				return acc;
			}, []);

			//rels belongsTo реляции без owner ИЛИ editRelations
			if (!editRelations) {
				this.relations = Object.keys(RELATIONS)
					.filter((relation) => RELATIONS[relation].name !== 'owner' && RELATIONS[relation].type === 'belongsTo')
					.map((relation) => ({ ...RELATIONS[relation], property: 'name' }));
			} else {
				this.relations = editRelations?.slice(0).map((relation) => ({ ...relation, ...RELATIONS[relation.relation] })) || [];
			}

			//filter - в инклюде реляции с филдами, в филдах поля проперти и ключи реляций
			this.filter.include = this.relations.map(({ name, properties = [], property }) => ({
				relation: name,
				scope: { fields: property || properties },
			}));
			this.filter.fields = [
				// айдишка чтобы подтягивать реляции hasMany/manyToMany
				'id',
				// филды пропов
				...this.properties.map(({ name }) => name),
				// ключи реляций belongsto
				...this.relations.filter(({ type }) => type === 'belongsTo').map(({ foreignKey }) => foreignKey),
			];
		}
	}


	onError = (error) => {
		const errorMessage = error.message.replace(/".*"/gis, '');
		if (error.code == 23505) {
			this.error = t('errorMessage');
		} else {
			this.error = t(`aircraft.${camelCase(errorMessage)}`);
		};
	};

	getRecord = (record) => {
		this.record = record;
	};

	beforeDelete = () => this.props.getValidatedRemove?.(this.record);

	onSave = () => store.route.push({ path: this.path });

	onCancel = () => store.route.push({ path: this.path });

	render() {
		const { belongsTo = [], hasMany = [] } = this.relations.reduce((acc, relation) => {
			if (!acc[relation.type]) acc[relation.type] = [];
			acc[relation.type].push(relation);
			return acc;
		}, {});
		const { onEdit, Component, disabled, excludeProperties, excludeRelations } = this.props;

		let _disabled = disabled;
		if (typeof disabled === 'function') {
			_disabled = disabled?.(this.record);
		}

		const mainInfoForm = (
			<>
				<ModelEdit
					model={this.model}
					id={this.id}
					getRecord={this.getRecord}
					className='universal-edit'
					path={this.path}
					onError={this.onError}
					beforeSave={() => {
						this.error = null;
					}}
					onCancel={this.onCancel}
					onSave={this.onSave}
					beforeDelete={this.beforeDelete}
					disableSave={!this.access}
					disableDelete={!this.access}
				>
					{this.properties
						.filter((property) => !excludeProperties.includes(property.name))
						.map(({ type, name }) => {
							const label = getLabelName(name, this.modelName);
							let content;
							if (name === 'color') {
								content = <ColorPicker disabled={!this.access}/>;
							} else if (type === 'Date') {
								content = <DatePicker format='dd.MM.yyyy' showClearButton={false} disabled={!this.access}/>;
							} else if (name === 'name') {
								return (
									<Field key={name} property={name} label={label} disabled={['updatedAt', 'createdAt'].includes(name) || !this.access} isRequired>
										<Input style={this.error ? { border: '1px solid red' } : null} />
										{this.error && (
											<Text view='alert' size='s'>
												{this.error}
											</Text>
										)}
									</Field>
								)
							}
							return (
								<Field key={name} property={name} label={label} disabled={['updatedAt', 'createdAt'].includes(name) || !this.access} isRequired>
									{content}
								</Field>
							);
						})}
					{belongsTo
						.filter((relation) => !excludeRelations.includes(relation.name))
						.map(({ name, property, computed, children, filter, disabled, model }) => {
							const label = getLabelName('title', model);
							return (
								<Field
									key={'relation_' + name + property}
									relation={name}
									property={property}
									computed={computed}
									label={label}
									filter={filter}
									disabled={disabled || !this.access}
									isRequired
								>
									{children}
								</Field>
							);
						})}
				</ModelEdit>
				{!!this.id && Component && <Component id={this.id} />}
			</>
		);

		if (hasMany.length) {
			const currentPath = this.props.match.url;
			const record = new this.model({ id: this.id });
			return (
				<Tabs>
					<Tab title={t('mainInfo')} path={currentPath} exact render={() => mainInfoForm} />
					{hasMany.map((relation, index) => (
						<Tab
							title={getLabelName(relation.name, this.modelName)}
							exact
							key={index}
							path={`${currentPath}/${relation.name}`}
							render={() => (
								<HasManyList
									record={record}
									relation={relation.name}
									properties={relation.properties}
									include={relation.include}
								/>
							)}
						/>
					))}
				</Tabs>
			);
		}

		return mainInfoForm;
	}
}

