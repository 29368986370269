import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import { Profile } from '../profile';

const path = '/users';
const modelName = 'ViewUser';

export const Users = () => (
	<Switch>
		<Route path={path} exact children={({ match }) => <List match={match} modelName={modelName} />} />
		<Route path={`${path}/page/:page`} children={({ match }) => <List match={match} modelName={modelName} />} />
		<Route path={`${path}/create`} children={({ match }) => <Profile create={true} match={match} path={path} modelName={modelName} />} />
		<Route path={`${path}/:id`} children={({ match }) => <Profile match={match} path={path} modelName={modelName} />} />
	</Switch>
);
