import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import store from 'client/store';
import t from 'i18n';
import { DatePicker, Loader, RecordSelect, Select } from '@smartplatform/ui';
import { Button } from 'components';
import './logbookToolbar.scss';

const STATUS_VALUES_SELECT = [
	{ label: t('importLog.success'), value: 'success' },
	{ label: t('importLog.error'), value: 'error' },
];

@inject('store')
@observer
export class LogbookToolbar extends Component {
	render() {
		const { flightDate, uploadDate, onChange, aircraft, onReset, status } = this.props.store;

		return (
			<div className='logbook-toolbar'>
				<div className='left'>
					<div className='date-field'>
						<label htmlFor=''>{t('aircraft.serialNumber')}</label>
						<RecordSelect
							model={store.model.Aircraft}
							property='serialNumber'
							value={aircraft}
							showValue={aircraft?.serialNumber}
							onChange={onChange('aircraft')}
						/>
					</div>
					<div className='date-field'>
						<label htmlFor=''>{t('importLog.flightDate')}</label>
						<DatePicker value={flightDate} onChange={onChange('flightDate')} />
					</div>
					<div className='date-field'>
						<label htmlFor=''>{t('importLog.uploadDate')}</label>
						<DatePicker value={uploadDate} onChange={onChange('uploadDate')} />
					</div>
					<div className='status-field'>
						<label htmlFor=''>{t('importLog.status')}</label>
						<Select onChange={onChange('status')} items={STATUS_VALUES_SELECT} value={status} />
					</div>
					<Button onClick={onReset}>{t('reset')}</Button>
				</div>
			</div>
		);
	}
}
