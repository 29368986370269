import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { BasicList } from 'components';
import { Column } from '@smartplatform/ui';
import { PlaneIcon } from './PlaneIcon';
import './PlaneList.scss';

@observer
export class PlaneList extends Component {
	constructor(props) {
		super(props);
		this.access = store.model.ACL && store.model.ACL.INFO.WRITE;
	}

	renderEngine = (record) => {
		const countEngine = record.family?.numberEngines;
		let positionsEngine = Array(countEngine).fill('');
		const engines = record.engines();
		for (const engine of engines) {
			if (engine?.enginePosition) positionsEngine[engine?.enginePosition - 1] = engine.serialNumber;
		}
		return (
			<div className='wrapper-engines-table'>
				{positionsEngine.map((value) => (
					<p className='engine-item-table'>{value}</p>
				))}
			</div>
		);
	};

	render() {
		return (
			<BasicList
				model={store.model.Aircraft}
				title={t('plane.plural')}
				path='/planes'
				filter={{
					include: ['manufacturer', 'type', 'family', 'holder', 'operator', 'engines'],
				}}
				disabledButton={!this.access}
			>
				<Column computed={PlaneIcon} width={35} />
				<Column property='serialNumber' label={t('ID')} />
				<Column relation='type' property='name' label={t('aircraftType.title')} />
				<Column relation='family' property='name' label={t('aircraftFamily.title')} />
				<Column relation='manufacturer' property='name' label={t('aircraftManufacturer.title')} />
				<Column relation='holder' property='name' label={t('aircraftHolder.title')} />
				<Column relation='operator' property='name' label={t('aircraftOperator.title')} />
				<Column label={t('engine.plural')} computed={this.renderEngine} />
			</BasicList>
		);
	}
}
