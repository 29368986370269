import React from 'react';
import { observer, Provider } from 'mobx-react';
import { Field, ManyToManySelect, Popup, RecordSelect, RelationSelect, Row, Select } from '@smartplatform/ui';
import { Form } from 'components';
import { ScheduleStore } from './store';
import { NotifyLogsList } from './NotifyLogsList';
import t from 'i18n';
import './style.scss';

@observer
export class EditPopup extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ScheduleStore(this.props.record);
		this.store.onClose = this.props.onClose;
	}

	// renderRecipient = ({ name, email }) => `${name || ''} - ${email}`;
	renderType = ({ name }) => `${name || ''}`;
	renderRole = ({ name, description }) => (
		<div>
			<strong>{name}</strong> {description && <span>- {description}</span>}
		</div>
	);

	render() {
		const { isNew, record, isInitialized, model, notifyLogs, beforeSave } = this.store;
		const { period, onPeriodChange, onHourChange, hour, dayOfWeek, onDayOfWeekChange, day, onDayChange, onGroupChange, group } = this.store;
		if (!isInitialized) return null;

		const { onClose } = this.props;
		const title = isNew ? t('editRecord') : t('addRecord');
		// const isCityDanger = record.report?.code === 'CityDanger';
		const isFormDisabled = !model?.INFO.WRITE;
		const hasNotifyLogs = notifyLogs.length > 0;
		const selectProps = { noTotal: true, maxItems: 10, itemsPerPage: 1000, isRequired: true };
		// const disableSave = !record[this.props.reportRelation];

		return (
			<Provider store={this.store}>
				<Popup width={hasNotifyLogs ? 1600 : 700} onClose={onClose} className='edit-transport-data-popup fires-popup'>
					<h2>{title}</h2>
					<Form
						disabled={isFormDisabled}
						record={record}
						onSave={onClose}
						onDelete={onClose}
						beforeSave={beforeSave}
						stay
						noClean
						disableSave={false}
						onCancel={onClose}
					>
						<div className='wrapper'>
							<div className='left'>
								<Field property='active' label={t('transportData.active')} />

								<Row>
									<Field relation='roles' label={t('role.plural')} property='name' itemTag={this.renderRole}>
										<ManyToManySelect searchFields={['name']} property='name' computed={this.renderRole} maxItems={10} itemsPerPage={1000} noTotal />
									</Field>
								</Row>

								<>
									<Field property='subject' label={t('transportData.subject')} />
									<Field property='text' label={t('transportData.text')}>
										<textarea rows={3} />
									</Field>
									<Field relation='type' property='name' label={t('notifyRecipient.type')} />
								</>
							</div>
							<NotifyLogsList />
						</div>
					</Form>
				</Popup>
			</Provider>
		);
	}
}
