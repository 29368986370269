import React from 'react';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';

import { Table, Column, Select, Pager, Input } from '@smartplatform/ui';
import { Button } from 'components';

import store from 'client/store';
import data from './data';
import t from 'i18n';
import { renderFaIcons } from 'client/tools';

const mapToSelect = (row) => ({ value: row.id, label: row.name });

const aclsData = {
	accessTypes: data.ACCESS_TYPE.map(mapToSelect),
	permissions: data.PERMISSION.map(mapToSelect),
	principalTypes: data.PRINCIPAL_TYPE.map(mapToSelect),
};

const ITEMS = [
	{ label: 'one', value: 1 },
	{ label: 'two', value: 2 },
	{ label: 'three', value: 3 },
	{ label: 'four', value: 4 },
	{ label: 'five', value: 5 },
];

const PER_PAGE = 20;

@withRouter
@observer
class ACLList extends React.Component {
	@observable records = null;
	@observable roles = null;
	@observable users = null;
	@observable isLoading = false;
	@observable items = ITEMS;

	constructor(props) {
		super(props);
		store.ui.title = t('acl.plural');
		this.models = Object.keys(store.model._models).map((modelName) => ({
			value: modelName,
			label: i18next.exists(`${modelName}.title`) ? t(`${modelName}.title`) : modelName,
		}));
		this.filter = {
			order: 'id asc',
			skip: 0,
			limit: PER_PAGE,
		};
		this.loadData();
	}

	@action loadData = async () => {
		this.isLoading = true;
		this.records = await store.model.ACL.find(this.filter);
		this.roles = await store.model.Role.find();
		this.users = await store.model.User.find();
		this.isLoading = false;
	};

	onPrincipalTypeChange = (type) => {
		this.principalType = type;
	};

	onPrincipalIdChange = (id, record) => {
		record.principalId = id;
		record.save();
	};

	fieldToArray = (value) => {
		let arr = [];
		try {
			arr = JSON.parse(value);
		} catch (e) {}
		// console.log('fieldToArray', arr);
		return arr;
	};

	onCustomChange = (r) => {
		const arr = toJS(r);
		const json = arr.length ? JSON.stringify(arr) : null;
		return json;
	};

	create = () => {
		let rootPath = this.props.rootPath || '';
		if (rootPath.length > 0) {
			rootPath = rootPath.replace(/\/$/, '');
		}
		this.props.history.push(`${rootPath}/acls/create`);
	};

	onPageChange = async (page) => {
		this.page = page;
		this.filter.skip = (this.page - 1) * PER_PAGE;
		this.loadData();
	};

	reload = (e) => {
		e.preventDefault();
		this.loadData();
	};

	edit = (record) => {
		store.route.push({ path: `/acls/${record.id}` });
	};

	render() {
		if (!this.roles || !this.users) return '...';
		if (!this.records) return '...';

		const types = {
			ROLE: this.roles.map((role) => ({ label: role.name, value: role.name })),
			USER: this.users.map((user) => ({ label: user.username, value: user.id })),
			APP: [],
		};

		return (
			<div className='table-list'>
				<div className='table-list-panel'>
					<div className='table-list-panel-create-btn'>
						<Button onClick={this.create} variant='primary' endIcon={renderFaIcons.circlePlus}>
							{t('create')}
						</Button>
					</div>
				</div>

				<div className='table-list-pager'>
					<Pager itemsPerPage={PER_PAGE} totalCount={this.records.totalCount} current={this.page} onChange={this.onPageChange} />
				</div>

				<Table rows={this.records} className='acl-table' onRowClick={this.edit}>
					<Column property='id' label={t('ID')} width={50} />

					<Column property='model' label={t('acl.title')} saveOnChange={true}>
						<Select items={this.models} itemsPerPage={10000} />
					</Column>

					<Column property='property' label={t('acl.property')} saveOnChange={true}>
						<Input />
					</Column>

					<Column property='accessType' label={t('acl.accessType')} saveOnChange={true}>
						<Select items={aclsData.accessTypes} itemsPerPage={10000} />
					</Column>

					<Column property='permission' label={t('acl.permission')} saveOnChange={true}>
						<Select items={aclsData.permissions} itemsPerPage={10000} />
					</Column>

					<Column property='principalType' label={t('acl.principalType')} saveOnChange={true}>
						<Select items={aclsData.principalTypes} onChange={this.onPrincipalTypeChange} itemsPerPage={10000} />
					</Column>

					<Column property='principalId' label={t('acl.principalId')} saveOnChange={true}>
						{({ record }) =>
							record.principalType ? (
								<Select
									items={types[record.principalType]}
									value={record.principalId}
									onChange={(v) => this.onPrincipalIdChange(v, record)}
									itemsPerPage={10000}
								/>
							) : (
								'-'
							)
						}
					</Column>
				</Table>
			</div>
		);
	}
}

export default ACLList;
