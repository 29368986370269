import React from 'react';
import t from 'i18n';
import { DragNDropField } from '@consta/uikit/DragNDropField';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

@observer
export default class ImageUploader extends React.Component {
	@observable image;
	constructor(props) {
		super(props);
		this.init();
	}

	init = () => {
		if (this.props.value) this.image = this.props.value;
	};

	setFiles = async (acceptedFiles) => {
		const file = acceptedFiles[0];
		const reader = new FileReader();
		reader.onload = () => {
			const imageDataUrl = reader.result;
			this.image = imageDataUrl;
		};
		reader.readAsDataURL(file);
		if (this.props.onChange) this.props.onChange(file);
	};

	deleteImage = () => {
		this.image = null;
		if (this.props.onChange) this.props.onChange(null);
	};

	render() {
		const DragNDropFieldProps = {};
		if (this.props?.accept) DragNDropFieldProps.accept = this.props?.accept;
		if (this.image)
			return (
				<div className='wrapper-image-setting'>
					<img src={this.image} />
					<div className='wrapper-delete-image' onClick={this.deleteImage}>
						<FontAwesomeIcon icon={faTrash} />
					</div>
				</div>
			);
		return (
			<DragNDropField onDropFiles={this.setFiles} {...DragNDropFieldProps}>
				{({ openFileDialog }) => <span onClick={openFileDialog}>{t('selectFile')}</span>}
			</DragNDropField>
		);
	}
}
