import React from 'react';
import { Toolbar, ListStore, Form } from 'components';
import { getPerPage, getLabelName } from 'client/tools';
import { Column, Field, Pager, Popup, Table } from '@smartplatform/ui';
import store from 'client/store';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';
import classNames from 'classnames';
import PropTypes from 'prop-types';

@observer
export class HasManyList extends React.Component {
	@observable selectedRecord = null;

	static propTypes = {
		record: PropTypes.object.isRequired,
		relation: PropTypes.string.isRequired,
		properties: PropTypes.array, // список пропети
		include: PropTypes.array, // инклюд belongsTo реляций вида [{relation:relation_name , fields: ['field_name']}]
		showAddButton: PropTypes.bool,
		showSearchInput: PropTypes.bool,
		onRowClick: PropTypes.func,
	};
	static defaultProps = {
		showAddButton: true,
		showSearchInput: true,
		properties: ['name'],
		include: [],
	};

	constructor(props) {
		super(props);
		const { record, relation, properties, include } = props;
		const relationInfo = record.MODEL.RELATIONS[relation];
		const foreignKeyValue = record.id;
		this.modelName = relationInfo.model;
		this.model = store.model[this.modelName];
		const RELATIONS = this.model.RELATIONS;
		const foreignKeys = (include || []).map(({ relation }) => RELATIONS[relation].foreignKey);
		// ключ на основную запись
		this.where = { [relationInfo.foreignKey]: foreignKeyValue };

		this.store = new ListStore({
			path: props.path,
			filters: {
				where: this.where,
				include: Array.isArray(include)
					? include.map(({ relation, property, fields = [] }) => ({ relation, scope: { fields: ['id', property, ...fields] } }))
					: undefined,
				fields: ['id', ...properties, ...foreignKeys],
			},
		});
	}
	componentDidMount() {
		if (!this.error) this.store.setPerPage(getPerPage());
	}

	add = () => (this.selectedRecord = new this.model(this.where));
	onClose = () => {
		this.store.reload();
		this.selectedRecord = null;
	};

	onRowClick = async (record) => {
		this.props.onRowClick ? this.props.onRowClick(record) : (this.selectedRecord = record);
	};

	onDelete = () => {
		this.store.reload();
		this.onClose();
	};

	render() {
		const { properties, include, showAddButton, showSearchInput } = this.props;
		const { onQueryUpdate, onChange, totalCount, page, query, perPage, search, onSearch, getInstance, isPerPageSetted } = this.store;
		const { onClose, onDelete, onRowClick } = this;
		const tableProps = isPerPageSetted ? { model: this.model } : { rows: [] };
		const className = classNames(this.modelName, 'hasmany-relation-list');

		return (
			<div className={className}>
				<Toolbar>
					{showAddButton && <Toolbar.AddButton onClick={this.add} />}
					{showSearchInput && <Toolbar.SearchIconInput value={search} onChange={onSearch} />}
				</Toolbar>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div id='table'>
					<Table query={query} onQueryUpdate={onQueryUpdate} {...tableProps} key={tableProps.model} onRowClick={onRowClick} getInstance={getInstance}>
						{include.map((props) => {
							return <Column {...props} key={props.relation + props.property} label={getLabelName(props.relation, this.modelName)}></Column>;
						})}
						{properties.map((property) => (
							<Column property={property} label={getLabelName(property, this.modelName)} key={property} />
						))}
					</Table>
				</div>
				{this.selectedRecord && (
					<Popup onClose={onClose} className='delivery-popup'>
						<h2>{this.selectedRecord.id ? t('editRecord') : t('addRecord')}</h2>
						<Form record={this.selectedRecord} stay onSave={onClose} onDelete={onDelete} onCancel={onClose}>
							{include.map((props) => {
								return <Field {...props} key={props.relation + props.property} label={getLabelName(props.relation, this.modelName)} />;
							})}
							{properties.map((property, i) => {
								const autoFocus = i === 0 ? true : undefined;
								return <Field property={property} label={getLabelName(property, this.modelName)} key={property} autoFocus={autoFocus} />;
							})}
						</Form>
					</Popup>
				)}
			</div>
		);
	}
}
