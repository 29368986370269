import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { Dictionaries as DictionariesList, RouteMenu } from 'components';
import { DICTIONARIES } from 'client/dictionaries';
import { toLowerCamelCase } from 'client/tools';
import store from 'client/store';
import { DeleteErrorEditWrapper } from './deleteErrorEditWrapper';
import t from 'i18n';

import './dictionaries.scss';

export const Dictionaries = (props) => {
	useEffect(() => {
		store.ui.title = t('dictionary.plural');
	}, []);

	return <DictionariesList models={DICTIONARIES} {...props} />;
};

