import React, { createRef } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import MenuItem from './MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import store from 'client/store';
import autoAnimate from '@formkit/auto-animate';

@observer
export default class SubMenu extends React.Component {
	ref = createRef(null);

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidMount() {
		this.ref.current && autoAnimate(this.ref.current);
	}

	init = () => {
		const { code } = this.props;

		if (!store.local.submenu) {
			store.local.submenu = {};
			store.local.save();
		}
		if (!store.local.submenu[code]) {
			store.local.submenu[code] = false;
			store.local.save();
		}
	};

	changeVisibility = (isVisible) => {
		const { code } = this.props;
		if (typeof isVisible === 'boolean') {
			if (isVisible !== store.local.submenu[code]) {
				store.local.submenu = {
					...store.local.submenu,
					[code]: isVisible,
				};
				store.local.save();
			}
		} else {
			store.local.submenu = {
				...store.local.submenu,
				[code]: !store.local.submenu[code],
			};
			store.local.save();
		}
	};

	render() {
		const { code, title, items } = this.props;
		const className = classNames('submenu', { hidden: !store.local.submenu[code] });

		return (
			<div className={className}>
				<div className='submenu__title' onClick={this.changeVisibility}>
					<strong>{title}</strong>
					<FontAwesomeIcon icon={faChevronUp} />
				</div>
				<div className='submenu__items' ref={this.ref}>
					{store.local.submenu[code] &&
						items.map((item, i) => {
							return (
								<MenuItem
									key={code + '-' + i}
									item={item}
									badgeFunc={item.badgeFunc}
									badgeEvent={item.badgeEvent}
									changeVisibility={this.changeVisibility}
									parentCode={code}
								/>
							);
						})}
				</div>
			</div>
		);
	}
}

