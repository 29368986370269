import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import t from 'i18n';
import store from 'client/store';

@observer
export class ToolbarCreateBtn extends React.Component {
	static propTypes = {
		url: PropTypes.string,
	};

	create = () => store.route.push({ path: this.props.url });

	render() {
		return (
			<Button
				text={this.props.text || this.props.innerText || t('create')}
				onClick={this.create}
				endIcon={<FontAwesomeIcon icon={faPlusCircle} />}
				className='toolbar-create-btn'
				{...this.props}
			/>
		);
	}
}

